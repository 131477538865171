
import Vue from "vue";
import { format } from "date-fns";
import { Manuscript, Plan, Version } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";
import ChevronDownIcon from "icons/ChevronDown.vue";
import { CodeDiff } from "v-code-diff";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ChevronDownIcon, CodeDiff },
  props: {
    novelId: {
      type: String,
      required: true,
    },
    manuscriptKey: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openedDates: [],
      previewVersionId: "",
      previewContent: "",
      existsDiff: true,
    };
  },
  mounted() {
    this.openedDates.push(this.versionDates[0]);
  },
  methods: {
    async onClickVersion(versionId) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "データ復元の最終確認",
        content:
          "選択した原稿データを復元して問題ないですか？<br />なお、現在の原稿に未保存の変更がある場合は、保存した後にデータの復元を行います。",
        change: "復元する",
      };
      const result = await confirmDialog.show(data);

      if (!result) return;

      const { novelId, manuscriptKey } = this;
      const res = await this.$store.dispatch("manuscriptModule/restoreVersion", { novelId, manuscriptKey, versionId });

      this.$close(true);

      if (!res) {
        const errorDialog = new Dialog(SimpleDialog);
        const errorData: SimpleDialogProps = {
          title: "データ復元の失敗",
          content: "データが破損している可能性があります。他のバックアップから復元をお試しください。",
          isError: true,
        };
        errorDialog.show(errorData);
        return;
      }

      const doneDialog = new Dialog(SimpleDialog);
      const doneData: SimpleDialogProps = {
        title: "データ復元の完了",
        content: "復元したデータをそのまま保存する場合は、原稿の保存ボタンの押し忘れにご注意ください。",
      };
      doneDialog.show(doneData);
    },
    async onClickPreview(versionId) {
      const { novelId, manuscriptKey } = this;
      const previewContent = await this.$store.dispatch("manuscriptModule/fetchVersionContent", {
        novelId,
        manuscriptKey,
        versionId,
      });
      this.previewContent = previewContent;
      this.previewVersionId = versionId;
    },
    onClickBack() {
      this.previewContent = "";
      this.previewVersionId = "";
      this.existsDiff = true;
    },
    onClickOutSide() {
      this.$close(true);
    },
    onClickSubscription() {
      this.$router.push({ name: "subscriptionAnnounce" });
      this.$close(true);
    },
    onToggleAccordion(date) {
      if ((this.openedDates as string[]).includes(date)) {
        this.openedDates = this.openedDates.filter((openedDate) => openedDate !== date);
      } else {
        this.openedDates.push(date);
      }
    },
    afterRenderDiff() {
      const existsDiff = document.querySelector(".d2h-file-wrapper");
      if (!existsDiff) this.existsDiff = false;
    },
  },
  computed: {
    manuscript() {
      return this.$store.getters["manuscriptModule/manuscript"](this.manuscriptKey);
    },
    versions() {
      return this.manuscript.versions;
    },
    versionDates() {
      return [
        ...(this.versions as Version[]).reduce(
          (acc, cur) => acc.add(format(cur.lastModified, "YYYY/MM/DD")),
          new Set()
        ),
      ];
    },
    versionsByDate() {
      return (date) => (this.versions as Version[]).filter((version) => version.lastModified.startsWith(date));
    },
    objectSize() {
      return (size) => size.toLocaleString();
    },
    lastModifiedTime() {
      return (lastModified) => format(lastModified, "YYYY年MM月DD日");
    },
    lastModifiedDate() {
      return (lastModified) => format(lastModified, "HH時mm分ss秒");
    },
    openedDate() {
      return (date) => this.openedDates.includes(date);
    },
    plan() {
      return this.$store.getters["userModule/plan"];
    },
    isFree() {
      return this.plan === Plan.free;
    },
  },
});

interface Props {
  novelId: string;
  manuscriptKey: string;
  content: string;
}

interface Data {
  openedDates: string[];
  previewVersionId: string;
  previewContent: string;
  existsDiff: boolean;
}

interface Methods {
  onClickOutSide: () => void;
  onClickVersion(versionId: string): void;
  onClickPreview(versionId: string): void;
  onClickBack(): void;
  onClickSubscription(): void;
  onToggleAccordion(date: string): void;
  afterRenderDiff(): void;
}

interface Computed {
  manuscript: Manuscript;
  versions: Version[];
  versionDates: any[];
  versionsByDate(date: string): any[];
  objectSize(size: number): string;
  lastModifiedTime(lastModified: string): string;
  lastModifiedDate(lastModified: string): string;
  openedDate(date: string): boolean;
  plan: Plan;
  isFree: boolean;
}

export type VersionsDialogProps = Props;

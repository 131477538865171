
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import Vue from "vue";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

interface Methods {
  close: () => void;
  navigateToNolaNovel: () => void;
}

export default Vue.extend<{}, Methods, {}, {}>({
  methods: {
    close() {
      this.$close(false);
    },
    async navigateToNolaNovel() {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);
      const aboutUrl = await service.genAboutUrl();

      window.open(
        createUrlWithUtmParams(aboutUrl, NolaPageName.Detail, NolaItemId.UseNolaNovelButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
  },
});


import Vue from "vue";
import { ToggleButton } from "vue-js-toggle-button";
import { DEFAULT_PROOFREADING_RULES, loadProofreading, ProofreadingRules } from "@/lib/proofreadingWorker";
import isMobile from "ismobilejs";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    negativeCallback: Function,
  },
  components: {
    ToggleButton,
  },
  created() {
    this.enable = this.$store.getters["proofreadingModule/enable"];

    const rules = this.$store.getters["proofreadingModule/rules"];
    if (rules) {
      this.rules = JSON.parse(JSON.stringify(rules));
    } else {
      this.rules = DEFAULT_PROOFREADING_RULES;
    }
  },
  computed: {
    ruleList() {
      return [
        {
          text: "文章の先頭で特定の記号（『《（【…―）以外は使用しない",
          preset: "general-novel-style-ja",
          key: "chars_leading_paragraph",
          value: "　「『【〈《（(“\"‘'［[〔｛{＜<",
        },
        {
          text: "閉じていない括弧のチェック",
          preset: "preset-ja-technical-writing",
          key: "no-unmatched-pair",
        },
        {
          text: "偶数での使用が必要な記号（―や…など）のチェック",
          preset: "general-novel-style-ja",
          key: [
            // 連続した三点リーダー(…)の数は偶数にする
            "even_number_ellipsises",
            // 連続したダッシュ(―)の数は偶数にする
            "even_number_dashes",
          ],
        },
        {
          text: "連続しての使用が適さない記号（。やーなど）のチェック",
          preset: "general-novel-style-ja",
          key: [
            // 連続した句読点(。、)を許可しない
            "appropriate_use_of_punctuation",
            // 連続した中黒(・)を許可しない
            "appropriate_use_of_interpunct",
            // 連続した長音符(ー)を許可しない
            "appropriate_use_of_choonpu",
          ],
        },
        {
          text: "文末の句点記号の抜けのチェック",
          preset: "preset-ja-technical-writing",
          // 文末の句点記号として「。」を使います
          key: "ja-no-mixed-period",
        },
        {
          text: "漢数字と算用数字を使い分けます",
          preset: "preset-ja-technical-writing",
          // 漢数字と算用数字を使い分けます
          key: "arabic-kanji-numbers",
        },
        {
          text: "「ですます調」、「である調」を統一します",
          preset: "preset-ja-technical-writing",
          key: "no-mix-dearu-desumasu",
        },
        {
          text: "二重否定は使用しない",
          preset: "preset-ja-technical-writing",
          key: "no-double-negative-ja",
        },
        {
          text: "ら抜き言葉を使用しない",
          preset: "preset-ja-technical-writing",
          key: "no-dropping-the-ra",
        },
        {
          text: "逆接の接続助詞「が」を連続して使用しない",
          preset: "preset-ja-technical-writing",
          key: "no-doubled-conjunctive-particle-ga",
        },
        {
          text: "同じ接続詞の連続使用のチェック",
          preset: "preset-ja-technical-writing",
          key: "no-doubled-conjunction",
        },
        {
          text: "同じ助詞の連続使用のチェック",
          preset: "preset-ja-technical-writing",
          key: "no-doubled-joshi",
        },
        {
          text: "同一の単語の連続使用のチェック",
          preset: "preset-ja-technical-writing",
          key: "ja-no-successive-word",
          value: {
            allowOnomatopee: true,
            // これらの記号が連続使用と判断されてしまうため許可リストに追加
            allow: ["…", "―", "・"],
          },
        },
        {
          text: "よくある日本語の誤用のチェック",
          preset: "preset-ja-technical-writing",
          key: "ja-no-abusage",
        },
        {
          text: "入力ミス（不自然なアルファベット）のチェック",
          preset: "preset-ja-technical-writing",
          key: "ja-unnatural-alphabet",
        },
        {
          text: "閉じ括弧の直前に句読点を置かない",
          preset: "general-novel-style-ja",
          // 閉じ括弧の手前に句読点(。、)を置かない
          key: "no_punctuation_at_closing_quote",
        },
        {
          text: "疑問符(？)と感嘆符(！)の直後にスペースを置く",
          preset: "general-novel-style-ja",
          key: "space_after_marks",
        },
        {
          text: "マイナス記号は数字の前にのみ許可する",
          preset: "general-novel-style-ja",
          key: "appropriate_use_of_minus_sign",
        },
        {
          text: "アラビア数字は最大桁数までのみ許可する",
          preset: "general-novel-style-ja",
          // アラビア数字の桁数は2桁まで
          key: "max_arabic_numeral_digits",
          value: 2,
        },
        {
          text: "1文の長さは100文字以下とする",
          preset: "preset-ja-technical-writing",
          // 1文の長さは100文字以下とする
          key: "sentence-length",
          value: { max: 100 },
        },
        {
          text: "カンマは1文中に3つまでに制限する",
          preset: "preset-ja-technical-writing",
          key: "max-comma",
          value: { max: 3 },
        },
        {
          text: "	読点は1文中に3つまでに制限する",
          preset: "preset-ja-technical-writing",
          key: "max-ten",
          value: { max: 3 },
        },
        {
          text: "連続できる最大の漢字長は6文字までに制限する",
          preset: "preset-ja-technical-writing",
          key: "max-kanji-continuous-len",
          value: { max: 6 },
        },
        {
          text: "不必要な制御文字の使用を制限する",
          preset: "preset-ja-technical-writing",
          key: "no-invalid-control-character",
        },
        {
          text: "不必要なゼロ幅スペースの使用を制限する",
          preset: "preset-ja-technical-writing",
          key: "no-zero-width-spaces",
        },
        {
          text: "感嘆符(！)、感嘆符(？)の使用を制限する",
          preset: "preset-ja-technical-writing",
          key: "no-exclamation-question-mark",
        },
        {
          text: "半角カナの使用を制限する",
          preset: "preset-ja-technical-writing",
          key: "no-hankaku-kana",
        },
        {
          text: "弱い日本語表現の利用をチェック",
          preset: "preset-ja-technical-writing",
          key: "ja-no-weak-phrase",
        },
        {
          text: "冗長な表現のチェック",
          preset: "preset-ja-technical-writing",
          key: "ja-no-redundant-expression",
        },
        {
          text: "UTF8-MAC 濁点の使用を制限する",
          preset: "preset-ja-technical-writing",
          key: "no-nfd",
        },
      ];
    },
    isUnsupportedDevice() {
      return isMobile().any;
    },
  },
  data() {
    return {
      enable: true,
      modified: false,
      rules: {},
    };
  },
  methods: {
    isChecked(rule) {
      const { preset, key } = rule as { preset: keyof ProofreadingRules; key: string };
      const keyItem = typeof key === "string" ? key : key[0];

      return this.rules[preset] && this.rules[preset][keyItem];
    },
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }
      this.$close(true);
    },
    onClickOutSide(e) {
      if ((e.target as HTMLElement).classList.contains("dialog-mask")) {
        this.$close(true);
      }
    },
    onChangeState(e) {
      this.enable = e.value;
      this.modified = true;
    },
    onChange(e) {
      const target = e.target as HTMLInputElement;
      if (!target) {
        return;
      }

      const index = Number(target.dataset.index);
      const rule = this.ruleList[index];

      const preset = this.rules[rule.preset as keyof ProofreadingRules];
      const keys = typeof rule.key === "string" ? [rule.key] : rule.key;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        // eslint-disable-next-line no-nested-ternary
        const value = target.checked ? (target.dataset.value ? JSON.parse(target.dataset.value) : true) : false;
        if (preset && typeof preset !== "boolean") {
          preset[key] = value;
        }
      }

      this.modified = true;
    },
    onSave() {
      const { rules } = this;
      const { enable } = this;

      this.$store.dispatch("proofreadingModule/update", { enable, rules });

      const proofreading = loadProofreading();
      proofreading.changeLintRule(rules as ProofreadingRules);
      this.$close(true);
    },
  },
});

interface Props {
  negative?: string;
  negativeCallback?: () => void;
}

interface Data {
  enable: boolean;
  modified: boolean;
  rules: any;
}

interface Methods {
  isChecked: (rule: any) => boolean;
  onNegativeClick: () => void;
  onChangeState: (event: any) => void;
  onChange: (event: InputEvent) => void;
  onClickOutSide: (event: InputEvent) => void;
  onSave: () => void;
}

interface Computed {
  ruleList: {
    text: string;
    preset: string;
    key: string | string[];
  }[];
  isUnsupportedDevice: boolean;
}

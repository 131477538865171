
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import { VueLoading } from "vue-loading-template";

interface Data {
  inProcess: boolean;
}

interface Methods {
  onClickOutSide: () => void;
  onCancelClick: () => void;
  onDelinkClick: () => void;
}

interface Props {
  novelId: string;
  nolaNovelId: string;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { CupertinoAlertDialog, VueLoading },

  data() {
    return {
      inProcess: false,
    };
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onCancelClick() {
      this.$close(false);
    },
    async onDelinkClick() {
      const { dispatch } = this.$store;
      const { novelId, nolaNovelId } = this;
      this.inProcess = true;
      try {
        await dispatch("nolaNovelModule/deleteNovelLink", {
          novelId,
          nolaNovelId,
        });
      } catch (e) {
        this.$close(false);
      }

      this.$close(true);
    },
  },

  props: {
    novelId: String,
    nolaNovelId: String,
  },
});

export type NolaNovelDelinkConfirmProps = Props;

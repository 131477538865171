
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  computed: {
    isShowProofreadingFirstTimeMessage() {
      return !this.$store.getters["proofreadingModule/isConfirmed"];
    },
  },
  methods: {
    close() {
      this.$store.dispatch("proofreadingModule/confirm", true);
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  isShowProofreadingFirstTimeMessage: boolean;
}

interface Methods {
  close(): void;
}


import Vue from "vue";
import DragHorizontalVariantIcon from "icons/DragHorizontalVariant.vue";
import DotsHorizontalCircleIcon from "icons/DotsHorizontalCircle.vue";
import LinkIcon from "icons/LinkVariant.vue";
import { Dialog, isBilling } from "@/lib/utils";
import CreateChildPlotDialog, { CreateChildPlotDialogProps } from "@/components/ui/CreateChildPlotDialog.vue";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
import { SubPlot } from "@/lib/models/plot";
import { ChildPlotItem } from "@/lib/plot";
import isMobile from "ismobilejs";

const ClickOutside = require("vue-click-outside");

interface Props {
  novelId: string;
  plotId: string;
  value: string;
  isInitial: boolean;
  // 子プロットのID
  child: string;
}

interface Data {
  displayMenu: boolean;
}

interface Computed {
  inputValue: string;
  childPlotName: string;
  isPC: boolean;
}

interface Methods {
  onClickOutside(): void;
  clickMenu(): void;
  createChildPlot(): void;
  deleteItem(e: MouseEvent): void;
  cancelLink(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  props: {
    novelId: String,
    plotId: String,
    value: String,
    isInitial: Boolean,
    child: String,
  },
  components: {
    DragHorizontalVariantIcon,
    DotsHorizontalCircleIcon,
    LinkIcon,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    childPlotName() {
      if (!this.child) return "";
      const subPlots = this.$store.getters["plotModule/subPlots"];
      const subPlot = subPlots.filter((i: SubPlot) => i.plotId === this.child);
      if (subPlot.length > 0) return subPlot[0].name;
      return "";
    },
    isPC() {
      return !isMobile().any;
    },
  },
  data() {
    return {
      displayMenu: false,
    };
  },
  methods: {
    onClickOutside() {
      // this.displayMenu = false;
    },
    clickMenu() {
      this.displayMenu = !this.displayMenu;
    },
    async createChildPlot() {
      const { $store, plotId } = this;
      const hasLimit = !(await isBilling($store));
      const subPlots = $store.getters["plotModule/subPlots"];

      const childPlotDialog = new Dialog(CreateChildPlotDialog);
      const data: CreateChildPlotDialogProps = {
        plotList: subPlots.filter((i: SubPlot) => i.plotId !== plotId),
        positiveCallback: (value: ChildPlotItem) => {
          this.$emit("linkPlot", value);
        },
        hasLimit,
      };

      childPlotDialog.show(data);
    },
    async cancelLink() {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "子プロットの連携を解除しますか？",
        content: "連携を解除しても子プロットは削除されず、再度の連携も可能です。",
        change: "解除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }
      this.$emit("cancelLink");
    },
    async deleteItem(e) {
      this.displayMenu = false;
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "このプロットを削除しますか？",
        content:
          "一度削除したプロットは復元することができません。なお、プロットアイテムに連携していた子プロットは削除されません。",
        change: "削除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }
      this.$emit("deleteItem", e);
    },
  },
});

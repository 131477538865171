
import Vue, { PropType } from "vue";
import PlusCircleIcon from "icons/PlusCircle.vue";
import PlaylistEditIcon from "icons/PlaylistEdit.vue";
import CheckboxMarkedCircleIcon from "icons/CheckboxMarkedCircle.vue";
import draggable from "vuedraggable";
import { SubPlot, UpdateSubPlotSortOrderPayload } from "@/lib/models/plot";
import { Dialog, isBilling } from "@/lib/utils";
import PlotCreateDialog, { PlotCreateDialogProps } from "@/components/ui/PlotCreateDialog.vue";
import clone from "lodash/cloneDeep";
import VueDragscroll from "vue-dragscroll";

Vue.use(VueDragscroll);

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    draggable,
    PlusCircleIcon,
    PlaylistEditIcon,
    CheckboxMarkedCircleIcon,
  },
  props: {
    novelId: String,
    plotId: String,
    subPlots: Array as PropType<SubPlot[]>,
  },
  data() {
    const { subPlots } = this;

    return {
      isEditing: false,
      dataSubPlots: clone(subPlots),
    };
  },
  computed: {
    isActive() {
      return (targetPlotId) => {
        const { plotId } = this;

        if (!targetPlotId && !plotId) {
          return true;
        }
        return plotId === targetPlotId;
      };
    },
  },
  methods: {
    async onClickAdd() {
      const { $store, novelId } = this;

      const hasLimit = !(await isBilling($store));

      const plotCreateDialog = new Dialog(PlotCreateDialog);
      const data: PlotCreateDialogProps = { novelId, hasLimit };
      plotCreateDialog.show(data);
    },
    onClickEdit(status) {
      this.isEditing = status;
    },
    onClickPlot(plotId) {
      const { $router, novelId } = this;

      if (plotId) {
        $router.push({ name: "plot", params: { novelId, plotId } }, () => {});
      } else {
        $router.push({ name: "plot", params: { novelId } }, () => {});
      }
    },
    onDragEnd() {
      const { $store, novelId, dataSubPlots } = this;
      const { dispatch } = $store;

      const order = dataSubPlots.map((subPlot) => subPlot.plotId);
      const payload: UpdateSubPlotSortOrderPayload = {
        novelId,
        order,
      };

      dispatch("plotModule/updateSubPlotSortOrder", payload);
    },
  },
  watch: {
    subPlots: {
      handler(subPlots: SubPlot[]) {
        this.dataSubPlots = clone(subPlots);
      },
      immediate: true,
      deep: true,
    },
  },
});

interface Props {
  novelId: string;
  plotId?: string;
  subPlots: SubPlot[];
}

interface Data {
  isEditing: boolean;
  dataSubPlots: SubPlot[];
}

interface Computed {
  isActive(plotId?: string): boolean;
}

interface Methods {
  onClickAdd(): Promise<void>;
  onClickEdit(status: boolean): void;
  onClickPlot(plotId?: string): void;
  onDragEnd(): void;
}


import Vue from "vue";

export default Vue.extend({
  props: {
    text: String,
    variant: String,
    disabled: {
      type: Boolean,
      default: false
    }
  }
});


import Vue from "vue";

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "white",
    },
    defaultColor: {
      type: String,
      default: "#a0a0a0",
    },
  },
});

interface Props {
  isActive: boolean;
  activeColor: string;
  defaultColor: string;
}

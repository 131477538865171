
import Vue from "vue";

const contents = {
  characterCreate: {
    title: "登場人物/世界観で好きな項目を追加しよう",
    content:
      "物語に登場する人や生き物、街や国などの設定を、自分の作品にあった項目で保存・管理することができます。自分だけの資料集の作成にお役立てください。",
    feature: "登場人物/世界観の項目追加機能",
  },
  characterEdit: {
    title: "登場人物/世界観で好きな項目を追加しよう",
    content:
      "物語に登場する人や生き物、街や国などの設定を、自分の作品にあった項目で保存・管理することができます。自分だけの資料集の作成にお役立てください。",
    feature: "登場人物/世界観の項目追加機能",
  },
  worldViewCreate: {
    title: "登場人物/世界観で好きな項目を追加しよう",
    content:
      "物語に登場する人や生き物、街や国などの設定を、自分の作品にあった項目で保存・管理することができます。自分だけの資料集の作成にお役立てください。",
    feature: "登場人物/世界観の項目追加機能",
  },
  worldViewEdit: {
    title: "登場人物/世界観で好きな項目を追加しよう",
    content:
      "物語に登場する人や生き物、街や国などの設定を、自分の作品にあった項目で保存・管理することができます。自分だけの資料集の作成にお役立てください。",
    feature: "登場人物/世界観の項目追加機能",
  },
  editor: {
    title: "原稿の文字数レイアウトを変更できます",
    content:
      "原稿用紙換算のように『1行あたりの文字数』と『１ページあたりの行数』を設定して執筆することができます。新人賞などのご応募の際にお役立てください。",
    feature: "文字数レイアウトの変更機能",
  },
  preview: {
    title: "原稿の文字数レイアウトを変更できます",
    content:
      "原稿用紙換算のように『1行あたりの文字数』と『１ページあたりの行数』を設定して執筆することができます。新人賞などのご応募の際にお役立てください。",
    feature: "文字数レイアウトの変更機能",
  },
};

export default Vue.extend({
  computed: {
    contents() {
      const key = this.$route.name;
      return contents[key];
    },
  },
});

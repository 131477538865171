var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"list-root"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"folder",on:{"click":function($event){return _vm.backToFolderList()}}},[_c('img',{attrs:{"src":require("@/assets/img/icon/folder_white.png")}})]),_c('div',{staticClass:"title ellipsis"},[_vm._v(" "+_vm._s(_vm.folder ? _vm.folder.name : "すべての登場人物")+" ")]),_c('div',{staticClass:"edit"},[(!_vm.isEditing)?_c('div',{on:{"click":function($event){_vm.isEditing = true}}},[_vm._v("並び替え")]):_c('div',{on:{"click":_vm.saveOrder}},[_vm._v("保存する")])])]),_c('sortable-list',{staticClass:"list-container",attrs:{"items":_vm.items,"keyAttr":"characterId","disabled":!_vm.isEditing,"extra":_vm.isEditing},on:{"update:items":function($event){_vm.items=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item, extra }){return [_c('list-item',{attrs:{"icon":_vm.getCharacterKey(item),"label":item.name,"folderName":_vm.folderName(item),"iconPlaceholder":"/img/placeholders/character.png","to":{
          name: 'characters',
          params: { novelId: _vm.novelId, characterId: item.characterId },
        },"isActive":item.characterId === _vm.selectedId,"showHandle":extra,"item":item,"customClickFunction":_vm.onClickItem.bind(this, item.characterId),"needsDoCustomClickFunction":_vm.needsDoCustomClickFunction,"isEditMode":_vm.isEditing},on:{"clickSetFolder":_vm.setFolder,"delete":function($event){return _vm.deleteCharacter(item.characterId)},"copy":function($event){return _vm.copyCharacter(item.characterId)}}})]}}])}),_c('button-solid',{staticClass:"create",attrs:{"to":{
      name: 'characterCreate',
      params: {
        novelId: _vm.novelId,
        characterFolderId: _vm.folder ? _vm.folder.characterFolderId : null,
      },
    }}},[_vm._v(" ＋ 新規作成する ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
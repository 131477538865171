
import Vue from "vue";
import CorrelationListItem from "@/components/molecules/CorrelationListItem.vue";
import SelectBox from "@/components/molecules/SelectBox.vue";
import ColorPicker from "@/components/molecules/ColorPicker.vue";
import InputText from "@/components/atoms/InputText.vue";
import InputTextarea from "@/components/atoms/InputTextarea.vue";
import { getCharacterKey } from "@/lib/s3";
import { callApi } from "@/lib/api";
import { showOkCancelDialog } from "@/lib/dialog";
import clone from "lodash/cloneDeep";

export default Vue.extend({
  components: {
    CorrelationListItem,
    InputText,
    InputTextarea,
    SelectBox,
    ColorPicker,
  },
  props: {
    novelId: String,
    selectedCorrelationGroupId: String,
    selectedCorrelationId: String,
    items: Array as any,
    correlation: Object,
    correlationDiagram: Object,
    correlationList: Array as any,
  },
  data() {
    return {
      characterItems: this.items,
      selectedCharacterA: null,
      selectedCharacterB: null,
      relationshipAtoB: {
        relationship: "",
        color: "#2B2B2B",
      },
      relationshipBtoA: {
        relationship: "",
        color: "#2B2B2B",
      },
      memo: null,
      isPreview: !!this.selectedCorrelationId || false,
    };
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      this.characterItems.forEach((item, index) => {
        this.characterItems[index].image = getCharacterKey(this.novelId, item);
      });
      if (this.correlation) {
        const { characterA, characterB, relationshipAtoB, relationshipBtoA, memo } = this.correlation;
        this.selectedCharacterA = this.getCharacterData(characterA);
        this.selectedCharacterB = this.getCharacterData(characterB);
        this.relationshipAtoB = relationshipAtoB;
        this.relationshipBtoA = relationshipBtoA;
        this.memo = memo;
      }
    },
    edit() {
      this.isPreview = false;
      this.setModified(true);
    },
    close() {
      this.$emit("close");
    },
    selectCharacterA(item) {
      this.selectedCharacterA = item;
    },
    selectCharacterB(item) {
      this.selectedCharacterB = item;
    },
    /** 登場人物Aで選択できるcharacterを取得 */
    filterCharacterAItems() {
      const { selectedCharacterB, characterItems, correlationList, selectedCorrelationId } = this;

      let filterCharacterItems = characterItems;

      if (selectedCharacterB) {
        // characterBを除く
        filterCharacterItems = filterCharacterItems.filter(
          (item) => item.characterId !== selectedCharacterB.characterId
        );

        // characterBを含む相関関係を取得
        const includeBCorrelationList = correlationList.filter(
          (item) =>
            item.correlationId !== selectedCorrelationId &&
            (item.characterA === selectedCharacterB.characterId || item.characterB === selectedCharacterB.characterId)
        );

        // characterBと相関しているcharacterを取得
        const relatedCharacterList = includeBCorrelationList.map((item) => {
          if (item.characterA === selectedCharacterB.characterId) {
            return item.characterB;
          }
          return item.characterA;
        });

        // characterBと相関しているcharacterを除く
        filterCharacterItems = filterCharacterItems.filter((item) => !relatedCharacterList.includes(item.characterId));
      }

      return filterCharacterItems;
    },
    /** 登場人物Bで選択できるcharacterを取得 */
    filterCharacterBItems() {
      const { selectedCharacterA, characterItems, correlationList, selectedCorrelationId } = this;

      let filterCharacterItems = characterItems;

      if (selectedCharacterA) {
        // characterAを除く
        filterCharacterItems = filterCharacterItems.filter(
          (item) => item.characterId !== selectedCharacterA.characterId
        );

        // characterAを含む相関関係を取得
        const includeACorrelationList = correlationList.filter(
          (item) =>
            item.correlationId !== selectedCorrelationId &&
            (item.characterA === selectedCharacterA.characterId || item.characterB === selectedCharacterA.characterId)
        );

        // characterAと相関しているcharacterを取得
        const relatedCharacterList = includeACorrelationList.map((item) => {
          if (item.characterA === selectedCharacterA.characterId) {
            return item.characterB;
          }
          return item.characterA;
        });

        // characterAと相関しているcharacterを除く
        filterCharacterItems = filterCharacterItems.filter((item) => !relatedCharacterList.includes(item.characterId));
      }

      return filterCharacterItems;
    },
    selectColorRelationshipAtoB(color) {
      this.relationshipAtoB.color = color;
    },
    selectColorRelationshipBtoA(color) {
      this.relationshipBtoA.color = color;
    },
    getCharacterData(characterId) {
      const character = this.characterItems.find((item) => item.characterId === characterId);
      return character;
    },
    validation() {
      return !(
        this.selectedCharacterA &&
        this.selectedCharacterB &&
        (this.relationshipAtoB.relationship || this.relationshipBtoA.relationship)
      );
    },
    async createCorrelation() {
      const {
        novelId,
        selectedCorrelationGroupId,
        selectedCharacterA,
        selectedCharacterB,
        relationshipAtoB,
        relationshipBtoA,
        memo,
      } = this;

      const query = `
      mutation CreateCorrelation ($input: CorrelationCreateInput!) {
        createCorrelation (input: $input) {
          novelId
          correlationId
          correlationGroupId
          characterA
          characterB
          relationshipAtoB {
            relationship
            color
          }
          relationshipBtoA {
            relationship
            color
          }
          memo
        }
      }
      `;
      await callApi(query, {
        input: {
          novelId,
          correlationGroupId: selectedCorrelationGroupId,
          characterA: selectedCharacterA.characterId,
          characterB: selectedCharacterB.characterId,
          relationshipAtoB,
          relationshipBtoA,
          memo,
        },
      });

      this.$notify({
        title: "保存しました",
        type: "success",
      });

      this.close();
      this.$emit("fetch");
    },
    async updateCorrelation() {
      const {
        novelId,
        selectedCorrelationGroupId,
        selectedCorrelationId,
        selectedCharacterA,
        selectedCharacterB,
        relationshipAtoB,
        relationshipBtoA,
        memo,
        correlation,
        correlationDiagram,
        correlationList,
      } = this;

      // 相関関係を更新
      const updateCorrelationQuery = `
      mutation UpdateCorrelation ($input: CorrelationUpdateInput!) {
        updateCorrelation (input: $input) {
          novelId
          correlationId
          correlationGroupId
          characterA
          characterB
          relationshipAtoB {
            relationship
            color
          }
          relationshipBtoA {
            relationship
            color
          }
          memo
        }
      }
      `;
      await callApi(updateCorrelationQuery, {
        input: {
          novelId,
          correlationId: selectedCorrelationId,
          characterA: selectedCharacterA.characterId,
          characterB: selectedCharacterB.characterId,
          relationshipAtoB,
          relationshipBtoA,
          memo,
        },
      });

      // 更新する相関関係が相関図に表示されており、characterの変更をおこなった場合、相関図を更新する
      const isIncludeCorrelationKeys =
        correlationDiagram && correlationDiagram.correlationKeys.includes(selectedCorrelationId);
      const isChangedCharacterA = correlation.characterA !== selectedCharacterA.characterId;
      const isChangedCharacterB = correlation.characterB !== selectedCharacterB.characterId;
      if (isIncludeCorrelationKeys && (isChangedCharacterA || isChangedCharacterB)) {
        // 自分以外の相関関係で使用されている登場人物を取得
        const exceptCorrelationList = correlationList.filter((item) => item.correlationId !== selectedCorrelationId);
        const exceptCharacterList = [] as any;
        exceptCorrelationList.forEach((item) => {
          exceptCharacterList.push(item.characterA, item.characterB);
        });

        const diagram = clone(correlationDiagram);
        diagram.coordinate = correlationDiagram.coordinate.reduce((accumulator, item) => {
          if (item.characterId === correlation.characterA) {
            if (exceptCharacterList.includes(correlation.characterA)) {
              if (correlationDiagram.coordinate.every((data) => data.characterId !== selectedCharacterA.characterId)) {
                const { x, y } = this.calcCoordinate;
                accumulator.push({
                  characterId: selectedCharacterA.characterId,
                  x,
                  y,
                });
              }
            } else if (
              correlationDiagram.coordinate.every((data) => data.characterId !== selectedCharacterA.characterId)
            ) {
              // eslint-disable-next-line no-param-reassign
              item.characterId = selectedCharacterA.characterId;
            } else {
              return accumulator;
            }
          }
          if (item.characterId === correlation.characterB) {
            if (exceptCharacterList.includes(correlation.characterB)) {
              if (correlationDiagram.coordinate.every((data) => data.characterId !== selectedCharacterB.characterId)) {
                const { x, y } = this.calcCoordinate;
                accumulator.push({
                  characterId: selectedCharacterB.characterId,
                  x,
                  y,
                });
              }
            } else if (
              correlationDiagram.coordinate.every((data) => data.characterId !== selectedCharacterB.characterId)
            ) {
              // eslint-disable-next-line no-param-reassign
              item.characterId = selectedCharacterB.characterId;
            } else {
              return accumulator;
            }
          }
          accumulator.push(item);
          return accumulator;
        }, []);

        // 相関関係グループの相関図データを更新
        const updateCorrelationGroupQuery = `
        mutation UpdateCorrelationGroup ($input: CorrelationGroupUpdateInput!) {
          updateCorrelationGroup (input: $input) {
            novelId
            correlationGroupId
            name
            diagram {
              correlationKeys
              coordinate {
                characterId
                x
                y
              }
            }
          }
        }
        `;
        await callApi(updateCorrelationGroupQuery, {
          input: {
            novelId,
            correlationGroupId: selectedCorrelationGroupId,
            diagram,
          },
        });
      }

      this.$notify({
        title: "保存しました",
        type: "success",
      });

      this.isPreview = true;
      this.$emit("fetch");
    },
    async deleteCorrelation() {
      const yes = await showOkCancelDialog({
        title: "確認",
        content: "本当にこちらの相関関係を削除しますか？<br />※一度削除した相関関係は元に戻せません。",
        okButton: "削除する",
      });
      if (!yes) {
        return;
      }

      const { novelId, selectedCorrelationGroupId, selectedCorrelationId, correlationDiagram, correlationList } = this;

      // 相関関係を削除
      const query = `
      mutation DeleteCorrelation ($input: CorrelationDeleteInput!) {
        deleteCorrelation (input: $input) {
          novelId
          correlationId
          correlationGroupId
          characterA
          characterB
          relationshipAtoB {
            relationship
            color
          }
          relationshipBtoA {
            relationship
            color
          }
          memo
        }
      }
      `;
      await callApi(query, {
        input: {
          novelId,
          correlationId: selectedCorrelationId,
        },
      });

      // 削除する相関関係が相関図に表示されている場合、相関図を更新する
      const isSelectedCorrelation =
        correlationDiagram && correlationDiagram.correlationKeys.includes(selectedCorrelationId);
      if (isSelectedCorrelation) {
        const diagram = clone(correlationDiagram);

        // 選択中の相関関係から除外
        diagram.correlationKeys = diagram.correlationKeys.filter((key) => key !== selectedCorrelationId);

        // 不要な座標データを除外
        const exceptCorrelationList = correlationList.filter((item) =>
          diagram.correlationKeys.includes(item.correlationId)
        );
        const exceptCharacterList = [] as any;
        exceptCorrelationList.forEach((item) => {
          exceptCharacterList.push(item.characterA, item.characterB);
        });
        diagram.coordinate = diagram.coordinate.filter((item) => exceptCharacterList.includes(item.characterId));

        // 相関関係グループの相関図データを更新
        const updateCorrelationGroupQuery = `
        mutation UpdateCorrelationGroup ($input: CorrelationGroupUpdateInput!) {
          updateCorrelationGroup (input: $input) {
            novelId
            correlationGroupId
            name
            diagram {
              correlationKeys
              coordinate {
                characterId
                x
                y
              }
            }
          }
        }
        `;
        await callApi(updateCorrelationGroupQuery, {
          input: {
            novelId,
            correlationGroupId: selectedCorrelationGroupId,
            diagram,
          },
        });
      }

      this.$notify({
        title: "削除しました",
        type: "error",
      });

      this.close();
      this.$emit("fetch");
    },
  },
  computed: {
    /** 配置可能な座標を計算 */
    calcCoordinate() {
      let x = 0;
      let y = 0;
      let i = 0;
      let isBreak = false;

      // 右下方向(x++, y++)
      while (i <= 40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x >= i;
        const isMaxY = y >= i;
        if (isMaxX && isMaxY) {
          y = 0;
          i += 1;
          x = i;
        } else if (isMaxX) {
          y += 1;
          if (y >= i) {
            x = 0;
          }
        } else {
          x += 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      // 左上方向(x--, y--)
      x = 0;
      y = 0;
      i = 0;
      while (i <= -40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x <= i;
        const isMaxY = y <= i;
        if (isMaxX && isMaxY) {
          y = 0;
          i -= 1;
          x = i;
        } else if (isMaxX) {
          y -= 1;
          if (y <= i) {
            x = 0;
          }
        } else {
          x -= 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      // 右上方向(x++, y--)
      x = 0;
      y = 0;
      i = 0;
      while (Math.abs(i) >= 40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x >= i;
        const isMaxY = y <= -i;
        if (isMaxX && isMaxY) {
          y = 0;
          i += 1;
          x = i;
        } else if (isMaxX) {
          y -= 1;
          if (y <= -i) {
            x = 0;
          }
        } else {
          x += 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      // 左下方向(x--, y++)
      x = 0;
      y = 0;
      i = 0;
      while (Math.abs(i) >= 40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x <= -i;
        const isMaxY = y >= i;
        if (isMaxX && isMaxY) {
          y = 0;
          i += 1;
          x = -i;
        } else if (isMaxX) {
          y += 1;
          if (y >= i) {
            x = 0;
          }
        } else {
          x -= 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      return { x: 0, y: 0 };
    },
    /** 配置可能な座標かチェック */
    checkCoordinate() {
      return (x, y) => {
        // 有効な座標範囲: (-40, -40) <= (x, y) <= (40, 40)
        if (Math.abs(x) > 40 || Math.abs(y) > 40) {
          return false;
        }

        const { coordinate } = this.correlationDiagram;

        // eslint-disable-next-line no-restricted-syntax
        for (const item of coordinate) {
          // 2点間の距離 = √((x2 - x1) ^ 2 + (y2 - y1) ^ 2)
          const distance = Math.sqrt((x - item.x) ** 2 + (y - item.y) ** 2);
          if (distance < 3) {
            return false;
          }
        }
        return true;
      };
    },
  },
  watch: {
    items: {
      handler() {
        this.characterItems = this.items;
        this.setData();
      },
      deep: true,
    },
    correlation: {
      handler() {
        this.setData();
      },
      deep: true,
    },
    /** 選択されている相関関係が変わった場合はプレビュー状態にする */
    selectedCorrelationId() {
      this.isPreview = true;
    },
  },
});

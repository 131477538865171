import { saveBinary } from "@/lib/s3";

let _canvas: any = null;

export function setCanvas(canvas) {
  _canvas = canvas;
}

/** ImageSelectorの画像を投稿する */
export async function submitImage(path: string) {
  if (!_canvas) {
    return;
  }
  const arr = Array.from(_canvas.classList);

  if (!arr.includes("local")) {
    return;
  }

  const [l, t, r, b] = _canvas.dataset.clip.split(",");

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  canvas.width = r - l;
  canvas.height = b - t;
  // use the extended from of drawImage to draw the
  // cropped area to the temp canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(_canvas, l, t, r - l, b - t, 0, 0, canvas.width, canvas.height);
  // return the .toDataURL of the temp canvas

  const img = canvas.toDataURL("image/jpeg");
  const imgData = dataURItoBlob(img);

  await saveBinary(path, imgData);
}

function dataURItoBlob(dataURI) {
  const binary = atob(dataURI.split(",")[1]);
  const array = [] as number[];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
}

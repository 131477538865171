
import Vue, { PropType } from "vue";
import { ManuscriptFolder } from "@/lib/models";
import SortableList from "@/components/molecules/SortableList.vue";
import ListFolderItem from "@/components/molecules/ListFolderItem.vue";
import { Dialog } from "@/lib/utils";
import FolderRenameDialog, { FolderRenameDialogProps } from "@/components/ui/novel/FolderRenameDialog.vue";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";

export default Vue.extend({
  components: { SortableList, ListFolderItem },
  props: {
    novelId: String, // 小説ID
    items: Array as PropType<ManuscriptFolder[]>, // 原稿フォルダ
    manuscriptsCount: Number, // フォルダに含まれる原稿の数
    isInsert: Boolean, // 状態管理用。原稿にフォルダを設定する状態かどうかを管理
    selectedManuscript: Object, // フォルダを設定しようとしている原稿
  },
  data() {
    return {
      folders: this.items || [],
      isEditMode: false,
      selectedInsertFolderIds: [],
    };
  },
  watch: {
    items() {
      this.folders = this.items;
    },
    isInsert: {
      handler(isInsert: boolean) {
        if (!isInsert) return;

        const folders = this.foldersBelongManuscript(this.selectedManuscript.item.key) as ManuscriptFolder[];
        this.selectedInsertFolderIds = folders.map((folder) => folder.manuscriptFolderId);
      },
      immediate: true,
    },
  },
  computed: {
    foldersBelongManuscript() {
      return (manuscriptKey: string) => this.$store.getters["manuscriptModule/foldersBelongManuscript"](manuscriptKey);
    },
    isSelectedFolder() {
      return (manuscriptFolderId: string) => this.selectedInsertFolderIds.includes(manuscriptFolderId);
    },
  },
  methods: {
    selectFolder(manuscriptFolderId: string) {
      this.$emit("selectedFolder", manuscriptFolderId);
    },
    async completeSetFolder() {
      const { folders, selectedInsertFolderIds, selectedManuscript } = this;
      const updatedFolders = (folders as ManuscriptFolder[]).map((folder) => {
        const target = folder;
        if (selectedInsertFolderIds.includes(target.manuscriptFolderId)) {
          target.manuscriptKeys = [...new Set([...target.manuscriptKeys, selectedManuscript.item.key])];
        } else {
          target.manuscriptKeys = target.manuscriptKeys.filter((key) => key !== selectedManuscript.item.key);
        }
        return target;
      });

      await this.updateManuscriptFolder(updatedFolders);
      this.$emit("clickCompleteSetFolder");
    },
    async createManuscriptFolder() {
      const { novelId } = this;
      const name = "新しいフォルダ";

      await this.$store.dispatch("manuscriptModule/createFolder", { novelId, name });
    },
    async deleteManuscriptFolder(key: string) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "削除の最終確認",
        content: "本当にフォルダを削除しますか？<br />※ 一度削除したフォルダは元に戻せません。",
        change: "削除する",
      };

      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }

      const { novelId } = this;
      const manuscriptFolders = (this.folders as ManuscriptFolder[]).filter(
        (folder) => folder.manuscriptFolderId !== key
      );
      await this.$store.dispatch("manuscriptModule/updateFolder", { novelId, manuscriptFolders });

      this.$notify({
        title: "削除しました",
        type: "error",
      });
    },
    async switchEditMode() {
      // 編集終了を押した場合、かつ変更していた場合
      if (this.isEditMode) {
        await this.updateManuscriptFolder(this.folders);
      }

      this.isEditMode = !this.isEditMode;
    },
    async updateManuscriptFolder(folders: ManuscriptFolder[]) {
      const { novelId } = this;

      // 変なデータが有ったらこっそり修正する
      const manuscriptFolders = folders.map((folder) => {
        const manuscriptKeys = folder.manuscriptKeys
          .filter((key) => key != null)
          .filter((el, index, selfArray) => selfArray.indexOf(el) === index); // 重複Keyは排除
        return { ...folder, manuscriptKeys };
      });

      await this.$store.dispatch("manuscriptModule/updateFolder", { novelId, manuscriptFolders });
    },
    async renameManuscriptFolder(key: string) {
      const renameCallback = async (name: string) => {
        const manuscriptFolders = (this.folders as ManuscriptFolder[]).map((folder) => {
          const item = folder;
          if (item.manuscriptFolderId === key) item.name = name;
          return item;
        });
        this.updateManuscriptFolder(manuscriptFolders);
      };
      const { name } = (this.folders as ManuscriptFolder[]).find((folder) => folder.manuscriptFolderId === key)!;

      const confirmDialog = new Dialog(FolderRenameDialog);
      const data: FolderRenameDialogProps = {
        name,
        renameCallback,
      };
      await confirmDialog.show(data);
    },
    onToggleInsertFolder(manuscriptFolderId: string) {
      if ((this.selectedInsertFolderIds as string[]).includes(manuscriptFolderId)) {
        this.selectedInsertFolderIds = this.selectedInsertFolderIds.filter(
          (folderId: string) => folderId !== manuscriptFolderId
        );
      } else {
        this.selectedInsertFolderIds.push(manuscriptFolderId);
      }
    },
  },
});

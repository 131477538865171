import axios from "axios";
import PizZip from "pizzip";
import { Manuscript } from "@/lib/models";

const fileUrls = [
  { name: "[Content_Types].xml", url: "/docxTemplate/[Content_Types].xml" },
  { name: "_rels/.rels", url: "/docxTemplate/_rels/.rels" },
  { name: "docProps/app.xml", url: "/docxTemplate/docProps/app.xml" },
  { name: "docProps/core.xml", url: "/docxTemplate/docProps/core.xml" },
  { name: "word/_rels/document.xml.rels", url: "/docxTemplate/word/_rels/document.xml.rels" },
  { name: "word/theme/theme1.xml", url: "/docxTemplate/word/theme/theme1.xml" },
  { name: "word/fontTable.xml", url: "/docxTemplate/word/fontTable.xml" },
  { name: "word/settings.xml", url: "/docxTemplate/word/settings.xml" },
  { name: "word/styles.xml", url: "/docxTemplate/word/styles.xml" },
  { name: "word/webSettings.xml", url: "/docxTemplate/word/webSettings.xml" },
];

const documentTemplate = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<w:document xmlns:wpc="http://schemas.microsoft.com/office/word/2010/wordprocessingCanvas"
  xmlns:cx="http://schemas.microsoft.com/office/drawing/2014/chartex"
  xmlns:cx1="http://schemas.microsoft.com/office/drawing/2015/9/8/chartex"
  xmlns:cx2="http://schemas.microsoft.com/office/drawing/2015/10/21/chartex"
  xmlns:cx3="http://schemas.microsoft.com/office/drawing/2016/5/9/chartex"
  xmlns:cx4="http://schemas.microsoft.com/office/drawing/2016/5/10/chartex"
  xmlns:cx5="http://schemas.microsoft.com/office/drawing/2016/5/11/chartex"
  xmlns:cx6="http://schemas.microsoft.com/office/drawing/2016/5/12/chartex"
  xmlns:cx7="http://schemas.microsoft.com/office/drawing/2016/5/13/chartex"
  xmlns:cx8="http://schemas.microsoft.com/office/drawing/2016/5/14/chartex"
  xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006"
  xmlns:aink="http://schemas.microsoft.com/office/drawing/2016/ink"
  xmlns:am3d="http://schemas.microsoft.com/office/drawing/2017/model3d"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  xmlns:oel="http://schemas.microsoft.com/office/2019/extlst"
  xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships"
  xmlns:m="http://schemas.openxmlformats.org/officeDocument/2006/math"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:wp14="http://schemas.microsoft.com/office/word/2010/wordprocessingDrawing"
  xmlns:wp="http://schemas.openxmlformats.org/drawingml/2006/wordprocessingDrawing"
  xmlns:w10="urn:schemas-microsoft-com:office:word"
  xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main"
  xmlns:w14="http://schemas.microsoft.com/office/word/2010/wordml"
  xmlns:w15="http://schemas.microsoft.com/office/word/2012/wordml"
  xmlns:w16cex="http://schemas.microsoft.com/office/word/2018/wordml/cex"
  xmlns:w16cid="http://schemas.microsoft.com/office/word/2016/wordml/cid"
  xmlns:w16="http://schemas.microsoft.com/office/word/2018/wordml"
  xmlns:w16sdtdh="http://schemas.microsoft.com/office/word/2020/wordml/sdtdatahash"
  xmlns:w16se="http://schemas.microsoft.com/office/word/2015/wordml/symex"
  xmlns:wpg="http://schemas.microsoft.com/office/word/2010/wordprocessingGroup"
  xmlns:wpi="http://schemas.microsoft.com/office/word/2010/wordprocessingInk"
  xmlns:wne="http://schemas.microsoft.com/office/word/2006/wordml"
  xmlns:wps="http://schemas.microsoft.com/office/word/2010/wordprocessingShape" mc:Ignorable="w14 w15 w16se w16cid w16 w16cex w16sdtdh wp14">
  <w:body>
    {{ body }}
  </w:body>
</w:document>`;

const titleTemplate = `<w:p>
      <w:r>
        <w:rPr>
          <w:b/>
          <w:bCs/>
          <w:sz w:val="24"/>
          <w:szCs w:val="24"/>
        </w:rPr>
        <w:t xml:space="preserve">{{ title }}</w:t>
      </w:r>
    </w:p>`;

const paragraphTemplate = `<w:p>
      {{ run }}
    </w:p>`;

const textTemplate = `<w:r>
        <w:rPr>
          <w:sz w:val="20"/>
          <w:szCs w:val="20"/>
        </w:rPr>
        <w:t xml:space="preserve">{{ text }}</w:t>
      </w:r>`;

const rubyTemplate = `<w:r>
        <w:ruby>
          <w:rubyPr>
            <w:rubyAlign w:val="distributeSpace"/>
            <w:hps w:val="11"/>
            <w:hpsRaise w:val="20"/>
            <w:hpsBaseText w:val="22"/>
            <w:lid w:val="ja-JP"/>
          </w:rubyPr>
          <w:rt>
            <w:r w:rsidR="00B21A78" w:rsidRPr="00B21A78">
              <w:rPr>
                <w:rFonts w:ascii="游明朝" w:eastAsia="游明朝" w:hAnsi="游明朝" w:hint="eastAsia"/>
                <w:sz w:val="11"/>
              </w:rPr>
              <w:t>{{ rubyText }}</w:t>
            </w:r>
          </w:rt>
          <w:rubyBase>
            <w:r w:rsidR="00B21A78">
              <w:rPr>
                <w:rFonts w:hint="eastAsia"/>
              </w:rPr>
              <w:t>{{ rubyBase }}</w:t>
            </w:r>
          </w:rubyBase>
        </w:ruby>
      </w:r>`;

const lineBreakTemplate = `<w:p/>`;

const nextPageTemplate = `<w:p>
      <w:pPr>
        <w:sectPr>
          <w:type w:val="nextPage"/>
          <w:pgSz w:w="11906" w:h="16838" w:orient="portrait"/>
          <w:pgMar w:top="1440" w:right="1440" w:bottom="1440" w:left="1440" w:header="708" w:footer="708" w:gutter="0"/>
          <w:pgNumType/>
          <w:docGrid w:linePitch="360"/>
        </w:sectPr>
      </w:pPr>
    </w:p>`;

async function loadFile(url: string): Promise<ArrayBuffer> {
  try {
    const response = await axios.get(url, { responseType: "arraybuffer" });
    return response.data;
  } catch (error) {
    console.error(`Failed to load file from ${url}`, error);
    throw error;
  }
}

// eslint-disable-next-line no-control-regex
const rubyRegExp = new RegExp("([|｜][^|｜\r\n]*?《.*?》)", "gu");

async function generateDocument(manuscripts: Manuscript[], showTitle: boolean): Promise<string> {
  const sections = manuscripts.map((manuscript, index, array) => {
    const title = manuscript.title || "(タイトル未設定)";
    const contentPerLinebreak = manuscript.content ? manuscript.content.split("\n") : [];

    const section: string[] = [];

    // タイトル
    if (showTitle) {
      section.push(titleTemplate.replace("{{ title }}", title));
      section.push(lineBreakTemplate);
    }

    // 本文
    const contentParagraphs = contentPerLinebreak.map((content) => {
      // ex. "正規表現で｜ルビ《るび》を分割する" => ["正規表現で", "｜ルビ《るび》", "を分割する"]
      const contentParts = content.split(rubyRegExp);
      const contentElements = contentParts.map((contentPart) => {
        const rubyMatch = contentPart.match(rubyRegExp);
        if (rubyMatch) {
          const ruby = rubyMatch[0];
          const rubyBase = ruby.match(/[|｜](.*?)《/)![1];
          const rubyText = ruby.match(/《(.*?)》/)![1];

          // ルビ文字はrubyTemplateで生成
          return rubyTemplate.replace("{{ rubyText }}", rubyText).replace("{{ rubyBase }}", rubyBase);
        }
        // ルビ文字がない場合はtextTemplateで生成
        return textTemplate.replace("{{ text }}", contentPart);
      });

      // 1段落の内容をまとめる
      const run = contentElements.join("\n");

      // 1段落をparagraphTemplateで生成
      return paragraphTemplate.replace("{{ run }}", run);
    });

    section.push(...contentParagraphs);

    // 最後のページの場合は改ページを挿入しない
    const isLast = index === array.length - 1;
    return section + (isLast ? "" : `\n${nextPageTemplate}`);
  });
  const body = sections.join("\n");
  return documentTemplate.replace("{{ body }}", body);
}

export async function generateDocx(manuscripts: Manuscript[], showTitle: boolean): Promise<Blob> {
  const zip = new PizZip();

  try {
    // document.xmlを生成する
    const document = await generateDocument(manuscripts, showTitle);
    zip.file("word/document.xml", document);

    // docxに必要なファイルを生成する
    const filePromises = fileUrls.map(async (file) => {
      const data = await loadFile(file.url);
      zip.file(file.name, data);
    });

    await Promise.all(filePromises);

    return zip.generate({
      type: "blob",
      mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  } catch (error) {
    console.error("Error generating docx file: ", error);
    throw error;
  }
}


import Vue, { PropType } from "vue";
import { Manuscript, Comment, User, SharedLinkKind, SubPlot } from "@/lib/models";
import { Comment as CommentDexie } from "@/lib/indexeddb";
import VueMarkdown from "vue-markdown";
import clone from "lodash/cloneDeep";
import { format } from "date-fns";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    VueMarkdown,
  },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    sharedLinkId: {
      type: String as PropType<string>,
    },
    contentKey: {
      type: String as PropType<string>,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      comment: "",
      editTarget: null,
      deleteTarget: null,
      isDeleting: false,
    };
  },
  methods: {
    post() {
      const { sharedLinkId, contentKey, novelId, comment } = this;
      const { name } = this.user;

      this.$store.dispatch("sharedLinkModule/createCommentBelongOwner", {
        sharedLinkId,
        manuscriptKey: this.kind === SharedLinkKind.MANUSCRIPT ? contentKey : undefined,
        plotId: this.kind === SharedLinkKind.PLOT ? contentKey : undefined,
        novelId,
        name: name || "",
        comment,
        callback: async () => {
          this.comment = "";
          await this.$nextTick();
          this.scrollToBottom();
        },
      });
    },
    updateComment() {
      const { sharedLinkId, commentId, manuscriptKey, plotId, novelId, name, comment } = this.editTarget as Comment;

      this.$store.dispatch("sharedLinkModule/updateCommentBelongOwner", {
        sharedLinkId,
        commentId,
        manuscriptKey,
        plotId,
        novelId,
        name,
        comment,
        callback: () => {
          this.initializeEditFragment();
        },
      });
    },
    onClickDelete(comment: Comment) {
      this.isDeleting = true;
      this.deleteTarget = comment;
    },
    cancelDelete() {
      this.initializeDeleteFragment();
    },
    deleteComment() {
      const { sharedLinkId, commentId } = this.deleteTarget as Comment;
      this.$store.dispatch("sharedLinkModule/deleteCommentBelongOwner", {
        sharedLinkId,
        commentId,
        callback: () => {
          this.initializeEditFragment();
          this.initializeDeleteFragment();
        },
      });
    },
    initializeEditFragment() {
      this.editTarget = null;
    },
    initializeDeleteFragment() {
      this.isDeleting = false;
      this.deleteTarget = null;
    },
    async reply(comment: string) {
      // eslint-disable-next-line vue/no-parsing-error
      /** 文字の先頭に引用符を付ける */
      const splittedComment = comment.split("\n");
      const addedQuoteComment = splittedComment.map((comment) => `> ${comment}`);
      this.comment = `${addedQuoteComment.join("\n")}\n\n`;

      /** this.commentが反映されるまで待つ */
      await this.$nextTick();

      /** textareaにフォーカスする */
      const textareaElement = this.$refs.textarea;
      textareaElement.focus();
      textareaElement.scrollTop = textareaElement.scrollHeight;
    },
    edit(commentObj: Comment) {
      this.editTarget = clone(commentObj);
    },
    scrollToBottom() {
      const listElement = this.$refs.list;
      listElement.scrollTop = listElement.scrollHeight;
    },
    back() {
      this.$store.dispatch("sharedLinkModule/initializeSelectedContent");
    },
  },
  computed: {
    selectedSharedLinkId() {
      return this.$store.getters["sharedLinkModule/selectedSharedLinkId"];
    },
    selectedContentKey() {
      return this.$store.getters["sharedLinkModule/selectedContentKey"];
    },
    commentList() {
      const commentList = this.$store.getters["sharedLinkModule/commentListBelongOwner"](
        this.selectedSharedLinkId
      ) as Comment[];

      switch (this.kind) {
        case SharedLinkKind.MANUSCRIPT:
          return commentList.filter((comment) => comment.manuscriptKey === this.contentKey);
        case SharedLinkKind.PLOT:
          return commentList.filter((comment) => comment.plotId === this.contentKey);
        default:
          return [];
      }
    },
    contentTitle() {
      switch (this.kind) {
        case SharedLinkKind.MANUSCRIPT: {
          const manuscriptList = this.$store.getters["manuscriptModule/manuscriptList"] as Manuscript[];
          const manuscript: Manuscript = manuscriptList.find(
            (manuscript) => manuscript.key === this.selectedContentKey
          )!;
          return manuscript.title ? manuscript.title : "（タイトル未設定）";
        }
        case SharedLinkKind.PLOT: {
          const overallPlotIdPrefix = "overall-plot-";
          if (this.selectedContentKey.startsWith(overallPlotIdPrefix)) {
            return "全体プロット";
          }
          const subPlots = this.$store.getters["plotModule/subPlots"];
          const subPlot: SubPlot = subPlots.find((subPlot: SubPlot) => subPlot.plotId === this.selectedContentKey)!;
          if (subPlot) {
            return subPlot.name ? subPlot.name : "（タイトル未設定）";
          }
          return "（タイトル未設定）";
        }
        default:
          return "";
      }
    },
    user() {
      return this.$store.getters["userModule/user"];
    },
    rows() {
      return (comment: string) => {
        const breakNum = comment.split("\n").length;
        return breakNum < 10 ? breakNum : 10;
      };
    },
    createdAt() {
      return (date: string) => format(date, "YYYY/MM/DD HH:mm");
    },
    isEditing() {
      return (commentId: string) => this.editTarget && this.editTarget.commentId === commentId;
    },
    isOwnComment() {
      return (userId: string) => userId === this.user.userId;
    },
  },
  async created() {
    const { novelId, sharedLinkId, contentKey, kind } = this;
    const { userId } = this.user as User;
    const unreadCommentList = this.$store.getters["sharedLinkModule/unreadCommentList"](
      userId,
      novelId,
      kind,
      sharedLinkId,
      contentKey
    ) as CommentDexie[];
    if (!unreadCommentList.length) return;

    /** 未読コメントを既読にする */
    this.$store.dispatch("sharedLinkModule/readComment", unreadCommentList);
  },
  mounted() {
    this.scrollToBottom();
  },
});

interface Props {
  novelId: string;
  sharedLinkId: string;
  contentKey: string;
  kind: string;
}

interface Data {
  comment: string;
  editTarget: Comment | null;
  deleteTarget: Comment | null;
  isDeleting: boolean;
}

interface Methods {
  post: () => void;
  onClickDelete: (comment: Comment) => void;
  cancelDelete: () => void;
  initializeDeleteFragment: () => void;
  initializeEditFragment: () => void;
  deleteComment: (comment: Comment) => void;
  updateComment: () => void;
  reply: (comment: string) => void;
  edit: (commentObj: Comment) => void;
  scrollToBottom: () => void;
  back: () => void;
}

interface Computed {
  selectedSharedLinkId: string;
  selectedContentKey: string;
  commentList: Comment[];
  contentTitle: string;
  user: User;
  rows: (comment: string) => number;
  createdAt: (date: string) => string;
  isEditing: (commentId: string) => boolean;
  isOwnComment: (userId: string) => boolean;
}


import Vue, { PropType } from "vue";
import { Manuscript, Plan, AnalysisResult } from "@/lib/models";
import Lottie from "@/components/animations/Lottie.vue";
import { Dialog } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";

type TItem = {
  title: string;
  key: string;
  checked: boolean;
  contentLength: number;
  checkOrder: number | null;
};

type TPastFeedback = {
  manuscriptAnalysisId: number;
  manuscriptAnalysisResult: string;
  createdAt: string;
  isToggleVisible: boolean;
  isShort: boolean;
};

const FREE_PLAN_MAX_CONTENTS = 10000;
const PREMIUM_PLAN_MAX_CONTENTS = 100000;

export default Vue.extend({
  components: {
    Lottie,
  },
  data() {
    return {
      items: [] as TItem[],
      checkCounter: 0,
      feedbackReceived: false,
      isLoading: false,
      isOverLimit: false,
      checkedKeys: [] as string[],
      pastFeedbacks: [] as TPastFeedback[],
      analysisResult: null as AnalysisResult | null,
    };
  },
  props: {
    novelId: String,
    manuscriptList: Array as PropType<Manuscript[]>,
  },
  mounted() {
    this.initialize();
  },
  computed: {
    isUnselected() {
      return this.items.filter((item) => item.checked).length === 0;
    },
    plan(): number {
      return this.$store.getters["userModule/plan"];
    },
    isFree(): boolean {
      return this.plan === Plan.free;
    },
    maxAnalysisContents(): number {
      return this.isFree ? FREE_PLAN_MAX_CONTENTS : PREMIUM_PLAN_MAX_CONTENTS;
    },
    totalAnalyzedManuscriptContentLength(): number {
      return this.$store.getters["manuscriptAnalysisModule/totalAnalyzedManuscriptContentLength"];
    },
    monthlyAnalyzedManuscriptContentLength(): number {
      return this.$store.getters["manuscriptAnalysisModule/monthlyAnalyzedManuscriptContentLength"];
    },
    checkedContentCount(): number {
      return this.items.filter((item) => item.checked).reduce((sum, item) => sum + item.contentLength, 0);
    },
    remainingAnalysisContents(): number {
      return this.isFree
        ? FREE_PLAN_MAX_CONTENTS - this.totalAnalyzedManuscriptContentLength - this.checkedContentCount
        : PREMIUM_PLAN_MAX_CONTENTS - this.monthlyAnalyzedManuscriptContentLength - this.checkedContentCount;
    },
    novelTitle() {
      return this.$store.getters["novelModule/novel"](this.novelId).title;
    },
  },
  watch: {
    checkedContentCount() {
      this.isOverLimit = this.remainingAnalysisContents < 0;
    },
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("manuscriptAnalysisModule/initialize", this.novelId);
      // 親から受け取ったmanuscriptListをitemsに代入し、原稿リスト表示用のデータを作成する
      this.items = this.manuscriptList.map((manuscript) => ({
        title: manuscript.title || "(タイトル未設定)",
        key: manuscript.key || "",
        checked: false,
        contentLength: manuscript.content ? manuscript.content.length : 0,
        checkOrder: null,
      }));

      // 診断リストを取得し、過去のフィードバック表示用のデータを作成する
      const pastFeedbacks = this.$store.getters["manuscriptAnalysisModule/manuscriptAnalysisList"];
      this.pastFeedbacks = pastFeedbacks.map((feedback: TPastFeedback) => {
        const manuscriptAnalysisResult = feedback.manuscriptAnalysisResult || "";
        return {
          manuscriptAnalysisId: feedback.manuscriptAnalysisId,
          manuscriptAnalysisResult,
          createdAt: new Date(feedback.createdAt).toLocaleString(),
          isToggleVisible: manuscriptAnalysisResult.length > 60,
          isShort: true,
        };
      });
    },
    handleCheckboxChange(item: TItem, value: boolean) {
      /* eslint-disable no-param-reassign */
      if (item.contentLength === 0) {
        return;
      }

      if (value) {
        this.checkCounter += 1;
        item.checkOrder = this.checkCounter;
        this.checkedKeys.push(item.key);
      } else {
        const uncheckedOrder = item.checkOrder;
        item.checkOrder = null;
        this.checkCounter -= 1;
        this.items.forEach((el) => {
          if (el.checkOrder != null && uncheckedOrder != null && el.checkOrder > uncheckedOrder) {
            el.checkOrder -= 1;
          }
        });
        const index = this.checkedKeys.indexOf(item.key);
        if (index > -1) {
          this.checkedKeys.splice(index, 1);
        }
      }
      item.checked = value;
      /* eslint-enable no-param-reassign */
    },
    getCheckmarkNumber(item: TItem) {
      return item.checked ? item.checkOrder : "";
    },
    async onSubmitFeedback() {
      if (this.isOverLimit || this.isUnselected) {
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch("manuscriptAnalysisModule/createManuscriptAnalysis", {
          novelId: this.novelId,
          manuscriptKeys: this.checkedKeys,
        });
        this.analysisResult = this.$store.getters["manuscriptAnalysisModule/manuscriptAnalysisResult"];
        this.feedbackReceived = true;
      } catch (error) {
        console.log(error);
        const errorDialog = new Dialog(SimpleDialog);
        const errorData: SimpleDialogProps = {
          title: "エラーが発生しました",
          content: `<span>結果の出力に失敗しました。<br />時間をおいて再度お試しください。</span>`,
          isError: true,
          width: 350,
        };
        errorDialog.show(errorData);
      } finally {
        this.checkedKeys = [];
        this.isLoading = false;
      }
    },
    toggleFeedbackDisplay(id: number) {
      const index = this.pastFeedbacks.findIndex((f) => f.manuscriptAnalysisId === id);
      if (index !== -1) {
        this.pastFeedbacks[index].isShort = !this.pastFeedbacks[index].isShort;
      }
    },
    truncate(text: string) {
      return `${text.substring(0, 60)}...`;
    },
    async backToTop() {
      // TOPに戻る前にチェック状態を初期化する
      this.items.forEach((item) => {
        /* eslint-disable no-param-reassign */
        item.checked = false;
        item.checkOrder = null;
        /* eslint-enable no-param-reassign */
      });
      this.checkCounter = 0;
      this.feedbackReceived = false;
      // 残り文字数などに更新がある為、再度初期化を行う
      await this.initialize();
    },
    closeMessage() {
      this.$emit("closeAiFeedback");
    },
    showPremiumFeatureDialog() {
      this.$router.push({ name: "subscriptionAnnounce", query: { from: "editor" } });
    },
    formatNumberWithCommas(value: number): string {
      return value ? Number(value).toLocaleString() : "0";
    },
    convertNewlinesToBr(text: string) {
      return text.replace(/\n/g, "<br>");
    },
  },
});

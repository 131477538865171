
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    title: String,
    content: String,
  },
  computed: {
    isShowFirstTimeMessage() {
      return this.$store.getters["manuscriptModule/isShowingAutoIndentFirstTimeMessage"];
    },
  },
  methods: {
    close() {
      this.$store.dispatch("manuscriptModule/closeAutoIndentMessage");
    },
  },
});

interface Props {
  title: string;
  content: string;
}

interface Data {}

interface Computed {
  isShowFirstTimeMessage: boolean;
}

interface Methods {
  close(): void;
}

/* eslint-disable no-undef */
function extractRubyInfo(rubyNodes: NodeListOf<ChildNode>): string {
  let rubyText = "";
  let rubyBase = "";

  rubyNodes.forEach((node: ChildNode) => {
    if (node.nodeName === "w:rt") {
      node.childNodes.forEach((wrtNode: ChildNode) => {
        if (wrtNode.nodeName === "w:r") {
          wrtNode.childNodes.forEach((wrNode: ChildNode) => {
            if (wrNode.nodeName === "w:t") {
              rubyText = wrNode.textContent || "";
            }
          });
        }
      });
    } else if (node.nodeName === "w:rubyBase") {
      node.childNodes.forEach((rubyBaseNode: ChildNode) => {
        if (rubyBaseNode.nodeName === "w:r") {
          rubyBaseNode.childNodes.forEach((wrNode: ChildNode) => {
            if (wrNode.nodeName === "w:t") {
              rubyBase = rubyBaseNode.textContent || "";
            }
          });
        }
      });
    }
  });

  return `|${rubyBase}《${rubyText}》`;
}

function extractTextNodeInfo(tNode: ChildNode): string {
  if (tNode.nodeName === "w:t") {
    return tNode.textContent || "";
  }
  if (tNode.nodeName === "w:tab") {
    return "\t";
  }
  if (tNode.nodeName === "w:ruby") {
    return extractRubyInfo(tNode.childNodes);
  }
  return "";
}

export function extractParagraphInfo(pNode: ChildNode): string {
  let paragraphText = "";
  if (pNode.nodeName === "w:p") {
    pNode.childNodes.forEach((rNode: ChildNode) => {
      if (rNode.nodeName === "w:r") {
        rNode.childNodes.forEach((tNode: ChildNode) => {
          paragraphText += extractTextNodeInfo(tNode);
        });
      }
    });
    paragraphText += "\n";
  }
  return paragraphText;
}


import Vue from "vue";
import { Manuscript, ManuscriptSetting } from "@/lib/models";
import Platform from "platform";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    novelId: {
      type: String,
      required: true,
    },
    manuscriptList: Array as Object,
  },
  data() {
    return {
      previewWidth: 0,
      resizeObserver: null,
    };
  },
  async mounted() {
    /** ResizeObserverを定義 */
    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$refs.previewWrapper);

    this.onResize();
    this.$refs.previewWrapper.scroll(this.previewWidth, 0);
  },
  destroyed() {
    this.resizeObserver.disconnect();
  },
  watch: {
    scrollRatio() {
      this.$refs.previewWrapper.scroll({
        left: this.previewWidth * (1 - this.scrollRatio),
      });
    },
    manuscriptList: {
      handler() {
        this.onResize();
      },
      deep: true,
    },
  },
  computed: {
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
    manuscriptSetting() {
      return this.$store.getters["novelModule/manuscriptSetting"](this.novelId) as ManuscriptSetting;
    },
    htmlContent() {
      return (content) => {
        const splittedContent = content.split("\n");
        const addedRubyContent = splittedContent.map((content: string) => {
          // eslint-disable-next-line no-control-regex
          const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
          const textWithRuby = content.match(rubyRegExp);
          if (textWithRuby) {
            textWithRuby.forEach((text: string) => {
              let replaceText = text;
              const parentRegExp = new RegExp("[|｜]");
              replaceText = replaceText.replace(parentRegExp, "<ruby>");
              replaceText = replaceText.replace("《", "<rt>");
              replaceText = replaceText.replace("》", "</rt></ruby>");
              // eslint-disable-next-line no-param-reassign
              content = content.replace(text, replaceText);
            });
          }
          return content;
        });
        const wrappedContent = addedRubyContent.map((content: string) => `<p>${content}</p>`);
        return wrappedContent.join("");
      };
    },
    styles() {
      // eslint-disable-next-line prefer-const
      let { wordCountOnVertical, fontSizeRate } = this.manuscriptSetting as ManuscriptSetting;

      /** NOTE: Safariで1文字多く表示されてしまう問題の暫定対応 */
      if (Platform.name === "Safari") {
        wordCountOnVertical -= 1;
      }

      return {
        "--word-count-on-vertical": `${wordCountOnVertical}.5em`,
        "--font-size-rate": fontSizeRate,
      };
    },
    scrollRatio() {
      return this.$store.getters["manuscriptModule/scrollRatio"];
    },
  },
  methods: {
    handleScroll(e, position) {
      const scrollRatio = 1 - position.scrollLeft / this.previewWidth;
      this.$store.dispatch("manuscriptModule/updateScrollRatio", scrollRatio);
    },
    onResize() {
      const { previewWrapper } = this.$refs;
      this.previewWidth = previewWrapper.scrollWidth - previewWrapper.clientWidth;
    },
  },
});

interface Props {
  novelId: string;
  manuscriptList: Manuscript[];
}

interface Data {
  previewWidth: number;
  resizeObserver: ResizeObserver | null;
}

interface Computed {
  theme: string;
  manuscriptSetting: ManuscriptSetting;
  htmlContent: (content: string) => string;
  styles: object;
  scrollRatio: number;
}

interface Methods {
  handleScroll(e: Event, position: any): void;
  onResize(): void;
}

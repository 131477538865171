
import Vue from "vue";
import ImageViewRound from "@/components/atoms/ImageView.vue";
import CharacterInfo from "@/components/atoms/CharacterInfo.vue";
import CorrelationArrows from "@/components/atoms/CorrelationArrows.vue";
import { showOkCancelDialog } from "@/lib/dialog";

export default Vue.extend({
  // eslint-disable-next-line vue/no-unused-components
  components: { ImageViewRound, CharacterInfo, CorrelationArrows },
  props: {
    previewMode: Boolean,
    showPlaceholder: Boolean,
    isActive: Boolean,
    to: Object,
    characterA: Object,
    characterB: Object,
    relationshipAtoB: Object,
    relationshipBtoA: Object,
  },
  data() {
    return {
      selectedCharacterA: this.characterA,
      selectedCharacterB: this.characterB,
    };
  },
  methods: {
    async click() {
      if (this.to) {
        const { currentRoute } = this.$router;
        const isMoveToSame =
          currentRoute.name === this.to.name &&
          currentRoute.params.novelId === this.to.params.novelId &&
          currentRoute.params.correlationGroupId === this.to.params.correlationGroupId &&
          currentRoute.params.correlationId === this.to.params.correlationId;
        if (isMoveToSame) {
          return;
        }
        if (this.isModified()) {
          const yes = await showOkCancelDialog({
            title: "確認",
            content: "保存されていない変更があります。<br />変更を破棄しますか？",
            okButton: "破棄する",
            cancelButton: "キャンセル",
          });
          if (!yes) {
            return;
          }
        }
        this.setModified(false);
        this.$router.push(this.to);
      }
    },
  },
  watch: {
    characterA() {
      this.selectedCharacterA = this.characterA;
    },
    characterB() {
      this.selectedCharacterB = this.characterB;
    },
  },
});

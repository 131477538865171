
import Vue, { PropType } from "vue";
import InputText from "@/components/atoms/InputText.vue";
import { CreateSubPlotPayload, Mode, Layout, PlotGroups, DisplayFormat } from "@/lib/models/plot";
import { plotModes, PlotModeSelectBoxItem } from "@/lib/plot";
import SelectBox from "@/components/molecules/SelectBox.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { InputText, SelectBox },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    hasLimit: Boolean,
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "作成する",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    return {
      name: "",
      selectedMode: plotModes[0],
      plotModes,
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      const { $router, novelId, name, selectedMode } = this;
      const mode = selectedMode.id;
      const layout = Layout.simple;
      const displayFormat = DisplayFormat.HORIZONTAL;

      let plotGroups: PlotGroups[] = [];

      // eslint-disable-next-line default-case
      switch (mode) {
        default:
        case Mode.kishoutenketsu:
          plotGroups = [
            { name: "起", description: "", plots: [] },
            { name: "承", description: "", plots: [] },
            { name: "転", description: "", plots: [] },
            { name: "結", description: "", plots: [] },
          ];
          break;
        case Mode.johakyu:
          plotGroups = [
            { name: "序", description: "", plots: [] },
            { name: "破", description: "", plots: [] },
            { name: "急", description: "", plots: [] },
          ];
          break;
        case Mode.custom:
          plotGroups = [
            { name: "第１章", description: "", plots: [] },
            { name: "第２章", description: "", plots: [] },
            { name: "第３章", description: "", plots: [] },
            { name: "第４章", description: "", plots: [] },
          ];
          break;
      }

      const createSubPlotPayload: CreateSubPlotPayload = {
        novelId,
        name,
        plotGroups,
        mode,
        layout,
        displayFormat,
        callback: (plotId: string) => {
          $router.push({ name: "plotEdit", params: { novelId, plotId } });
        },
      };
      this.$store.dispatch("plotModule/createSubPlot", createSubPlotPayload);

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    selectMode(item) {
      this.selectedMode = item;
    },
  },
  computed: {
    isCompleted() {
      const { name } = this;
      return !!name;
    },
  },
});

interface Props {
  novelId: string;
  hasLimit: boolean;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  name: string;
  selectedMode: PlotModeSelectBoxItem;
  plotModes: PlotModeSelectBoxItem[];
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
  selectMode(item: PlotModeSelectBoxItem): void;
}

interface Computed {
  isCompleted: boolean;
}

export type PlotCreateDialogProps = Props;

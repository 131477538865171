
import Vue, { PropType } from "vue";
import { Character, CharacterFolder } from "@/lib/models";
import SortableList from "@/components/molecules/SortableList.vue";
import ListFolderItem from "@/components/molecules/ListFolderItem.vue";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
import FolderRenameDialog, { FolderRenameDialogProps } from "@/components/ui/novel/FolderRenameDialog.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SortableList, ListFolderItem },
  props: {
    novelId: String, // 小説ID
    items: Array as PropType<CharacterFolder[]>, // 登場人物フォルダ
    charactersCount: Number, // フォルダに含まれる登場人物の数
    isInsert: Boolean, // 状態管理用。登場人物にフォルダを設定する状態かどうかを管理
    selectedCharacter: Object as PropType<Character>, // フォルダを設定しようとしている登場人物
  },
  data() {
    return {
      folders: this.items || [],
      isEditMode: false,
      selectedInsertFolderIds: [],
    };
  },
  watch: {
    items() {
      this.folders = this.items;
    },
    isInsert: {
      handler(isInsert: boolean) {
        if (!isInsert) return;

        const folders = this.foldersBelongCharacter(this.selectedCharacter.characterId) as CharacterFolder[];
        this.selectedInsertFolderIds = folders.map((folder) => folder.characterFolderId);
      },
      immediate: true,
    },
  },
  computed: {
    foldersBelongCharacter() {
      return (characterId) => this.$store.getters["characterModule/foldersBelongCharacter"](characterId);
    },
    isSelectedFolder() {
      return (characterFolderId) => this.selectedInsertFolderIds.includes(characterFolderId);
    },
  },
  methods: {
    selectFolder(characterFolderId) {
      this.$emit("selectedFolder", characterFolderId);
    },
    async completeSetFolder() {
      const { folders, selectedInsertFolderIds, selectedCharacter } = this;
      const updatedFolders = (folders as CharacterFolder[]).map((folder) => {
        const target = folder;
        if (selectedInsertFolderIds.includes(target.characterFolderId)) {
          target.characterKeys = [...new Set([...target.characterKeys, selectedCharacter.characterId])];
        } else {
          target.characterKeys = target.characterKeys.filter((key) => key !== selectedCharacter.characterId);
        }
        return target;
      });

      await this.updateCharacterFolder(updatedFolders);
      this.$emit("clickCompleteSetFolder");
    },
    async createCharacterFolder() {
      const { novelId } = this;
      const name = "新しいフォルダ";

      await this.$store.dispatch("characterModule/createFolder", { novelId, name });
    },
    async renameCharacterFolder(key) {
      const renameCallback = async (name: string) => {
        const { novelId } = this;
        const characterFolders = (this.folders as CharacterFolder[]).map((folder) => {
          const item = folder;
          if (item.characterFolderId === key) item.name = name;
          return item;
        });
        await this.$store.dispatch("characterModule/updateFolder", { novelId, characterFolders });
      };
      const { name } = (this.folders as CharacterFolder[]).find((folder) => folder.characterFolderId === key)!;

      const confirmDialog = new Dialog(FolderRenameDialog);
      const data: FolderRenameDialogProps = {
        name,
        renameCallback,
      };
      await confirmDialog.show(data);
    },
    async deleteCharacterFolder(key) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "削除の最終確認",
        content: "本当にフォルダを削除しますか？<br />※ 一度削除したフォルダは元に戻せません。",
        change: "削除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }

      const { novelId } = this;
      const characterFolders = (this.folders as CharacterFolder[]).filter((folder) => folder.characterFolderId !== key);
      await this.$store.dispatch("characterModule/updateFolder", { novelId, characterFolders });

      this.$notify({
        title: "削除しました",
        type: "error",
      });
    },
    async switchEditMode() {
      // 編集終了を押した場合、かつ変更していた場合
      if (this.isEditMode) {
        await this.updateCharacterFolder(this.folders);
      }

      this.isEditMode = !this.isEditMode;
    },
    async updateCharacterFolder(folders) {
      const { novelId } = this;

      // 変なデータが有ったらこっそり修正する
      const characterFolders = folders.map((folder) => {
        const characterKeys = folder.characterKeys
          .filter((key) => key != null)
          .filter((el, index, selfArray) => selfArray.indexOf(el) === index); // 重複Keyは排除
        return { ...folder, characterKeys };
      });

      await this.$store.dispatch("characterModule/updateFolder", { novelId, characterFolders });
    },
    onToggleInsertFolder(characterFolderId) {
      if ((this.selectedInsertFolderIds as string[]).includes(characterFolderId)) {
        this.selectedInsertFolderIds = this.selectedInsertFolderIds.filter(
          (folderId) => folderId !== characterFolderId
        );
      } else {
        this.selectedInsertFolderIds.push(characterFolderId);
      }
    },
  },
});

interface Props {
  novelId: string;
  items: CharacterFolder[];
  charactersCount: number;
  isInsert: boolean;
  selectedCharacter: Character | null;
}

interface Data {
  folders: CharacterFolder[];
  isEditMode: boolean;
  selectedInsertFolderIds: string[];
}

interface Computed {
  foldersBelongCharacter(characterId: string): CharacterFolder[];
  isSelectedFolder(characterFolderId: string): boolean;
}

interface Methods {
  selectFolder(characterFolderId: string): void;
  completeSetFolder(): Promise<void>;
  createCharacterFolder(): Promise<void>;
  renameCharacterFolder(key: string): void;
  deleteCharacterFolder(key: string): void;
  switchEditMode(): Promise<void>;
  updateCharacterFolder(characterFolders: CharacterFolder[]): Promise<void>;
  onToggleInsertFolder(characterFolderId: string): void;
}

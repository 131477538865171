
import Vue, { PropType } from "vue";
import SelectBox from "@/components/molecules/SelectBox.vue";
import InputText from "@/components/atoms/InputText.vue";
import { plotModes, PlotModeSelectBoxItem, ChildPlotItem, NewChildPlotItem } from "@/lib/plot";
import { SubPlot } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SelectBox, InputText },
  props: {
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "作成する",
    },
    plotList: {
      type: Array as PropType<SubPlot[]>,
    },
    hasLimit: Boolean,
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    return {
      selectedPlots: undefined,
      plots: [],
      name: "",
      selectedMode: plotModes[0],
      plotModes,
    };
  },
  created() {
    const defaultPlots = [
      {
        plotId: "new",
        name: "新規作成する",
        image: "none",
      },
    ];
    this.plots = defaultPlots.concat(this.plotList.map((i) => ({ plotId: i.plotId, name: i.name, image: "none" })));
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        const data = {
          plotId: this.selectedPlots!.plotId,
          name: this.selectedPlots!.name,
          mode: this.selectedMode,
        };
        if (data.plotId === "new") {
          data.name = this.name || "プロット名未設定";
        }
        this.positiveCallback(data as NewChildPlotItem);
      }

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    selectPlot(plots) {
      this.selectedPlots = plots;
    },
    selectMode(item) {
      this.selectedMode = item;
    },
  },
});

interface Props {
  negative?: string;
  positive?: string;
  plotList: Array<SubPlot>;
  hasLimit: Boolean;
  negativeCallback?: () => void;
  positiveCallback?: (data: NewChildPlotItem) => void;
}

interface Data {
  selectedPlots?: SubPlot;
  plots: Array<ChildPlotItem>;
  name: string;
  selectedMode: PlotModeSelectBoxItem;
  plotModes: PlotModeSelectBoxItem[];
}

interface Computed {}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
  selectPlot: (plot: SubPlot) => void;
  selectMode(item: PlotModeSelectBoxItem): void;
}

export type CreateChildPlotDialogProps = Props;

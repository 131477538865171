
import Vue from "vue";
import ListTemplate from "@/components/templates/ListTemplate.vue";
import WorldViewList from "@/components/organisms/WorldViewList.vue";
import WorldViewFolderList from "@/components/organisms/WorldViewFolderList.vue";
import WorldViewDetail from "@/components/organisms/WorldViewDetail.vue";
import SpTab from "@/components/molecules/SpTab.vue";
import BackToList from "@/components/atoms/BackToList.vue";
import { Plan, WorldView, WorldViewFolder } from "@/lib/models";
import { cloneDeep } from "lodash";
// NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
// import { Dialog } from "@/lib/utils";
// import PremiumFeatureDialog, { PremiumFeatureDialogProps } from "@/components/ui/PremiumFeatureDialog.vue";
// import { downloadPublicImage } from "@/lib/s3";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ListTemplate,
    WorldViewList,
    WorldViewFolderList,
    WorldViewDetail,
    BackToList,
    SpTab,
  },
  props: {
    novelId: String,
    worldViewId: String,
  },
  data() {
    return {
      selectedWorldView: null,
      isShowFolderList: false,
      isFolderInsertMode: false,
      targetWorldView: null,
      selectedFolderId: null,
    };
  },
  async created() {
    const { novelId } = this;
    await this.$store.dispatch("worldViewModule/initialize", novelId);
    this.load();
    if (!this.worldViews.length) this.$router.push({ name: "worldViewCreate", params: { novelId } });
  },
  computed: {
    worldViews() {
      const { $store, selectedFolderId } = this;

      let worldViews = $store.getters["worldViewModule/worldViewList"] as WorldView[];

      if (selectedFolderId) {
        worldViews = $store.getters["worldViewModule/worldViewsInFolder"](selectedFolderId) as WorldView[];
      }

      return cloneDeep(worldViews);
    },
    worldViewFolder() {
      const { selectedFolderId } = this;
      const worldViewFolder = this.$store.getters["worldViewModule/worldViewFolder"](selectedFolderId);
      return cloneDeep(worldViewFolder);
    },
    worldViewFolders() {
      const worldViewFolders = this.$store.getters["worldViewModule/worldViewFolderList"];
      return cloneDeep(worldViewFolders);
    },
    worldViewsCount() {
      const worldViews = this.$store.getters["worldViewModule/worldViewList"] as WorldView[];
      return worldViews.length;
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
  },
  methods: {
    async selectFolder(worldViewFolderId) {
      this.selectedFolderId = worldViewFolderId;
      this.isShowFolderList = false;
    },
    async showFolderList() {
      if (this.isFree) {
        this.showPremiumFeatureDialog();
        return;
      }

      this.isShowFolderList = true;
    },
    setFolder(el) {
      if (this.isFree) {
        this.showPremiumFeatureDialog();
        return;
      }

      this.targetWorldView = el.worldView;
      this.selectedFolderId = el.worldViewFolder ? el.worldViewFolder.worldViewFolderId : null;
      this.isFolderInsertMode = true;
      this.isShowFolderList = true;
    },
    async finishFolderSelect() {
      await this.selectFolder(this.selectedFolderId);

      this.targetWorldView = null;
      this.isFolderInsertMode = false;
      this.isShowFolderList = false;
    },
    load() {
      const list = this.worldViews as WorldView[];
      if (!list) {
        this.selectedWorldView = null;
        return;
      }
      if (this.worldViewId) {
        this.selectedWorldView = list.find((c) => c.worldViewId === this.worldViewId);
        return;
      }
      if (list.length) {
        const [first] = list;
        this.selectedWorldView = first;
      }
    },
    async showPremiumFeatureDialog() {
      // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
      // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
      // const data: PremiumFeatureDialogProps = {
      //   title: "フォルダ機能はプレミアム機能です",
      //   text: "Nolaプレミアムにご登録いただくと、お好きな名前で資料を管理できる『フォルダ管理機能』をご利用いただけます。”〇〇国”や”〇〇星”などの世界ごとの管理や、キャラクターが登場するシーンごとの資料管理ができますので、お手すきの際にぜひご活用いただだけたら幸いです。",
      //   image: (await downloadPublicImage("shared/images/gif/worldview_folder.gif")) as string,
      // };
      // premiumFeatureDialog.show(data);

      this.$router.push({ name: "subscriptionAnnounce", query: { from: "worldviewFolder" } });
    },
  },
  watch: {
    $route: "load",
  },
});

interface Props {
  novelId: string;
  worldViewId: string;
}

interface Data {
  selectedWorldView: WorldView | null;
  isShowFolderList: boolean;
  isFolderInsertMode: boolean;
  targetWorldView: WorldView | null;
  selectedFolderId: string | null;
}

interface Computed {
  worldViews: WorldView[];
  worldViewFolder: WorldViewFolder;
  worldViewFolders: WorldViewFolder[];
  worldViewsCount: number;
  plan: Plan;
  isFree: boolean;
}

interface Methods {
  selectFolder(worldViewFolderId: string): Promise<void>;
  showFolderList(): Promise<void>;
  setFolder(el): void;
  finishFolderSelect(): Promise<void>;
  load(): void;
  showPremiumFeatureDialog(): Promise<void>;
}


import Vue from "vue";

interface Methods {
  onClickOutSide: () => void;
}

interface Props {
  title: string;
  content?: string;
  action?: string;
  close?: () => void;
  isError?: boolean;
  width?: number | string;
  fontSize?: string;
}

export default Vue.extend<{}, Methods, {}, Props>({
  methods: {
    onClickOutSide() {
      const { close } = this;
      if (!close) {
        this.$close(false);
        return;
      }

      close();
    },
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    close: Function,
    content: String,
    action: String,
    isError: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: [Number, String],
      default: 300,
      required: false,
    },
    fontSize: {
      type: String,
      default: "1em",
      required: false,
    },
  },
});

export type CupertinoAlertDialogProps = Props;

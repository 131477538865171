
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";

interface Methods {
  onClickOutSide: () => void;
  onCancelClick: () => void;
  onUpdateClick: () => void;
}

interface Props {
  id: string;
  content: string;
}

export default Vue.extend<{}, Methods, {}, Props>({
  components: { CupertinoAlertDialog },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onCancelClick() {
      this.$close(false);
    },
    onUpdateClick() {
      this.$close(true);
    },
  },

  props: {
    id: String,
    content: String,
  },
});

export type NolaNovelUpdateConfirmProps = Props;


import Vue from "vue";
import NovelDetail from "@/components/organisms/NovelDetail.vue";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  props: { novelId: String },
  components: { NovelDetail },
});


import Vue from "vue";

export default Vue.extend({
  props: {
    /** text type */
    type: {
      type: String,
      default: "text",
    },
    label: String,
    maxlength: Number,
    min: Number,
    max: Number,
    readonly: Boolean,
  },
  model: {
    event: "input",
  },
  methods: {
    handleInput(e: any) {
      const { $refs, type, min, max } = this;

      const { input } = $refs;
      let { value } = e.target;

      if (type === "number") {
        value = Number(value);

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(min)) {
          if (Number(min) > value) {
            value = min;

            // NOTE: inputに表示される値に変更が反映されないので直接Elementの値を変更する
            (input as HTMLInputElement).value = min.toString();
          }
        }

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(max)) {
          if (Number(max) < value) {
            value = max;

            // NOTE: inputに表示される値に変更が反映されないので直接Elementの値を変更する
            (input as HTMLInputElement).value = max.toString();
          }
        }

        value = value.toString();
      }

      this.$emit("input", value);
    },
  },
});

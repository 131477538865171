var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"plotchange"},[_c('h1',[_vm._v("プロットの構成を変更する")]),_vm._m(0),_c('div',{staticClass:"plotchange-card"},[_c('div',{staticClass:"plotchange-card-title"},[_vm._m(1),(_vm.currentMode === 'kishoutenketsu')?_c('button',{staticClass:"selected",attrs:{"disabled":""}},[_vm._v(" 選択中 ")]):_c('button',{staticClass:"change",on:{"click":function($event){return _vm.changePlotMode('kishoutenketsu')}}},[_vm._v(" 変更する ")])]),_vm._m(2)]),_c('div',{staticClass:"plotchange-card"},[_c('div',{staticClass:"plotchange-card-title"},[_vm._m(3),(_vm.currentMode === 'johakyu')?_c('button',{staticClass:"selected",attrs:{"disabled":""}},[_vm._v("選択中")]):_c('button',{staticClass:"change",on:{"click":function($event){return _vm.changePlotMode('johakyu')}}},[_vm._v("変更する")])]),_vm._m(4)]),_c('div',{staticClass:"plotchange-card"},[_c('div',{staticClass:"plotchange-card-title"},[_vm._m(5),(_vm.currentMode === 'custom')?_c('button',{staticClass:"selected",attrs:{"disabled":""}},[_vm._v("選択中")]):_c('button',{staticClass:"change",on:{"click":function($event){return _vm.changePlotMode('custom')}}},[_vm._v("変更する")])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" プロットの項目構成を変更することができます。 "),_c('br'),_vm._v("自分のスタイルにあったプロット構成をお選びください。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mode-name-column"},[_c('div',[_vm._v("起承転結モード")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"plotchange-card-description"},[_vm._v(" プロットの構成を「起承転結」で作成します。 "),_c('br'),_vm._v("すでにデータを入力済みの場合は、すべてのアイテムを一度、「起」に紐づける形で再編成します。 "),_c('br'),_vm._v("なお、大項目の記入欄に記入していた内容も、「起」の入力欄にまとめて表示します。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mode-name-column"},[_c('div',[_vm._v("序破急モード")]),_c('img',{attrs:{"src":require("@/assets/img/icon/premium.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"plotchange-card-description"},[_vm._v(" プロットの構成を「序破急」で作成します。 "),_c('br'),_vm._v("すでにデータを入力済みの場合は、すべてのアイテムを一度、「序」に紐づける形で再編成します。 "),_c('br'),_vm._v("なお、大項目の記入欄に記入していた内容も、「序」の入力欄にまとめて表示します。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mode-name-column"},[_c('div',[_vm._v("カスタムモード")]),_c('img',{attrs:{"src":require("@/assets/img/icon/premium.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"plotchange-card-description"},[_vm._v(" プロットの構成を自由に作成できます。 "),_c('br'),_vm._v("すでにデータを入力済みの場合は、そのアイテムの位置はそのままに、項目名を変更することができます。 "),_c('br'),_vm._v("起承転結などの名前を変更したり、項目を追加したりしてお好きな名称でプロットを管理してみてください。 ")])
}]

export { render, staticRenderFns }

import Vue, { PropType } from "vue";
import ListItem from "@/components/molecules/ListItem.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import ButtonSolid from "@/components/atoms/ButtonSolid.vue";
import { getWorldViewlKey } from "@/lib/s3";
import { DeleteWorldView, WorldView, WorldViewFolder } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ListItem, SortableList, ButtonSolid },
  props: {
    novelId: String,
    worldViews: Array as PropType<WorldView[]>,
    selectedId: String,
    folder: Object as PropType<WorldViewFolder>,
    allFolders: Array as PropType<WorldViewFolder[]>,
    needsDoCustomClickFunction: {
      type: Boolean,
      default: false,
    },
    onClickItem: {
      type: Function,
      default: () => false,
      required: false,
    }, // アイテムクリック時の処理を外部から指定したい場合に渡す
  },
  data() {
    return {
      isEditing: false,
      items: this.worldViews,
    };
  },
  watch: {
    worldViews() {
      this.items = this.worldViews;
    },
  },
  computed: {
    foldersBelongWorldView() {
      return (worldViewId) => this.$store.getters["worldViewModule/foldersBelongWorldView"](worldViewId);
    },
    folderName() {
      return (item) => {
        const folders = this.foldersBelongWorldView(item.worldViewId) as WorldViewFolder[];
        return folders.reduce((acc, cur) => (acc ? `${acc} / ${cur.name}` : `${cur.name}`), "");
      };
    },
  },
  methods: {
    async saveOrder() {
      const { novelId, items } = this;
      const order = (items as WorldView[]).map((item) => item.worldViewId);

      if (this.folder) {
        const folders = this.allFolders as WorldViewFolder[];
        const worldViewFolders = folders.map((folder) => {
          const item = folder;
          if (item.worldViewFolderId === this.folder.worldViewFolderId) {
            item.worldViewKeys = order;
          }
          return item;
        });

        await this.$store.dispatch("worldViewModule/updateFolder", { novelId, worldViewFolders });
      } else {
        await this.$store.dispatch("worldViewModule/updateOrder", { novelId, order });
        await this.$store.dispatch("worldViewModule/initialize", novelId);
      }

      this.isEditing = false;
    },
    getWorldViewlKey(worldView) {
      return getWorldViewlKey(this.novelId, worldView);
    },
    backToFolderList() {
      this.$emit("backToFolderList");
    },
    setFolder(el) {
      this.$emit("clickSetFolder", {
        worldView: el.item,
        worldViewFolder: this.folder,
      });
    },
    async deleteWorldView(worldViewId) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "削除の最終確認",
        content: "本当に世界観を削除しますか？<br />※ 一度削除した世界観は元に戻せません。",
        change: "削除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }
      const { novelId } = this;
      await this.$store.dispatch("worldViewModule/deleteWorldView", { novelId, worldViewId } as DeleteWorldView);

      this.$notify({
        title: "削除しました",
        type: "error",
      });

      this.setModified(false);

      this.$router.push({ name: "worldViews" });
    },
  },
});

interface Props {
  novelId: string;
  worldViews: WorldView[];
  selectedId: string;
  folder: WorldViewFolder;
  allFolders: WorldViewFolder[];
  needsDoCustomClickFunction: boolean;
  onClickItem?: boolean;
}

interface Data {
  isEditing: boolean;
  items: WorldView[];
}

interface Computed {
  foldersBelongWorldView(worldViewId: string): WorldViewFolder[];
  folderName(item: WorldView): string;
}

interface Methods {
  saveOrder(): Promise<void>;
  getWorldViewlKey(worldView: WorldView): string | null;
  backToFolderList(): void;
  setFolder(el): void;
  deleteWorldView(worldViewId: string): Promise<void>;
}

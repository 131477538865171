
import Vue, { PropType } from "vue";
import { SharedLink, Manuscript, User, SharedLinkKind, SubPlot, PlotList } from "@/lib/models";
import { Comment as CommentDexie } from "@/lib/indexeddb";
import DotIcon from "@/components/atoms/DotIcon.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      SharedLinkKind,
    };
  },
  components: { DotIcon },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  methods: {
    async select(contentKey) {
      this.$store.dispatch("sharedLinkModule/selectContent", contentKey);
    },
    back() {
      this.$store.dispatch("sharedLinkModule/initializeSelectedSharedLink");
    },
  },
  computed: {
    user() {
      return this.$store.getters["userModule/user"] as User;
    },
    selectedSharedLinkId() {
      return this.$store.getters["sharedLinkModule/selectedSharedLinkId"];
    },
    sharedLinkName() {
      const sharedLinkList = this.$store.getters["sharedLinkModule/sharedLinkList"] as SharedLink[];
      const sharedLink = sharedLinkList.find((sharedLink) => sharedLink.sharedLinkId === this.selectedSharedLinkId);
      return sharedLink!.name || "NO TITLE";
    },
    manuscriptList() {
      const sharedLinkList = this.$store.getters["sharedLinkModule/sharedLinkList"] as SharedLink[];
      const sharedLink: SharedLink = sharedLinkList.find(
        (sharedLink) => sharedLink.sharedLinkId === this.selectedSharedLinkId
      )!;
      const manuscriptList = this.$store.getters["manuscriptModule/manuscriptList"] as Manuscript[];
      return manuscriptList.filter((manuscript) => sharedLink.manuscriptKeys!.includes(manuscript.key!));
    },
    plotList() {
      const sharedLinkList = this.$store.getters["sharedLinkModule/sharedLinkList"] as SharedLink[];
      const sharedLink: SharedLink = sharedLinkList.find(
        (sharedLink) => sharedLink.sharedLinkId === this.selectedSharedLinkId
      )!;

      const plots = this.$store.getters["plotModule/plots"];
      const overallPlot = {
        name: "全体プロット",
        plotId: `overall-plot-${sharedLink.novelId}`,
        ...plots,
      };

      const subPlots = this.$store.getters["plotModule/subPlots"];

      if (sharedLink && sharedLink.plotIds) {
        return sharedLink.plotIds
          .map((plotId) => {
            if (plotId === overallPlot.plotId) {
              return overallPlot;
            }
            return subPlots.find((subPlot: SubPlot) => subPlot.plotId === plotId);
          })
          .filter((plot) => plot !== undefined);
      }
      return [];
    },
    isIncludesUnreadComment() {
      return (manuscriptKey) => {
        const { novelId, kind } = this;
        const { userId } = this.user as User;
        const sharedLinkId = this.selectedSharedLinkId;
        const unreadCommentList = this.$store.getters["sharedLinkModule/unreadCommentList"](
          userId,
          novelId,
          kind,
          sharedLinkId,
          manuscriptKey
        ) as CommentDexie[];
        return !!unreadCommentList.length;
      };
    },
  },
});

interface Data {
  SharedLinkKind: typeof SharedLinkKind;
}

interface Props {
  novelId: string;
  kind: string;
}

interface Methods {
  select: (sharedLinkId: string) => void;
  back: () => void;
}

interface Computed {
  user: User;
  selectedSharedLinkId: string;
  sharedLinkName: string;
  manuscriptList: Manuscript[];
  plotList: PlotList | [];
  isIncludesUnreadComment: (manuscriptKey: string) => boolean;
}

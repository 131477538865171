
import Vue from "vue";
import { callApi } from "@/lib/api";
import InputText from "@/components/atoms/InputText.vue";
import CorrelationDiagram from "@/components/organisms/CorrelationDiagram.vue";
import CorrelationListItem from "@/components/molecules/CorrelationListItem.vue";
import { getCharacterKey } from "@/lib/s3";
import { showNotifyDialog } from "@/lib/dialog";
import equal from "deep-equal";
import clone from "lodash/cloneDeep";

export default Vue.extend({
  components: {
    InputText,
    CorrelationDiagram,
    CorrelationListItem,
  },
  props: {
    novelId: String,
    correlationGroupName: String,
    selectedCorrelationGroupId: String,
    items: Array as any,
    characterItems: Array as any,
    correlationDiagram: Object,
  },
  data() {
    return {
      name: this.correlationGroupName,
      changed: false,
      isSelecting: false,
      correlationList: this.items,
      diagram: this.correlationDiagram || {
        correlationKeys: [],
        coordinate: [],
      },
    };
  },
  methods: {
    async save() {
      const { novelId, name, diagram } = this;

      const query = `
      mutation UpdateCorrelationGroup ($input: CorrelationGroupUpdateInput!) {
        updateCorrelationGroup (input: $input) {
          novelId
          correlationGroupId
          name
          diagram {
            correlationKeys
            coordinate {
              characterId
              x
              y
            }
          }
        }
      }
      `;
      await callApi(query, {
        input: {
          novelId,
          correlationGroupId: this.selectedCorrelationGroupId,
          name,
          diagram,
        },
      });

      this.setModified((this.changed = false));
      this.$emit("fetch");

      this.$notify({
        title: "保存しました",
        type: "success",
      });
    },
    change(e) {
      if (this.correlationGroupName === this.name && equal(this.correlationDiagram, this.diagram)) {
        this.setModified((this.changed = false));
      } else {
        this.setModified((this.changed = true));
      }
    },
    getCharacterData(characterId) {
      const character = this.characterItems.find((item) => item.characterId === characterId);
      character.image = getCharacterKey(this.novelId, character);
      return character;
    },
    isSelectedCorrelation(correlationId) {
      return this.diagram.correlationKeys.includes(correlationId);
    },
    selectCorrelation(correlation) {
      const { correlationId, characterA, characterB } = correlation;

      if (this.isSelectedCorrelation(correlationId)) {
        this.diagram.correlationKeys = this.diagram.correlationKeys.filter((key) => key !== correlationId);
        this.removeCoordinate(characterA, characterB);
      } else {
        this.diagram.correlationKeys.push(correlationId);
        this.setInitialCoordinate(characterA, characterB);
      }
      this.change();
    },
    removeCoordinate(characterA, characterB) {
      let isRemoveCharacterA = true;
      let isRemoveCharacterB = true;

      // 相関図に表示されているすべての相関関係を取得（自身の相関関係はselectCorrelation()でcorrelationKeysから除外済み）
      const selectedCorrelationList = this.correlationList.filter((item) =>
        this.diagram.correlationKeys.find((key) => key === item.correlationId)
      );

      // 相関関係にcharacterA or characterBが含まれている場合は除外対象から外す
      selectedCorrelationList.forEach((item) => {
        if (characterA === item.characterA || characterA === item.characterB) {
          isRemoveCharacterA = false;
        }
        if (characterB === item.characterA || characterB === item.characterB) {
          isRemoveCharacterB = false;
        }
      });

      // coordinateから除外
      if (isRemoveCharacterA) {
        this.diagram.coordinate = this.diagram.coordinate.filter((item) => item.characterId !== characterA);
      }
      if (isRemoveCharacterB) {
        this.diagram.coordinate = this.diagram.coordinate.filter((item) => item.characterId !== characterB);
      }
    },
    async setInitialCoordinate(characterA, characterB) {
      const hasCharacterA = this.diagram.coordinate.some((item) => item.characterId === characterA);
      const hasCharacterB = this.diagram.coordinate.some((item) => item.characterId === characterB);

      // 指定のcharacterがcoordinateに存在しない場合は追加
      if (!hasCharacterA) {
        const { x, y } = await this.calcCoordinate();
        this.diagram.coordinate.push({
          characterId: characterA,
          x,
          y,
        });
      }
      if (!hasCharacterB) {
        const { x, y } = await this.calcCoordinate();
        this.diagram.coordinate.push({
          characterId: characterB,
          x,
          y,
        });
      }
    },
    /** 配置可能な座標を計算 */
    async calcCoordinate() {
      let x = 0;
      let y = 0;
      let i = 0;
      let isBreak = false;

      // 右下方向(x++, y++)
      while (i <= 40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x >= i;
        const isMaxY = y >= i;
        if (isMaxX && isMaxY) {
          y = 0;
          i += 1;
          x = i;
        } else if (isMaxX) {
          y += 1;
          if (y >= i) {
            x = 0;
          }
        } else {
          x += 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      // 左上方向(x--, y--)
      x = 0;
      y = 0;
      i = 0;
      while (i <= -40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x <= i;
        const isMaxY = y <= i;
        if (isMaxX && isMaxY) {
          y = 0;
          i -= 1;
          x = i;
        } else if (isMaxX) {
          y -= 1;
          if (y <= i) {
            x = 0;
          }
        } else {
          x -= 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      // 右上方向(x++, y--)
      x = 0;
      y = 0;
      i = 0;
      while (Math.abs(i) >= 40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x >= i;
        const isMaxY = y <= -i;
        if (isMaxX && isMaxY) {
          y = 0;
          i += 1;
          x = i;
        } else if (isMaxX) {
          y -= 1;
          if (y <= -i) {
            x = 0;
          }
        } else {
          x += 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      // 左下方向(x--, y++)
      x = 0;
      y = 0;
      i = 0;
      while (Math.abs(i) >= 40) {
        if (this.checkCoordinate(x, y)) {
          isBreak = true;
          break;
        }
        const isMaxX = x <= -i;
        const isMaxY = y >= i;
        if (isMaxX && isMaxY) {
          y = 0;
          i += 1;
          x = -i;
        } else if (isMaxX) {
          y += 1;
          if (y >= i) {
            x = 0;
          }
        } else {
          x -= 1;
        }
      }
      if (isBreak) {
        return { x, y };
      }

      // alert
      await showNotifyDialog({
        title: "相関図エラー",
        content: "相関図に表示できる登場人物の数が上限に達しました。",
      });

      return { x: 0, y: 0 };
    },
    /** 配置可能な座標かチェック */
    checkCoordinate(x, y, characterId = null) {
      // 有効な座標範囲: (-40, -40) <= (x, y) <= (40, 40)
      if (Math.abs(x) > 40 || Math.abs(y) > 40) {
        return false;
      }

      let { coordinate } = this.diagram;

      if (characterId) {
        coordinate = coordinate.filter((item) => item.characterId !== characterId);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of coordinate) {
        // 2点間の距離 = √((x2 - x1) ^ 2 + (y2 - y1) ^ 2)
        const distance = Math.sqrt((x - item.x) ** 2 + (y - item.y) ** 2);
        if (distance < 3) {
          return false;
        }
      }
      return true;
    },
    moveCharacter(payload) {
      const { characterId, x, y } = payload;

      if (this.checkCoordinate(x, y, characterId)) {
        this.diagram.coordinate = this.diagram.coordinate.filter((item) => item.characterId !== characterId);
        this.diagram.coordinate.push({
          characterId,
          x,
          y,
        });
      }

      this.change();
    },
    filterCorrelationList() {
      if (!this.diagram) {
        this.diagram = {
          correlationKeys: [],
          coordinate: [],
        };
      }

      const { correlationList } = this;
      const { correlationKeys } = this.diagram;

      return correlationList.filter((item) => correlationKeys.find((key) => key === item.correlationId));
    },
  },
  watch: {
    correlationGroupName() {
      this.name = this.correlationGroupName;
    },
    items() {
      this.correlationList = clone(this.items);
    },
    correlationDiagram: {
      handler() {
        this.diagram = clone(this.correlationDiagram);
      },
      deep: true,
    },
  },
});


import Vue from "vue";

interface Props {
  value: string;
  isInitial: boolean;
}

interface Data {}

interface Computed {
  inputValue: string;
}

interface Methods {
  deleteItem(e: MouseEvent): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    value: String,
    isInitial: Boolean,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    deleteItem(e) {
      this.$emit("deleteItem", e);
    },
  },
});

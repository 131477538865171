
import Vue from "vue";
import ImageView from "@/components/atoms/ImageView.vue";
import LocalImageSelector from "@/components/atoms/LocalImageSelector.vue";
import { openPresetSelectorDialog } from "@/lib/dialog";

import { setCanvas } from "@/lib/image-save";

function getMousePos(canvas, evt) {
  const rect = canvas.getBoundingClientRect(); // abs. size of element
  const scaleX = canvas.width / rect.width; // relationship bitmap vs. element for X
  const scaleY = canvas.height / rect.height; // relationship bitmap vs. element for Y

  return {
    x: (evt.clientX - rect.left) * scaleX, // scale mouse coordinates after they have
    y: (evt.clientY - rect.top) * scaleY, // been adjusted to be relative to element,
    dx: evt.movementX * scaleX,
    dy: evt.movementY * scaleY,
  };
}

function getHandlePoint(e) {
  const { x, y } = getMousePos(e.srcElement, e);
  const [l, t, r, b] = e.srcElement.dataset.clip.split(",").map((n) => parseInt(n, 10));
  const range = { l, t, r, b };

  const hp: any = {};

  hp.x = (r + l) / 2 > x ? "l" : "r";
  hp.ox = (r + l) / 2 > x ? "r" : "l";
  hp.dx = (r + l) / 2 > x ? -1 : 1;
  hp.y = (b + t) / 2 > y ? "t" : "b";
  hp.oy = (b + t) / 2 > y ? "b" : "t";
  hp.dy = (b + t) / 2 > y ? -1 : 1;

  if ((range[hp.x] - x) * (range[hp.x] - x) + (range[hp.y] - y) * (range[hp.y] - y) < 50 * 50) {
    return hp;
  }
  return { dragMode: true };
}

export default Vue.extend({
  components: {
    ImageView,
    LocalImageSelector,
  },
  props: {
    defaultImage: String,
    placeholder: String,
    value: String,
    ratio: {
      default: 1,
      type: Number,
    },
  },
  data() {
    return {
      imageData: null,
      image: null,
      type: null as null | "local" | "preset",

      handlePoint: null as any,
      imageArea: null,
    };
  },
  mounted() {
    const cv = this.$refs.localImgCanvas;
    setCanvas(cv);
  },

  computed: {
    displayingImage() {
      const { defaultImage, image, type } = this;

      return (type && image) || defaultImage;
    },
  },

  methods: {
    updateClip(canvas: any, range: any) {
      const ctx = canvas.getContext("2d")!;
      ctx.fillStyle = "rgb(255, 255, 255)";
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      const { sx, sy, sw, sh } = this.imageArea;
      ctx.drawImage(this.image, sx, sy, sw, sh);
      ctx.beginPath();
      const T = 10;
      ctx.lineWidth = T;
      ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
      ctx.strokeRect(range.l - T, range.t - T, range.r - range.l + T * 2, range.b - range.t + T * 2);

      ctx.restore();
      // eslint-disable-next-line no-param-reassign
      canvas.dataset.clip = [range.l, range.t, range.r, range.b].join(",");
    },
    mouseDownEvent(e) {
      this.handlePoint = getHandlePoint(e);
    },
    mouseUpEvent(e) {
      this.handlePoint = null;
    },
    mouseMoveEvent(e) {
      const hp = getHandlePoint(e);
      let cursor = "move";
      if (hp.x === "l" && hp.y === "t") cursor = "nw-resize";
      if (hp.x === "l" && hp.y === "b") cursor = "sw-resize";
      if (hp.x === "r" && hp.y === "t") cursor = "ne-resize";
      if (hp.x === "r" && hp.y === "b") cursor = "se-resize";
      e.srcElement.style.cursor = cursor;

      const { handlePoint } = this;
      if (!handlePoint) {
        return;
      }
      const canvas = e.srcElement as HTMLCanvasElement;
      const { y, dx, dy } = getMousePos(canvas, e);
      const [l, t, r, b] = e.srcElement.dataset.clip.split(",").map((n) => parseInt(n, 10));
      const range = { l, t, r, b };

      if (handlePoint.dragMode) {
        range.l += dx;
        range.r += dx;
        range.t += dy;
        range.b += dy;
      } else {
        range[handlePoint.y] = y;
        range[handlePoint.x] = range[handlePoint.ox] + (handlePoint.dx * (range.b - range.t)) / this.ratio;
      }
      this.updateClip(canvas, range);
    },
    loadLocalImage({ data }) {
      const img = new Image();

      img.onload = () => {
        const canvas = this.$refs.localImgCanvas as HTMLCanvasElement;
        const cw = canvas.width;
        const ch = canvas.height;
        let { width, height } = img;

        const MAX = cw - 200;
        if (width >= height && width > MAX) {
          height = (height * MAX) / width;
          width = MAX;
        } else if (height >= width && height > MAX) {
          width = (width * MAX) / height;
          height = MAX;
        }

        const ctx = canvas.getContext("2d")!;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let l = cw / 2 - width / 2;
        let t = ch / 2 - height / 2;
        this.imageArea = { sx: l, sy: t, sw: width, sh: height };
        this.image = img;

        let w;
        let h;
        if (width / height >= this.ratio) {
          w = width;
          h = width * this.ratio;
        } else {
          w = height / this.ratio;
          h = height;
        }
        l = cw / 2 - w / 2;
        t = ch / 2 - h / 2;
        this.updateClip(canvas, { l, t, r: l + w, b: t + h });

        this.$emit("input", `file:${this.storagePath}`);
        this.type = "local";
      };
      img.src = data;
    },
    async openPresetDialog() {
      const response = await openPresetSelectorDialog();

      if (!response) {
        return;
      }

      this.type = "preset";
      this.image = `preset:${response.key}`;
      this.$emit("input", `preset:${response.key}`);
    },
  },
});

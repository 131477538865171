
import Vue from "vue";
import gql from "graphql-tag";
import { callApi } from "@/lib/api";
import crypto from "crypto";
import TextField from "@/components/molecules/TextField.vue";
import PremiumInformation from "@/components/atoms/PremiumInformation.vue";
import { Plan } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { TextField, PremiumInformation },
  props: {
    novelId: String,
    value: Object,
  },
  created() {
    if (!this.value) {
      return;
    }
    this.name = this.value.name;
    const { detail } = this.value as { detail: any[] };
    if (!detail) {
      return;
    }
    this.detail = this.detail.map((x) => {
      let targetValue = x.value;
      const target = detail.find((y) => y.name === x.name);
      if (target) targetValue = target.value;
      return { ...x, value: targetValue };
    });
    const { additionalColumn } = this.value as { additionalColumn: any[] };
    if (!additionalColumn || additionalColumn.length === 0) {
      return;
    }
    this.additionalColumn = additionalColumn;
  },
  async mounted() {
    const { billingInfo } = await callApi(gql`
      query BillingInfo {
        billingInfo {
          plan
        }
      }
    `);
    this.plan = billingInfo.plan;
  },
  methods: {
    change() {
      const { name, detail, additionalColumn } = this;
      this.$emit("input", {
        name,
        detail: detail.filter(({ value }) => value).map(({ name, value }) => ({ name, value })),
        additionalColumn,
      });
      this.setModified(true);
    },
    add() {
      const { additionalColumn } = this;
      additionalColumn.push({
        name: this.generateUniqueValue(),
        label: "",
        content: "",
      });
      this.change();
    },
    remove(index) {
      const { additionalColumn } = this;
      additionalColumn.splice(index, 1);
      this.change();
    },
    generateUniqueValue() {
      const N = 16;
      return crypto.randomBytes(N).toString("base64").substring(0, N);
    },
  },
  data() {
    return {
      plan: 0,
      name: "",
      detail: [
        {
          name: "alias",
          value: "",
          _label: "別名・あだ名",
          _placeholder: "登場人物のあだ名や別名を入力してください。",
        },
        {
          name: "role",
          value: "",
          _label: "役職",
          _placeholder: "作品内での役職を入力してください。",
        },
        {
          name: "gender",
          value: "",
          _label: "性別",
          _placeholder: "性別を入力してください。",
        },
        {
          name: "age",
          value: "",
          _label: "年齢",
          _placeholder: "年齢を入力してください。",
        },
        {
          name: "birthdate",
          value: "",
          _label: "誕生日",
          _placeholder: "誕生日を入力してください。",
        },
        {
          name: "bloodType",
          value: "",
          _label: "血液型",
          _placeholder: "血液型を入力してください。",
        },
        {
          name: "height",
          value: "",
          _placeholder: "身長を入力してください。",
          _label: "身長",
        },
        {
          name: "weight",
          value: "",
          _placeholder: "体重を入力してください。",
          _label: "体重",
        },
        {
          name: "personality",
          value: "",
          _label: "性格",
          _rows: 4,
          _placeholder: "性格を入力してください。",
        },
        {
          name: "individuality",
          value: "",
          _label: "個性",
          _rows: 4,
          _placeholder: "個性を入力してください。",
        },
        {
          name: "skill",
          value: "",
          _label: "特技",
          _rows: 4,
          _placeholder: "特技を入力してください。",
        },
        {
          name: "ability",
          value: "",
          _label: "能力・スキル",
          _rows: 4,
          _placeholder: "能力・スキルを入力してください。",
        },
        {
          name: "looks",
          value: "",
          _label: "見た目",
          _rows: 4,
          _placeholder: "見た目を入力してください。",
        },
        {
          name: "personalHistory",
          value: "",
          _label: "生い立ち",
          _rows: 4,
          _placeholder: "登場人物の歴史や生い立ちを入力してください。",
        },
        {
          name: "background",
          value: "",
          _label: "背景",
          _rows: 4,
          _placeholder: "作品に登場する背景を入力してください。",
        },
        {
          name: "other",
          value: "",
          _label: "その他",
          _rows: 4,
          _placeholder: "その他のメモを入力してください。",
        },
      ],
      additionalColumn: [
        {
          name: (this as any).generateUniqueValue(),
          label: "",
          content: "",
        },
      ],
    };
  },
});

interface Props {
  novelId: string;
  value: {
    name: string;
    detail: any[];
    additionalColumn: any[];
  };
}

interface Data {
  plan: Plan;
  name: string;
  detail: any[];
  additionalColumn: any[];
}

interface Computed {}

interface Methods {
  change(): void;
  add(): void;
  remove(index: number): void;
  generateUniqueValue(): string;
}

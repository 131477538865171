
import Vue from "vue";
import ListTemplates from "@/components/templates/ListTemplate.vue";
import ManuscriptList from "@/components/organisms/ManuscriptList.vue";
import ManuscriptFolderList from "@/components/organisms/ManuscriptFolderList.vue";
import ManuscriptEditor from "@/components/organisms/ManuscriptEditor.vue";
import PublishSharedLink from "@/components/organisms/PublishSharedLink.vue";
import SlideSharedLinkMenu from "@/components/organisms/SlideSharedLinkMenu.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { Manuscript, ManuscriptFolder, Plan } from "@/lib/models";
import isMobile from "ismobilejs";
import { ThemeClient } from "@/lib/clients";
import { cloneDeep } from "lodash";
import ManuscriptAnalysis from "@/components/organisms/ManuscriptAnalysis.vue";

const themeClient = new ThemeClient();

// NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
// import { Dialog } from "@/lib/utils";
// import PremiumFeatureDialog, { PremiumFeatureDialogProps } from "@/components/ui/PremiumFeatureDialog.vue";
// import { downloadPublicImage } from "@/lib/s3";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ListTemplates,
    ManuscriptEditor,
    ManuscriptList,
    ManuscriptFolderList,
    PublishSharedLink,
    SlideSharedLinkMenu,
    MaskedLoading,
    ManuscriptAnalysis,
  },
  props: {
    novelId: String,
    manuscriptKey: String,
  },
  data() {
    return {
      selected: null,
      isShowFolderList: false,
      isFolderInsertMode: false,
      targetManuscript: null,
      selectedFolderId: "",
      wordLength: 0,
      loading: false,
      aiFeedbackMode: false,
    };
  },
  async created() {
    const { novelId } = this;

    this.loading = true;

    await this.fetchData();
    this.$store.dispatch("manuscriptModule/initialize", {
      novelId,
      callback: () => {
        this.load();
        this.loading = false;
      },
    });
    this.$store.dispatch("sharedLinkModule/initializeSharedLinkList", novelId);
    this.$store.dispatch("sharedLinkModule/initializeSharedLinkListBelongOwner");
    this.$store.dispatch("sharedLinkModule/initializeCommentListBelongOwner", novelId);
    this.$store.dispatch("userModule/initialize");
    this.$store.dispatch("proofreadingModule/initialize");
    this.$store.dispatch("plotModule/initialize", novelId);
  },
  methods: {
    load() {
      /** NOTE: 執筆画面ではブラウザ操作での遷移を禁止する */
      window.history.pushState(null, "", null);
      window.addEventListener("popstate", () => {
        window.history.pushState(null, "", null);
      });

      const { manuscriptKey, manuscriptItems } = this;

      if (!manuscriptItems) {
        this.selected = null;
        return;
      }
      if (manuscriptKey) {
        this.selected = manuscriptItems.find((i) => i.key === manuscriptKey) || null;
        return;
      }
      if (manuscriptItems.length) {
        this.selected = manuscriptItems[0];
      }
    },
    async fetchData() {
      await Promise.all([this.fetchWordLength()]);
    },
    async fetchWordLength() {
      this.wordLength = await this.getWordLength();
    },
    async getWordLength() {
      const { novelId } = this;

      const theme = await themeClient.fetchTheme(novelId);

      if (theme.detail) {
        const result = theme.detail.find((x) => x.name === "word-length");
        if (result) {
          return Number(result.value);
        }
      }
      return 0;
    },
    async selectFolder(manuscriptFolderId) {
      this.selectedFolderId = manuscriptFolderId;
      this.isShowFolderList = false;
    },
    async showFolderList() {
      if (this.isFree) {
        this.showPremiumFeatureDialog();
        return;
      }

      this.isShowFolderList = true;
    },
    setFolder(el) {
      if (this.isFree) {
        this.showPremiumFeatureDialog();
        return;
      }

      this.targetManuscript = el.manuscript;
      this.selectedFolderId = el.manuscriptFolder ? el.manuscriptFolder.manuscriptFolderId : "";
      this.isFolderInsertMode = true;
      this.isShowFolderList = true;
    },
    async finishFolderSelect() {
      await this.selectFolder(this.selectedFolderId);

      this.targetManuscript = null;
      this.isFolderInsertMode = false;
      this.isShowFolderList = false;
    },
    handleSelectItem(key) {
      const list = this.manuscriptItems;
      this.selected = list.find((i) => i.key === key) || null;

      if (this.$refs.manuscriptAnalysis) {
        this.$refs.manuscriptAnalysis.closeMessage();
      }
    },
    async showPremiumFeatureDialog() {
      // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
      // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
      // const data: PremiumFeatureDialogProps = {
      //   title: "フォルダ機能はプレミアム機能です",
      //   text: "Nolaプレミアムにご登録いただくと、お好きな名前で原稿を管理できる『フォルダ管理機能』をご利用いただけます。”第一章”、”第二章”……などの章ごとの管理や、”〇〇編”などキャラクターごとのエピソード管理などができますので、お手すきの際にぜひご活用いただだけたら幸いです。",
      //   image: (await downloadPublicImage("shared/images/gif/manuscript_folder.gif")) as string,
      // };
      // premiumFeatureDialog.show(data);

      this.$router.push({ name: "subscriptionAnnounce", query: { from: "manuscriptFolder" } });
    },
    changeLoading(value) {
      this.loading = value;
    },
    aiFeedback() {
      this.aiFeedbackMode = true;
    },
    notAiFeedback() {
      this.aiFeedbackMode = false;
    },
    closeAiFeedback() {
      this.aiFeedbackMode = false;
      this.$refs.manuscriptList.clearActiveButton();
    },
    handleCommentIconClick() {
      this.$refs.slideMenu.onOpenTagClick();
    },
  },
  computed: {
    manuscripts() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    manuscriptsCount() {
      return this.manuscripts.length;
    },
    manuscriptItems() {
      const { manuscriptFolder } = this;

      if (manuscriptFolder) {
        return this.manuscripts
          .filter((item) => manuscriptFolder.manuscriptKeys.includes(item.key!))
          .sort(
            (x, y) => manuscriptFolder.manuscriptKeys.indexOf(x.key!) - manuscriptFolder.manuscriptKeys.indexOf(y.key!)
          );
      }
      return this.manuscripts;
    },
    isShowPublishSharedLink() {
      return this.$store.getters["sharedLinkModule/isShowPublishSharedLink"];
    },
    plan() {
      return this.$store.getters["userModule/plan"];
    },
    isFree() {
      return this.plan === Plan.free;
    },
    isEnableMobile() {
      return !isMobile().phone && !(isMobile().tablet && window.innerWidth < 2048);
    },
    manuscriptFolder() {
      const { selectedFolderId } = this;
      const manuscriptFolder = this.$store.getters["manuscriptModule/manuscriptFolder"](selectedFolderId);
      return cloneDeep(manuscriptFolder);
    },
    manuscriptFolders() {
      const manuscriptFolders = this.$store.getters["manuscriptModule/manuscriptFolderList"];
      return cloneDeep(manuscriptFolders);
    },
  },
  watch: {
    $route: "load",
  },
});

interface Props {
  novelId: string;
  manuscriptKey: string;
}

interface Data {
  selected: Manuscript | null;
  isShowFolderList: boolean;
  isFolderInsertMode: boolean;
  targetManuscript: Manuscript | null;
  selectedFolderId: string;
  wordLength: number;
  loading: boolean;
  aiFeedbackMode: boolean;
}

interface Methods {
  load(): void;
  fetchData(): Promise<void>;
  fetchWordLength(): Promise<void>;
  getWordLength(): Promise<number>;
  selectFolder(manuscriptFolderId: string): Promise<void>;
  showFolderList(): Promise<void>;
  setFolder(el: { manuscript: Manuscript; manuscriptFolder: ManuscriptFolder }): void;
  finishFolderSelect(): Promise<void>;
  handleSelectItem(key: string): void;
  showPremiumFeatureDialog(): Promise<void>;
  changeLoading(value: boolean): void;
  aiFeedback(): void;
  notAiFeedback(): void;
  closeAiFeedback(): void;
  handleCommentIconClick(): void;
}

interface Computed {
  manuscripts: Manuscript[];
  manuscriptsCount: number;
  manuscriptItems: Manuscript[];
  isShowPublishSharedLink: boolean;
  plan: Plan;
  isFree: boolean;
  isEnableMobile: boolean;
  manuscriptFolder: ManuscriptFolder;
  manuscriptFolders: ManuscriptFolder[];
}

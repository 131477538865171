
import Vue, { PropType } from "vue";
import { SharedLink, User } from "@/lib/models";
import { Comment as CommentDexie } from "@/lib/indexeddb";
import { compareAsc } from "date-fns";
import DotIcon from "@/components/atoms/DotIcon.vue";

export default Vue.extend<{}, Methods, Computed, Props>({
  components: { DotIcon },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  methods: {
    async select(sharedLinkId) {
      this.$store.dispatch("sharedLinkModule/selectSharedLink", sharedLinkId);
    },
  },
  computed: {
    user() {
      return this.$store.getters["userModule/user"] as User;
    },
    sharedLinkList() {
      const sharedLinkList = this.$store.getters["sharedLinkModule/sharedLinkList"] as SharedLink[];
      const enabledList = sharedLinkList
        .filter((sharedLink) => sharedLink.enabled)
        .sort((x, y) => compareAsc(x.createdAt!, y.createdAt!));
      const disabledList = sharedLinkList
        .filter((sharedLink) => !sharedLink.enabled)
        .sort((x, y) => compareAsc(x.createdAt!, y.createdAt!));
      return enabledList.concat(disabledList);
    },
    isIncludesUnreadComment() {
      return (sharedLinkId) => {
        const { novelId, kind } = this;
        const { userId } = this.user as User;
        const unreadCommentList = this.$store.getters["sharedLinkModule/unreadCommentList"](
          userId,
          novelId,
          kind,
          sharedLinkId
        ) as CommentDexie[];
        return !!unreadCommentList.length;
      };
    },
  },
});

interface Props {
  novelId: string;
  kind: string;
}

interface Methods {
  select: (sharedLinkId: string) => void;
}

interface Computed {
  user: User;
  sharedLinkList: SharedLink[];
  isIncludesUnreadComment: (sharedLinkId: string) => boolean;
}

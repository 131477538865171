
import Vue from "vue";

export default Vue.extend({
  props: {
    /** text type */
    type: {
      type: String,
      default: "text",
    },
    rows: {
      type: Number,
      default: 10,
    },
    value: String,
    label: String,
    maxlength: Number,
    readonly: Boolean,
  },
  model: {
    event: "input",
  },
  data() {
    return {
      raw: this.value,
    };
  },
  methods: {
    handleInput(e: any) {
      this.$emit("input", e.target.value);
    },
  },
  watch: {
    value() {
      this.raw = this.value;
    },
  },
});


import Vue from "vue";
import { Novel, NovelFromNolaNovel } from "@/lib/models";
import ImageView from "@/components/atoms/ImageView.vue";
import { VueLoading } from "vue-loading-template";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

interface Data {
  isLoading: boolean;
  isLinking: boolean;
}

interface Methods {
  initialize: () => void;
  getAssociatedId: () => string | undefined;
  getImagePath: (novel: NovelFromNolaNovel) => string | null;
  onClickOutSide: () => void;
  onNegativeClick: () => void;
  onNovelSelect: (novel: NovelFromNolaNovel) => void;
  executeLink: (novel: NovelFromNolaNovel) => void;
  navigateToNolaNovelEditPage: (novel?: NovelFromNolaNovel) => void;
}

interface Computed {
  novel?: Novel;
  novels: NovelFromNolaNovel[];
}

interface Props {
  novelId: string;
  isPost: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { VueLoading, ImageView },

  created() {
    const { initialize } = this;
    initialize();
  },

  data() {
    return {
      isLoading: true,
      isLinking: false,
    };
  },

  methods: {
    async initialize() {
      const { dispatch } = this.$store;
      await dispatch("nolaNovelModule/fetchNovels", {});

      this.isLoading = false;
    },

    getImagePath(novel) {
      const { imagePath } = novel;

      if (!imagePath) {
        return null;
      }

      return `nolaNovel:${novel.imagePath}`;
    },
    getAssociatedId() {
      const { novel } = this;
      if (!novel) {
        return undefined;
      }

      const { associatedData } = novel;

      if (!associatedData) {
        return undefined;
      }

      const { nolaNovel } = associatedData;

      if (!nolaNovel) {
        return undefined;
      }

      const { id } = nolaNovel;
      if (!id) {
        return undefined;
      }

      return id;
    },

    onNegativeClick() {
      this.$close(false);
    },
    onClickOutSide() {
      this.$close(false);
    },
    onNovelSelect(novel) {
      const { navigateToNolaNovelEditPage, isLoading, isPost, executeLink } = this;

      if (isLoading) {
        return;
      }

      if (isPost) {
        navigateToNolaNovelEditPage(novel);
        return;
      }

      executeLink(novel);
    },

    async executeLink(novelFromNolaNovel) {
      const { novelId } = this;
      const { dispatch } = this.$store;
      try {
        this.isLinking = true;
        await dispatch("nolaNovelModule/addNovelLink", {
          novelId,
          nolaNovelId: novelFromNolaNovel.id,
        });
      } catch (e) {
        this.$close(false);
        return;
      }

      this.$close(true);
    },
    async navigateToNolaNovelEditPage(novel) {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const { novelId } = this;

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);

      if (!novel) {
        const postNovelUrl = await service.genPostNovelUrl(novelId);
        window.open(
          createUrlWithUtmParams(postNovelUrl, NolaPageName.Detail, NolaItemId.SelectForNewNovelPostButton),
          process.env.VUE_APP_NOLANOVEL_NAME
        );
        return;
      }

      const updateNovelUrl = await service.genUpdateNovelUrl(novel.id, novelId);
      window.open(
        createUrlWithUtmParams(updateNovelUrl, NolaPageName.Detail, NolaItemId.SelectForOverwriteNovelButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
  },

  computed: {
    novel() {
      const { novelId } = this;
      const { getters } = this.$store;
      return getters["novelModule/novel"](novelId);
    },
    novels() {
      const { getters } = this.$store;
      return getters["nolaNovelModule/novels"];
    },
  },

  props: {
    novelId: String,
    isPost: {
      type: Boolean,
      default: true,
    },
  },
});

export type NovelListDialogProps = Props;

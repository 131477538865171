import { render, staticRenderFns } from "./ColorThemeDialog.vue?vue&type=template&id=65a1aab2&scoped=true"
import script from "./ColorThemeDialog.vue?vue&type=script&lang=ts"
export * from "./ColorThemeDialog.vue?vue&type=script&lang=ts"
import style0 from "./ColorThemeDialog.vue?vue&type=style&index=0&id=65a1aab2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a1aab2",
  null
  
)

export default component.exports
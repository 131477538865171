
import Vue from "vue";
import ListTemplate from "@/components/templates/ListTemplate.vue";
import CharacterList from "@/components/organisms/CharacterList.vue";
import CharacterFolderList from "@/components/organisms/CharacterFolderList.vue";
import CharacterDetail from "@/components/organisms/CharacterDetail.vue";
import SpTab from "@/components/molecules/SpTab.vue";
import BackToList from "@/components/atoms/BackToList.vue";
import { Character, CharacterFolder, Plan } from "@/lib/models";
import { cloneDeep } from "lodash";
// NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
// import { Dialog } from "@/lib/utils";
// import PremiumFeatureDialog, { PremiumFeatureDialogProps } from "@/components/ui/PremiumFeatureDialog.vue";
// import { downloadPublicImage } from "@/lib/s3";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ListTemplate,
    CharacterList,
    CharacterFolderList,
    CharacterDetail,
    BackToList,
    SpTab,
  },
  props: {
    novelId: String,
    characterId: String,
  },
  data() {
    return {
      selectedCharacter: null,
      isShowFolderList: false,
      isFolderInsertMode: false,
      targetCharacter: null,
      selectedFolderId: null,
    };
  },
  async created() {
    const { novelId } = this;
    await this.$store.dispatch("characterModule/initialize", novelId);
    this.load();
    if (!this.characters.length) this.$router.push({ name: "characterCreate", params: { novelId } });
  },
  computed: {
    characters() {
      const { $store, selectedFolderId } = this;

      let characters = $store.getters["characterModule/characterList"] as Character[];

      if (selectedFolderId) {
        characters = $store.getters["characterModule/charactersInFolder"](selectedFolderId) as Character[];
      }

      return cloneDeep(characters);
    },
    characterFolder() {
      const { selectedFolderId } = this;
      const characterFolder = this.$store.getters["characterModule/characterFolder"](selectedFolderId);
      return cloneDeep(characterFolder);
    },
    characterFolders() {
      const characterFolders = this.$store.getters["characterModule/characterFolderList"];
      return cloneDeep(characterFolders);
    },
    charactersCount() {
      const characters = this.$store.getters["characterModule/characterList"] as Character[];
      return characters.length;
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
  },
  methods: {
    async selectFolder(characterFolderId) {
      this.selectedFolderId = characterFolderId;
      this.isShowFolderList = false;
    },
    async showFolderList() {
      if (this.isFree) {
        this.showPremiumFeatureDialog();
        return;
      }

      this.isShowFolderList = true;
    },
    setFolder(el) {
      if (this.isFree) {
        this.showPremiumFeatureDialog();
        return;
      }

      this.targetCharacter = el.character;
      this.selectedFolderId = el.characterFolder ? el.characterFolder.characterFolderId : null;
      this.isFolderInsertMode = true;
      this.isShowFolderList = true;
    },
    async finishFolderSelect() {
      await this.selectFolder(this.selectedFolderId);

      this.targetCharacter = null;
      this.isFolderInsertMode = false;
      this.isShowFolderList = false;
    },
    load() {
      const list = this.characters as Character[];
      if (!list) {
        this.selectedCharacter = null;
        return;
      }
      if (this.characterId) {
        this.selectedCharacter = list.find((c) => c.characterId === this.characterId);
        return;
      }
      if (list.length) {
        const [first] = list;
        this.selectedCharacter = first;
      }
    },
    async showPremiumFeatureDialog() {
      // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
      // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
      // const data: PremiumFeatureDialogProps = {
      //   title: "フォルダ機能はプレミアム機能です",
      //   text: "Nolaプレミアムにご登録いただくと、お好きな名前で資料を管理できる『フォルダ管理機能』をご利用いただけます。”〇〇高校”などの組織ごとの管理や、”味方”、”敵陣”などキャラクターの役割ごとの資料管理ができますので、お手すきの際にぜひご活用いただだけたら幸いです。",
      //   image: (await downloadPublicImage("shared/images/gif/character_folder.gif")) as string,
      // };
      // premiumFeatureDialog.show(data);

      this.$router.push({ name: "subscriptionAnnounce", query: { from: "characterFolder" } });
    },
  },
  watch: {
    $route: "load",
  },
});

interface Props {
  novelId: string;
  characterId: string;
}

interface Data {
  selectedCharacter: Character | null;
  isShowFolderList: boolean;
  isFolderInsertMode: boolean;
  targetCharacter: Character | null;
  selectedFolderId: string | null;
}

interface Computed {
  characters: Character[];
  characterFolder: CharacterFolder;
  characterFolders: CharacterFolder[];
  charactersCount: number;
  plan: Plan;
  isFree: boolean;
}

interface Methods {
  selectFolder(characterFolderId: string): Promise<void>;
  showFolderList(): Promise<void>;
  setFolder(el): void;
  finishFolderSelect(): Promise<void>;
  load(): void;
  showPremiumFeatureDialog(): Promise<void>;
}

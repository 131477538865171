
import Vue, { PropType } from "vue";
import SelectBoxItem, { SelectBoxItemProps } from "@/components/atoms/SelectBoxItem.vue";
import ChevronDownIcon from "icons/ChevronDown.vue";

const ClickOutside = require("vue-click-outside");

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: { SelectBoxItem, ChevronDownIcon },
  model: {
    prop: "selected",
    event: "select",
  },
  props: {
    selected: Object as PropType<SelectBoxItemProps>,
    initialSelected: Object as PropType<object>,
    options: Array as PropType<Option[]>,
    placeholder: String as PropType<string>,
    placeholderImage: String as PropType<string>,
    onClickHandler: Function as PropType<() => void>,
    text: String as PropType<string>,
    allowUnselected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    error: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (this.initialSelected) {
      this.$emit("select", this.initialSelected);
    }
  },
  methods: {
    onClickSelectBox() {
      if (this.readonly) return;

      if (this.onClickHandler) {
        this.onClickHandler();
      } else {
        this.isOpen = !this.isOpen;
      }
    },
    onClickItem(item) {
      this.$emit("select", item);
    },
    onClickOutside() {
      this.isOpen = false;
    },
  },
});

interface Props {
  selected: SelectBoxItemProps;
  initialSelected?: object;
  options: Option[];
  placeholder: string;
  placeholderImage?: string;
  onClickHandler?: () => void;
  text?: string;
  allowUnselected: boolean;
  readonly: boolean;
  error: boolean;
}

interface Data {
  isOpen: boolean;
}

interface Computed {}

interface Methods {
  onClickSelectBox(): void;
  onClickItem(item?: Option): void;
  onClickOutside(): void;
}

export type Option = {
  id: string | number;
  name: string;
  image?: string;
};

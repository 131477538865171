import { AuthService } from "@/lib/auth";
import { stringify } from "querystring";

export class NolaNovelUrlGenerator {
  constructor(private readonly baseUrl: string, private readonly authService: AuthService = new AuthService()) {}

  async genAboutUrl(): Promise<string> {
    const url = new URL("/about", this.baseUrl);
    const tokenQuery = await this.getTokenQuery();
    const queryString = stringify({ ...tokenQuery });
    url.search = queryString;

    return url.toString();
  }

  async genPostNovelUrl(novelId: string): Promise<string> {
    const url = new URL("/novel/create", this.baseUrl);
    const tokenQuery = await this.getTokenQuery();
    const queryString = stringify({ ...tokenQuery, novelid: novelId });
    url.search = queryString;

    return url.toString();
  }

  async genUpdateNovelUrl(novelIdFromNolaNovel: string, novelId: string): Promise<string> {
    const url = new URL(`/novel/edit/${novelIdFromNolaNovel}`, this.baseUrl);
    const tokenQuery = await this.getTokenQuery();
    const queryString = stringify({ ...tokenQuery, novelid: novelId });
    url.search = queryString;

    return url.toString();
  }

  async genPostEpisodeUrl(novelIdFromNolaNovel: string, novelId?: string, manuscriptKey?: string): Promise<string> {
    const url = new URL(`/episode/create/${novelIdFromNolaNovel}`, this.baseUrl);
    const tokenQuery = await this.getTokenQuery();
    const queryString = stringify({
      ...tokenQuery,
      ...(novelId && manuscriptKey ? { novelid: novelId, episodeid: manuscriptKey } : {}),
    });
    url.search = queryString;

    return url.toString();
  }

  async genUpdateEpisodeUrl(episodeIdFromNolaNovel: string, novelId: string, manuscriptKey: string): Promise<string> {
    const url = new URL(`/episode/edit/${episodeIdFromNolaNovel}`, this.baseUrl);
    const tokenQuery = await this.getTokenQuery();
    const queryString = stringify({ ...tokenQuery, novelid: novelId, episodeid: manuscriptKey });
    url.search = queryString;

    return url.toString();
  }

  async genMyPageNovelUrl(novelId: string): Promise<string> {
    const url = new URL(`/mypage/novel/${novelId}`, this.baseUrl);
    const tokenQuery = await this.getTokenQuery();
    const queryString = stringify({ ...tokenQuery });
    url.search = queryString;

    return url.toString();
  }

  private async getTokenQuery() {
    return {
      token: await this.authService.getJwtToken(),
      userId: await this.authService.getUserSub(),
    };
  }
}

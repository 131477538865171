
import Vue from "vue";
import WorldViewForm from "@/components/organisms/WorldViewForm.vue";
import ImageSelector from "@/components/molecules/ImageSelector.vue";
import { submitImage } from "@/lib/image-save";
import { CreateWorldView, WorldView } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { WorldViewForm, ImageSelector },
  props: {
    novelId: String,
    worldViewFolderId: String,
  },
  data() {
    return {
      model: {
        detail: [],
        additionalColumn: [],
      },
      image: "",
      saving: false,
    };
  },
  methods: {
    change() {
      this.setModified(true);
    },
    async submit() {
      if (this.saving) {
        return;
      }
      try {
        this.saving = true;

        const { novelId, worldViewFolderId, image } = this;
        const { additionalColumn } = this.model;
        this.model.additionalColumn = additionalColumn.filter((item) => item.label || item.content);

        const createWorldViewInput: CreateWorldView = {
          novelId,
          worldViewFolderId,
          image,
          ...this.model,
        };
        const worldView: WorldView = await this.$store.dispatch(
          "worldViewModule/createWorldView",
          createWorldViewInput
        );

        const { worldViewId } = worldView;

        await submitImage(`novels/${novelId}/worldviews/${worldViewId}.jpg`);

        this.$ga.event("novel", "create-worldview", "世界観新規作成", 0);
        this.setModified(false);
        this.$router.push({
          name: "worldViews",
          params: { novelId, worldViewId },
        });
      } finally {
        this.saving = false;

        this.$notify({
          title: "保存しました",
          type: "success",
        });
      }
    },
  },
});

interface Props {
  novelId: string;
  worldViewFolderId: string;
}

interface Data {
  model: {
    detail: any[];
    additionalColumn: any[];
  };
  image: string;
  saving: boolean;
}

interface Computed {}

interface Methods {
  change(): void;
  submit(): Promise<void>;
}

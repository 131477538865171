
import Vue from "vue";
import ClickOutside from "vue-click-outside";

export default Vue.extend<Data, Methods, {}, Props>({
  directives: {
    ClickOutside,
  },
  props: {
    title: String,
    content: String,
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    styleObject: Object,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onClickMenu() {
      this.isOpen = !this.isOpen;
    },
    onClickItem(item) {
      item.callback();
      this.onClickMenu();
    },
    onClickOutside() {
      this.isOpen = false;
    },
  },
});

export interface Item {
  icon?: string;
  name: string;
  value?: any;
  callback(): void;
}

interface Props {
  title: string;
  content: string;
  items: Item[];
  styleObject: object;
}

interface Data {
  isOpen: boolean;
}

interface Methods {
  onClickMenu(): void;
  onClickItem(item: Item): void;
  onClickOutside(): void;
}

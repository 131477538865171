
import Vue from "vue";
import HamburgerMenu from "@/components/molecules/HamburgerMenu.vue";
import isMobile from "ismobilejs";
import { Item } from "@/components/molecules/DropupMenu.vue";
import ExportManuscriptDialog, { ExportManuscriptDialogProps } from "@/components/ui/ExportManuscriptDialog.vue";
import { Dialog } from "@/lib/utils";
import ManuscriptSettingsDialog, { ManuscriptSettingsDialogProps } from "@/components/ui/ManuscriptSettingsDialog.vue";
import ColorThemeDialog from "@/components/ui/ColorThemeDialog.vue";
import ProofreadingSettingsDialog from "@/components/ui/ProofreadingSettingsDialog.vue";
import VersionsDialog, { VersionsDialogProps } from "@/components/ui/VersionsDialog.vue";
import { Plan, User } from "@/lib/models";
import LinkIcon from "@/components/atoms/LinkIcon.vue";
import HistoryIcon from "icons/History.vue";
import SearchIcon from "icons/Magnify.vue";
import SearchReplaceDialog from "@/components/ui/SearchReplaceDialog.vue";
import CommentIcon from "@/components/atoms/CommentIcon.vue";
import { Comment as CommentDexie } from "@/lib/indexeddb";

export interface IEditorEmitArgs {
  value: string;
  isComposing: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    HamburgerMenu,
    LinkIcon,
    HistoryIcon,
    SearchReplaceDialog,
    SearchIcon,
    CommentIcon,
  },
  props: {
    title: String,
    novelId: String,
    manuscriptKey: String,
    content: String,
    changed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const { showManuscriptSettings, showColorTheme, showProofreadingSettings, showExportManuscript } = this as any;

    return {
      hamburgerMenuItems: [
        {
          name: "原稿のエクスポート",
          icon: "tray-arrow-down",
          callback() {
            showExportManuscript();
          },
        },
        {
          name: "原稿用紙の設定",
          icon: "note-text-outline",
          callback() {
            showManuscriptSettings();
          },
        },
        {
          name: "カラーテーマ選択",
          icon: "palette",
          callback() {
            showColorTheme();
          },
        },
        {
          name: "自動校正機能の設定",
          icon: "checkbox-marked-outline",
          callback() {
            showProofreadingSettings();
          },
        },
      ],
      defaultColor: "#a0a0a0",
      activeColor: "#5698e1",
      isVersionsDialogOpen: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["userModule/user"];
    },
    plan() {
      return this.$store.getters["userModule/plan"];
    },
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
    isMobileDevice() {
      return isMobile().phone || (isMobile().tablet && window.innerWidth < 1024);
    },
    autoSave() {
      return this.$store.getters["manuscriptModule/autoSave"];
    },
    autoIndent() {
      return this.$store.getters["manuscriptModule/autoIndent"];
    },
    hamburgerMenuTogglableItems() {
      const { autoSave, toggleAutoSave, autoIndent, toggleAutoIndent } = this;

      const hamburgerMenuTogglableItems: Item[] = [
        {
          name: "自動字下げ",
          icon: "format-indent-increase",
          value: autoIndent,
          callback() {
            toggleAutoIndent();
          },
        },
        {
          name: "自動保存",
          icon: "content-save-outline",
          value: autoSave,
          callback() {
            toggleAutoSave();
          },
        },
      ];

      return hamburgerMenuTogglableItems;
    },
    isNotMultiEditorPath() {
      return !window.location.pathname.includes("multiEditor");
    },
    isShowPublishSharedLink() {
      return this.$store.getters["sharedLinkModule/isShowPublishSharedLink"];
    },
    enabledSearchReplace() {
      return this.$store.getters["manuscriptModule/enabledSearchReplace"];
    },
    hasUnreadComment() {
      const { novelId } = this;
      const { userId } = this.user as User;
      const unreadCommentList = this.$store.getters["sharedLinkModule/unreadCommentList"](
        userId,
        novelId,
        "manuscript"
      ) as CommentDexie[];
      return !!unreadCommentList.length;
    },
  },
  methods: {
    onChangeTitle(e) {
      if (e.target instanceof HTMLInputElement) {
        const emitArgs: IEditorEmitArgs = { value: e.target.value, isComposing: e.isComposing };
        this.$emit("changeTitle", emitArgs);
      }
    },
    compositionStart() {
      this.$emit("compositionStart");
    },
    compositionEnd() {
      this.$emit("compositionEnd");
    },
    showExportManuscript() {
      const { novelId } = this;
      const exportManuscriptDialog = new Dialog(ExportManuscriptDialog);
      const data: ExportManuscriptDialogProps = {
        novelId,
      };
      exportManuscriptDialog.show(data);
    },
    async showVersions() {
      const { novelId, manuscriptKey, content } = this;
      const versionsDialog = new Dialog(VersionsDialog);
      const data: VersionsDialogProps = { novelId, manuscriptKey, content };
      this.isVersionsDialogOpen = true;
      await versionsDialog.show(data);
      this.isVersionsDialogOpen = false;
    },
    showManuscriptSettings() {
      const { novelId, plan } = this;
      const manuscriptSettingsDialog = new Dialog(ManuscriptSettingsDialog);
      const data: ManuscriptSettingsDialogProps = {
        novelId,
        isChangeable: Boolean(plan),
        positive: "保存する",
        positiveCallback: async () => {},
      };
      manuscriptSettingsDialog.show(data);
    },
    showColorTheme() {
      const colorThemeDialog = new Dialog(ColorThemeDialog);
      colorThemeDialog.show();
    },
    showProofreadingSettings() {
      const proofreadingDialog = new Dialog(ProofreadingSettingsDialog);
      proofreadingDialog.show();
    },
    toggleAutoSave() {
      this.$store.dispatch("manuscriptModule/toggleAutoSave");
    },
    toggleAutoIndent() {
      this.$store.dispatch("manuscriptModule/toggleAutoIndent");
    },
    showPublishSharedLink() {
      this.$store.dispatch("sharedLinkModule/showPublishSharedLink");
    },
    toggleEnabledSearchReplace() {
      const { $store, enabledSearchReplace } = this;
      $store.dispatch("manuscriptModule/toggleEnabledSearchReplace");

      if (!enabledSearchReplace) {
        const selectedText = window.getSelection()!.toString();
        if (selectedText) $store.dispatch("manuscriptModule/updateSearchText", selectedText);
      }
    },
    post() {
      this.$emit("post");
    },
    save() {
      this.$emit("save");
    },
    onClickCommentIcon() {
      this.$emit("commentIconClicked");
    },
  },
});

interface Props {
  title: string;
  novelId: string;
  manuscriptKey: string;
  content: string;
  changed: boolean;
}

interface Data {
  hamburgerMenuItems: Item[];
  defaultColor: string;
  activeColor: string;
  isVersionsDialogOpen: boolean;
}

interface Methods {
  onChangeTitle(e: InputEvent): void;
  compositionStart(): void;
  compositionEnd(): void;
  showExportManuscript(): void;
  showVersions(): Promise<void>;
  showManuscriptSettings(): void;
  showColorTheme(): void;
  showProofreadingSettings(): void;
  toggleAutoSave(): void;
  toggleAutoIndent(): void;
  showPublishSharedLink(): void;
  toggleEnabledSearchReplace(): void;
  post(): void;
  save(): void;
  onClickCommentIcon(): void;
}

interface Computed {
  user: User;
  plan: Plan;
  theme: string;
  isMobileDevice: boolean;
  autoSave: boolean;
  autoIndent: boolean;
  hamburgerMenuTogglableItems: Item[];
  isNotMultiEditorPath: boolean;
  isShowPublishSharedLink: boolean;
  enabledSearchReplace: boolean;
  hasUnreadComment: boolean;
}

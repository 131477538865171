
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";

interface Props {
  title: string;
  content: string;
  close?: string;
  isError?: boolean;
  width?: number;
  actionHandler?: () => void;
}

interface Data {}

interface Computed {}

interface Methods {
  onClickOutSide: () => void;
  onClickClose: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog },

  props: {
    title: String,
    content: String,
    close: {
      type: String,
      default: "閉じる",
      required: false,
    },
    isError: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
    actionHandler: {
      type: Function,
      required: false,
    },
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onClickClose() {
      if (this.actionHandler) this.actionHandler();
      this.$close(false);
    },
  },
});

export type SimpleDialogProps = Props;


import Vue from "vue";
import { EpisodeFromNolaNovel, Manuscript, Novel, NovelFromNolaNovel } from "@/lib/models";
import { VueLoading } from "vue-loading-template";
import ImageView from "@/components/atoms/ImageView.vue";
import PopupMenu from "@/components/molecules/PopupMenu.vue";
import ChevronLeftIcon from "icons/ChevronLeft.vue";
import SortVariantIcon from "icons/SortVariant.vue";
import { isAfter, isBefore } from "date-fns";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

const ClickOutside = require("vue-click-outside");

// ソートの種類
const SortType = {
  createdAsc: "作成日(昇順)",
  createdDesc: "作成日(降順)",
  updatedAsc: "更新日(昇順)",
  updatedDesc: "更新日(降順)",
  titleAsc: "タイトル(昇順)",
  titleDesc: "タイトル(降順)",
} as const;

// enumは使わない方がいいみたいだからunion型にしてみた
// eslint-disable-next-line no-redeclare
type SortType = typeof SortType[keyof typeof SortType];

interface Data {
  isLoading: boolean;
  isLinking: boolean;
  linkedNovel?: NovelFromNolaNovel;
  currentSortType?: SortType;
  showSortPopupMenu: boolean;
}

interface Methods {
  initialize: () => void;
  getAssociatedId: () => string | undefined;
  getImagePath: (novel: NovelFromNolaNovel) => string | null;
  getSortedEpisodes: (episodes: EpisodeFromNolaNovel[]) => EpisodeFromNolaNovel[];
  setDefaultLinkedNovel: () => NovelFromNolaNovel | undefined;
  onClickOutSide: () => void;
  onNegativeClick: () => void;
  onNovelSelect: (novel: NovelFromNolaNovel) => void;
  onEpisodeSelect: (episode: EpisodeFromNolaNovel) => void;
  onDetailNavigateClick: () => void;
  onSelectedItem: (item: SortType) => void;
  executeLink: (episode: EpisodeFromNolaNovel) => void;
  changeShowPopupMenu: () => void;
  closePopupMenu: () => void;
  navigateToNolaNovelEditPage: (episode?: EpisodeFromNolaNovel) => void;
  navigateToNovelDetailPage: () => void;
}

interface Computed {
  novel?: Novel;
  manuscript?: Manuscript;
  novels: NovelFromNolaNovel[];
  episodes: EpisodeFromNolaNovel[];
  sortTypes: string[];
}

interface Props {
  novelId: string;
  manuscriptId: string;
  isPost: boolean;
  novelIdFromNolaNovel?: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: { VueLoading, ImageView, ChevronLeftIcon, PopupMenu, SortVariantIcon },

  created() {
    const { initialize } = this;
    initialize();
  },

  data() {
    return {
      isLoading: true,
      isLinking: false,
      linkedNovel: undefined,
      currentSortType: undefined,
      showSortPopupMenu: false,
    };
  },

  methods: {
    // initialize

    async initialize() {
      const { setDefaultLinkedNovel } = this;
      const result = setDefaultLinkedNovel();

      const { dispatch } = this.$store;
      await dispatch("nolaNovelModule/fetchNovels", {});

      if (!result) {
        setDefaultLinkedNovel();
      }

      this.isLoading = false;
    },

    // handle data

    getImagePath(novel) {
      const { imagePath } = novel;

      if (!imagePath) {
        return null;
      }

      return `nolaNovel:${novel.imagePath}`;
    },
    getAssociatedId() {
      const { novel } = this;
      if (!novel) {
        return undefined;
      }

      const { associatedData } = novel;

      if (!associatedData) {
        return undefined;
      }

      const { nolaNovel } = associatedData;

      if (!nolaNovel) {
        return undefined;
      }

      const { id } = nolaNovel;
      if (!id) {
        return undefined;
      }

      return id;
    },
    getSortedEpisodes(episodes) {
      return episodes.sort((a, b) => {
        const aCreatedDate = new Date(a.createdAt);
        const bCreatedDate = new Date(b.createdAt);
        const aUpdatedDate = new Date(a.updatedAt);
        const bUpdatedDate = new Date(b.updatedAt);

        switch (this.currentSortType) {
          case SortType.createdAsc:
            return isBefore(aCreatedDate, bCreatedDate) ? -1 : 1;
          case SortType.createdDesc:
            return isAfter(aCreatedDate, bCreatedDate) ? -1 : 1;
          case SortType.updatedAsc:
            return isBefore(aUpdatedDate, bUpdatedDate) ? -1 : 1;
          case SortType.updatedDesc:
            return isAfter(aUpdatedDate, bUpdatedDate) ? -1 : 1;
          case SortType.titleAsc:
            if (a.title && b.title) {
              return a.title.localeCompare(b.title);
            }
            if (a.title) {
              return a.title.localeCompare("");
            }
            if (b.title) {
              return "".localeCompare(b.title);
            }
            return 0;
          case SortType.titleDesc:
            if (a.title && b.title) {
              return b.title.localeCompare(a.title);
            }
            if (b.title) {
              return b.title.localeCompare("");
            }
            if (a.title) {
              return "".localeCompare(a.title);
            }
            return 0;
          default:
            return isAfter(aUpdatedDate, bUpdatedDate) ? -1 : 1;
        }
      });
    },
    setDefaultLinkedNovel() {
      const { novelIdFromNolaNovel } = this;
      if (novelIdFromNolaNovel) {
        const { getters } = this.$store;
        const novel = getters["nolaNovelModule/novel"](novelIdFromNolaNovel) as NovelFromNolaNovel | undefined;
        this.linkedNovel = novel;
        return novel;
      }

      return undefined;
    },

    // event handle

    onNegativeClick() {
      this.$close(false);
    },
    onClickOutSide() {
      this.$close(false);
    },
    onNovelSelect(novel) {
      const { isLoading } = this;

      if (isLoading) {
        return;
      }

      this.linkedNovel = novel;
    },
    onEpisodeSelect(episode) {
      const { isLoading, isPost, executeLink, navigateToNolaNovelEditPage } = this;

      if (isLoading) {
        return;
      }

      if (isPost) {
        navigateToNolaNovelEditPage(episode);
        return;
      }

      executeLink(episode);
    },
    onSelectedItem(item: SortType) {
      this.currentSortType = item;
      this.closePopupMenu();
    },
    onDetailNavigateClick() {
      this.$close(false);
      const { navigateToNovelDetailPage } = this;
      navigateToNovelDetailPage();
    },

    // execute

    async executeLink(episodeFromNolaNovel) {
      const { novelId, manuscriptId } = this;
      const { dispatch } = this.$store;
      try {
        this.isLinking = true;
        await dispatch("nolaNovelModule/addEpisodeLink", {
          novelId,
          manuscriptId,
          episode: episodeFromNolaNovel,
        });
      } catch (e) {
        this.$close(false);
        return;
      }

      this.$close(true);
    },
    changeShowPopupMenu() {
      this.showSortPopupMenu = !this.showSortPopupMenu;
    },
    closePopupMenu() {
      this.showSortPopupMenu = false;
    },
    async navigateToNolaNovelEditPage(episode) {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }
      const { linkedNovel, novelId, manuscriptId } = this;

      if (!linkedNovel) {
        throw new Error("エピソードに紐づく作品が選択できていません。");
      }

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);

      if (episode) {
        const updateEpisodeUrl = await service.genUpdateEpisodeUrl(episode.id, novelId, manuscriptId);
        window.open(
          createUrlWithUtmParams(updateEpisodeUrl, NolaPageName.Editor, NolaItemId.SelectForOverwriteEpisodeButton),
          process.env.VUE_APP_NOLANOVEL_NAME
        );
        return;
      }

      const postEpisodeUrl = await service.genPostEpisodeUrl(linkedNovel.id, novelId, manuscriptId);
      window.open(
        createUrlWithUtmParams(postEpisodeUrl, NolaPageName.Editor, NolaItemId.SelectForNewEpisodePostButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
    navigateToNovelDetailPage() {
      const { $router, novelId } = this;
      $router.push({ name: "detail", params: { novelId } });
    },
  },

  computed: {
    novel() {
      const { novelId } = this;
      const { getters } = this.$store;
      return getters["novelModule/novel"](novelId);
    },
    manuscript() {
      const { manuscriptId } = this;
      const { getters } = this.$store;
      return getters["manuscriptModule/manuscript"](manuscriptId);
    },
    novels() {
      const { getters } = this.$store;
      return getters["nolaNovelModule/novels"];
    },
    episodes() {
      const { linkedNovel } = this;
      if (!linkedNovel) {
        return [];
      }

      const { getSortedEpisodes, currentSortType } = this;
      const { getters } = this.$store;
      const episodes = getters["nolaNovelModule/episodes"](linkedNovel.id);

      if (currentSortType) {
        return getSortedEpisodes(episodes);
      }

      return episodes;
    },
    sortTypes() {
      return Object.values(SortType);
    },
  },

  props: {
    novelId: String,
    manuscriptId: String,
    novelIdFromNolaNovel: String,
    isPost: {
      type: Boolean,
      default: false,
    },
  },
});

export type EpisodeListDialogProps = Props;

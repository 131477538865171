
import Vue from "vue";
import { showOkCancelDialog } from "@/lib/dialog";
import FolderOutlineIcon from "icons/FolderOutline.vue";
import EqualIcon from "icons/Equal.vue";
import DotsHorizontalIcon from "icons/DotsHorizontal.vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";
import ClickOutside from "vue-click-outside";

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: {
    FolderOutlineIcon,
    EqualIcon,
    DotsHorizontalIcon,
    CheckboxIcon,
  },
  props: {
    label: String,
    count: Number,
    onSelectCallback: Function,
    isSorting: Boolean,
    isSelecting: Boolean,
    item: Object,
    onCheckCallback: Function,
    isChecked: Boolean,
    onRenameCallback: Function,
    onDeleteCallback: Function,
    disabledMenu: Boolean,
  },
  data() {
    return {
      iconColor: "#787878",
      checkBoxColor: "#707070",
      isShowMenu: false,
    };
  },
  methods: {
    async click() {
      if (this.isSorting) {
        return;
      }

      if (this.isSelecting) {
        this.onCheckCallback();
        return;
      }

      if (this.isModified()) {
        const yes = await showOkCancelDialog({
          title: "確認",
          content: "保存されていない変更があります。<br />変更を破棄しますか？",
          okButton: "破棄する",
          cancelButton: "キャンセル",
        });
        if (!yes) {
          return;
        }
      }

      this.setModified(false);
      this.onSelectCallback();
    },
    rename() {
      this.onRenameCallback();
    },
    deleteItem() {
      this.onDeleteCallback();
    },
    onClickMenu() {
      this.isShowMenu = true;
    },
    closeMenu() {
      this.isShowMenu = false;
    },
  },
});

interface Props {
  label: string;
  count: number;
  onSelectCallback: void;
  isSorting: boolean;
  isSelecting: boolean;
  item: object;
  onCheckCallback: void;
  isChecked: boolean;
  onRenameCallback: void;
  onDeleteCallback: void;
  disabledMenu: boolean;
}

interface Data {
  iconColor: string;
  checkBoxColor: string;
  isShowMenu: boolean;
}

interface Computed {}

interface Methods {
  click(): Promise<void>;
  rename(): void;
  deleteItem(): void;
  onClickMenu(): void;
  closeMenu(): void;
}


import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    novelTitle: String,
  },
  methods: {
    onIntegrateButtonClick() {
      this.$router.push({ name: "agent" });

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
  },
});

interface Data {}

interface Methods {
  onIntegrateButtonClick: () => void;
  onClickOutSide: () => void;
}

interface Props {
  novelTitle: string;
}

interface Computed {}

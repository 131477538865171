
import Vue, { PropType } from "vue";
import ListItem from "@/components/molecules/ListItem.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import ButtonSolid from "@/components/atoms/ButtonSolid.vue";
import { getCharacterKey } from "@/lib/s3";
import { Character, CharacterFolder, DeleteCharacter } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
import { CharacterClient } from "@/lib/clients";

const characterClient = new CharacterClient();

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ListItem, SortableList, ButtonSolid },
  props: {
    novelId: String,
    characters: Array as PropType<Character[]>,
    selectedId: String,
    folder: Object as PropType<CharacterFolder>,
    allFolders: Array as PropType<CharacterFolder[]>,
    needsDoCustomClickFunction: {
      type: Boolean,
      default: false,
    },
    onClickItem: {
      type: Function,
      default: () => false,
      required: false,
    }, // アイテムクリック時の処理を外部から指定したい場合に渡す
  },
  data() {
    return {
      isEditing: false,
      items: this.characters,
    };
  },
  watch: {
    characters() {
      this.items = this.characters;
    },
  },
  computed: {
    foldersBelongCharacter() {
      return (characterId) => this.$store.getters["characterModule/foldersBelongCharacter"](characterId);
    },
    folderName() {
      return (item) => {
        const folders = this.foldersBelongCharacter(item.characterId) as CharacterFolder[];
        return folders.reduce((acc, cur) => (acc ? `${acc} / ${cur.name}` : `${cur.name}`), "");
      };
    },
  },
  methods: {
    async saveOrder() {
      const { novelId, items } = this;
      const order = (items as Character[]).map((item) => item.characterId);

      if (this.folder) {
        const folders = this.allFolders as CharacterFolder[];
        const characterFolders = folders.map((folder) => {
          const item = folder;
          if (item.characterFolderId === this.folder.characterFolderId) {
            item.characterKeys = order;
          }
          return item;
        });

        await this.$store.dispatch("characterModule/updateFolder", { novelId, characterFolders });
      } else {
        await this.$store.dispatch("characterModule/updateOrder", { novelId, order });
        await this.$store.dispatch("characterModule/initialize", novelId);
      }

      this.isEditing = false;
    },
    getCharacterKey(character) {
      return getCharacterKey(this.novelId, character);
    },
    backToFolderList() {
      this.$emit("backToFolderList");
    },
    setFolder(el) {
      this.$emit("clickSetFolder", {
        character: el.item,
        characterFolder: this.folder,
      });
    },
    async deleteCharacter(characterId) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "削除の最終確認",
        content: "本当に登場人物を削除しますか？<br />※ 一度削除した登場人物は元に戻せません。",
        change: "削除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }
      const { novelId } = this;
      await this.$store.dispatch("characterModule/deleteCharacter", { novelId, characterId } as DeleteCharacter);

      this.$notify({
        title: "削除しました",
        type: "error",
      });

      this.updateCharacterFolders();

      this.setModified(false);

      this.$router.push({ name: "characters" });
    },
    copyCharacter(characterId) {
      this.$router.push({ name: "characterCreate", params: { characterIdToCopy: characterId } });
    },
    async updateCharacterFolders() {
      const characterFolders = await characterClient.fetchAllCharacterFolder(this.novelId);
      await this.$store.dispatch("characterModule/updateFolder", { novelId: this.novelId, characterFolders });
    },
  },
});

interface Props {
  novelId: string;
  characters: Character[];
  selectedId: string;
  folder: CharacterFolder;
  allFolders: CharacterFolder[];
  needsDoCustomClickFunction: boolean;
  onClickItem?: boolean;
}

interface Data {
  isEditing: boolean;
  items: Character[];
}

interface Computed {
  foldersBelongCharacter(characterId: string): CharacterFolder[];
  folderName(item: Character): string;
}

interface Methods {
  saveOrder(): Promise<void>;
  getCharacterKey(character: Character): string | null;
  backToFolderList(): void;
  setFolder(el): void;
  deleteCharacter(characterId: string): Promise<void>;
  copyCharacter(characterId: string): void;
  updateCharacterFolders(): void;
}

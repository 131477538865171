
import Vue from "vue";
import ProofreadingEditor from "@/components/molecules/ProofreadingEditor.vue";

const Platform = require("platform");

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ProofreadingEditor,
  },
  props: {
    title: String,
    content: String,
  },
  data() {
    return {
      textareaHeight: 0,
      scrollTop: 0,
      resizeObserver: null,
      lineBreakPosition: false,
      compositionEndedFlag: false,
    };
  },
  mounted() {
    /** ResizeObserverを定義 */
    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$refs.textarea as HTMLTextAreaElement);
  },
  destroyed() {
    this.resizeObserver!.disconnect();
  },
  watch: {
    scrollRatio() {
      (this.$refs.textarea as HTMLTextAreaElement).scroll(0, this.textareaHeight * this.scrollRatio);
    },
    content() {
      this.onResize();
    },
  },
  computed: {
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
    scrollRatio() {
      return this.$store.getters["manuscriptModule/scrollRatio"];
    },
    isEnableAutoIndent() {
      return this.$store.getters["manuscriptModule/autoIndent"];
    },
    isFirstTime() {
      return this.$store.getters["manuscriptModule/isFirstTimeAutoIndent"];
    },
    isMultiEditorPath() {
      return window.location.pathname.includes("multiEditor");
    },
  },
  methods: {
    onChangeTitle(e) {
      if (e.target instanceof HTMLInputElement) {
        const emitArgs: IEditorEmitArgs = { value: e.target.value, isComposing: e.isComposing };
        this.$emit("changeTitle", emitArgs);
      }
    },
    onFixed(text) {
      this.$emit("changeContent", { value: text, isComposing: false });
    },
    onChangeContent(e) {
      if (e.target instanceof HTMLTextAreaElement) {
        const emitArgs: IEditorEmitArgs = { value: e.target.value, isComposing: e.isComposing };
        this.$emit("changeContent", emitArgs);
      }
    },
    compositionStart() {
      this.$emit("compositionStart");
    },
    compositionEnd() {
      this.compositionEndedFlag = true;
      this.$emit("compositionEnd");
    },
    handleScroll(e, position) {
      this.scrollTop = position.scrollTop;
      const scrollRatio = position.scrollTop / this.textareaHeight;
      this.$store.dispatch("manuscriptModule/updateScrollRatio", scrollRatio);
    },
    onResize() {
      const { textarea } = this.$refs as { textarea: HTMLTextAreaElement };
      this.textareaHeight = textarea.scrollHeight - textarea.clientHeight;
    },
    compositionUpdate(e) {
      if (!this.isEnableAutoIndent) {
        return;
      }
      const textarea = e.target as HTMLTextAreaElement;
      const caredPosition = textarea.selectionStart;
      // 字下げの対象がとなる文字候補
      const OPEN_BRACKETS = ["（", "＜", "「"];

      if (this.lineBreakPosition === caredPosition && e.data && OPEN_BRACKETS.includes(e.data.charAt(0))) {
        // 改行直後の開きカッコは字下げしないように、挿入した全角空白を削除する。
        document.execCommand("delete", false, undefined);

        if (Platform.name === "Chrome" || Platform.name === "Microsoft Edge") {
          // Chrome系ブラウザでは文末以外では、全角空白を削除した分カーソルの位置を移動しないと入力したい場所からずれる。
          // this.$nextTick を使うとカーソル位置が正常に移動しないため setTimeout を使用する。
          setTimeout(() => {
            const caretPosition = textarea.selectionStart;
            if (caretPosition !== textarea.value.length) {
              // eslint-disable-next-line no-multi-assign
              textarea.selectionStart = textarea.selectionEnd = caretPosition - 1;
            }
          }, 0);
        }
      }
    },
    keyDown(e) {
      if (!this.isEnableAutoIndent) {
        return;
      }

      const textarea = e.target as HTMLTextAreaElement;

      if (e.code === "Enter") {
        // Safari では keyDown の前に compositionEnd が呼ばれ
        // IME入力確定時の Enter イベントで isComposing が false になるための対策
        const skipLineBreak = Platform.name === "Safari" && this.compositionEndedFlag;

        if (!e.isComposing && !skipLineBreak) {
          e.preventDefault();
          const caredPosition = textarea.selectionStart;
          if (this.lineBreakPosition === caredPosition) {
            // 連続で改行が入力された時は、空白行から挿入した全角空白を削除する。
            document.execCommand("delete", false, undefined);
          }

          // eslint-disable-next-line no-irregular-whitespace
          document.execCommand("insertText", false, `\n　`);

          // 初めての字下げ処理が走ったタイミングでユーザーにメニューからの切り替え方法を通知する。
          if (this.isFirstTime) {
            this.$store.dispatch("manuscriptModule/showAutoIndentMessage");
          }

          this.$nextTick(() => {
            this.lineBreakPosition = textarea.selectionStart;

            // e.preventDefault() すると自動的にカーソル表示位置にスクロールしない問題に対応するためのハック
            // 参考: https://stackoverflow.com/questions/29899364
            textarea.blur();
            textarea.focus();
          });
          return;
        }
      }
      if (this.lineBreakPosition !== textarea.selectionStart) {
        this.$nextTick(() => {
          this.lineBreakPosition = false;
        });
      }

      this.compositionEndedFlag = false;
    },
  },
});

interface Props {
  title: string;
  content: string;
}

interface Data {
  textareaHeight: number;
  resizeObserver: ResizeObserver | null;
  scrollTop: number;
  lineBreakPosition: boolean | number;
  compositionEndedFlag: boolean;
}

interface Computed {
  theme: string;
  scrollRatio: number;
  isEnableAutoIndent: boolean;
  isFirstTime: boolean;
  isMultiEditorPath: boolean;
}

interface Methods {
  onChangeTitle(e: InputEvent): void;
  onChangeContent(e: InputEvent): void;
  onFixed(value: string): void;
  compositionStart(): void;
  compositionEnd(): void;
  handleScroll(e: Event, position: any): void;
  onResize(): void;
  compositionUpdate(e: InputEvent): void;
  keyDown(e: KeyboardEvent): void;
}

export interface IEditorEmitArgs {
  value: string;
  isComposing: boolean;
}


import Vue from "vue";
import ThemeEditor from "@/components/organisms/ThemeEditor.vue";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { ThemeEditor },
  props: ["novelId"],
  methods: {},
  created() {},
});

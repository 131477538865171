
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import { VueLoading } from "vue-loading-template";
import { EpisodeFromNolaNovel } from "@/lib/models";

interface Data {
  inProcess: boolean;
}

interface Methods {
  onClickOutSide: () => void;
  onCancelClick: () => void;
  onDeleteClick: () => void;

  deleteManuscript: () => void;
}

interface Computed {
  episode: EpisodeFromNolaNovel;
}

interface Props {
  title: string;
  novelId: string;
  manuscriptId: string;
  episodeId?: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog, VueLoading },

  data() {
    return {
      inProcess: false,
    };
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onCancelClick() {
      this.$close(false);
    },
    onDeleteClick() {
      const { deleteManuscript } = this;
      deleteManuscript();
    },

    async deleteManuscript() {
      try {
        this.inProcess = true;
        const { novelId, manuscriptId, episodeId, episode } = this;
        const { dispatch } = this.$store;

        if (episodeId) {
          await dispatch("nolaNovelModule/deleteEpisodeLink", {
            novelId,
            manuscriptIds: [manuscriptId],
            episodes: [episode ?? episodeId],
          });
        }

        await dispatch("manuscriptModule/deleteManuscript", {
          novelId,
          key: manuscriptId,
        });
        this.$close(true);
      } catch (e) {
        this.$close(false);
      }
    },
  },

  computed: {
    episode() {
      const { episodeId } = this;
      const { getters } = this.$store;

      return getters["nolaNovelModule/episode"](episodeId);
    },
  },

  props: {
    title: String,
    novelId: String,
    manuscriptId: String,
    episodeId: String,
  },
});

export type ManuscriptDeleteConfirmProps = Props;

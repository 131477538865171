
import Vue from "vue";
import SelectBoxItem from "@/components/molecules/SelectBoxItem.vue";
import CheckIcon from "@/components/atoms/CheckIcon.vue";
import clone from "lodash/cloneDeep";

const ClickOutside = require("vue-click-outside");

export default Vue.extend({
  directives: { ClickOutside },
  components: { SelectBoxItem, CheckIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
    keyAttr: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    iconPlaceholder: {
      type: String,
      required: false,
    },
    selected: Array,
    max: Number,
  },
  data() {
    return {
      dataItems: this.$props.items,
      isSelecting: false,
    };
  },
  watch: {
    items() {
      this.dataItems = this.items;
    },
  },
  methods: {
    switchFragment() {
      this.isSelecting = !this.isSelecting;
    },
    close() {
      this.isSelecting = false;
    },
    selectItem(item) {
      let clonedItems = clone(this.selected);
      if (clonedItems) {
        if (clonedItems.some((element) => element[this.keyAttr] === item[this.keyAttr])) {
          clonedItems = clonedItems.filter((element) => element[this.keyAttr] !== item[this.keyAttr]);
        } else if (!this.max || this.max > clonedItems.length) {
          clonedItems.push(item);
        }
      } else {
        clonedItems = [item];
      }
      this.$emit("select", clonedItems);
    },
  },
  computed: {
    selectedItems() {
      let items = "";
      this.selected.forEach((item, index) => {
        if (index > 0) {
          items += " / ";
        }
        items += item.name;
      });
      return items;
    },
    isSelected() {
      return (item) =>
        this.selected ? this.selected.some((element) => element[this.keyAttr] === item[this.keyAttr]) : false;
    },
  },
});

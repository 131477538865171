
import Vue from "vue";
import ImageViewRound from "@/components/atoms/ImageView.vue";
import { showOkCancelDialog } from "@/lib/dialog";
import FolderOutlineIcon from "icons/FolderOutline.vue";
import FolderHiddenIcon from "icons/FolderHidden.vue";
import EqualIcon from "icons/Equal.vue";
import DotsHorizontalIcon from "icons/DotsHorizontal.vue";
import ClickOutside from "vue-click-outside";
import TrashCanOutlineIcon from "icons/TrashCanOutline.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: {
    ImageViewRound,
    FolderOutlineIcon,
    FolderHiddenIcon,
    EqualIcon,
    DotsHorizontalIcon,
    TrashCanOutlineIcon,
  },
  props: {
    to: Object,
    icon: String,
    label: String,
    folderName: String,
    iconPlaceholder: String,
    isActive: Boolean,
    showHandle: Boolean,
    item: Object,
    disabledFolder: {
      type: Boolean,
      default: false,
    },
    customClickFunction: Function, // click時の挙動を外部から指定したい場合用に渡す
    needsDoCustomClickFunction: Boolean, // customClickFunctionの実行要否の判断用に追加
    showProgress: Boolean,
    progress: String,
    progressWidth: String,
    isEditMode: Boolean,
    isManuscriptDeleteMode: Boolean,
    isManuscriptMoveFolderMode: Boolean,
  },
  data() {
    return {
      iconColor: "#787878",
      isShowMenu: false,
    };
  },
  methods: {
    async click() {
      if (this.showHandle) {
        return;
      }
      // マルチ執筆画面内での処理でもisModifiedに引っかかってしまうので、その前に処理を行わせている
      if (this.needsDoCustomClickFunction) {
        this.customClickFunction();
        return;
      }
      if (this.isModified()) {
        const yes = await showOkCancelDialog({
          title: "確認",
          content: "保存されていない変更があります。<br />変更を破棄しますか？",
          okButton: "破棄する",
          cancelButton: "キャンセル",
        });
        if (!yes) {
          return;
        }
      }
      this.setModified(false);
      this.$router.push(this.to, () => {});
    },
    setFolder(item) {
      if (this.$mq === "pc") this.click();

      this.$emit("clickSetFolder", { item });
    },
    deleteItem() {
      this.click();
      this.$emit("delete");
    },
    copyItem() {
      this.click();
      this.$emit("copy");
    },
    onClickMenu() {
      this.isShowMenu = true;
    },
    closeMenu() {
      this.isShowMenu = false;
    },
  },
  computed: {
    isCharactersPath() {
      return this.$route.path.includes("/characters");
    },
  },
});

interface Props {
  to: object;
  icon: string;
  label: string;
  folderName: string;
  iconPlaceholder: string;
  isActive: boolean;
  showHandle: boolean;
  item: object;
  disabledFolder: boolean;
  customClickFunction: void;
  needsDoCustomClickFunction: boolean;
  showProgress: boolean;
  progress: string;
  progressWidth: string;
  isEditMode: boolean;
  isManuscriptDeleteMode: boolean;
  isManuscriptMoveFolderMode: boolean;
}

interface Data {
  iconColor: string;
  isShowMenu: boolean;
}

interface Computed {
  isCharactersPath: boolean;
}

interface Methods {
  click(): Promise<void>;
  setFolder(item: object): void;
  deleteItem(): void;
  copyItem(): void;
  onClickMenu(): void;
  closeMenu(): void;
}

const WORKER_SCRIPT_URL = "/textlint-worker.js";

let _instance: Proofreading | null = null;
/**
 * 校正機能を取得します。
 */
export function loadProofreading(): Proofreading {
  if (_instance) {
    return _instance;
  }
  _instance = new Proofreading();
  return _instance;
}

export class Proofreading {
  /**
   * 校正機能ワーカー
   */
  private _worker: Worker;

  private _init: (() => void) | null = null;

  private _result: ((result: ProofreadingResult) => void) | null = null;

  private _ruleChanged: (() => void) | null = null;

  /** 校正開始のタイマー */
  private _runningStart: number | null = null;

  /** 校正待ちテキスト */
  private _waitingText: string | null = null;

  private _proofreadingRules: ProofreadingRules = DEFAULT_PROOFREADING_RULES;

  constructor() {
    this.initWorker();
  }

  private initWorker() {
    const worker = new Worker(WORKER_SCRIPT_URL);
    worker.addEventListener("message", (event) => {
      if (event.data.command === "init") {
        if (this._init) {
          this._init();
        }
        this.changeLintRule(this._proofreadingRules, true);
      } else if (event.data.command === "lint:result") {
        if (this._result) {
          this._result(event.data.result);
        }

        this._runningStart = null;

        if (this._waitingText) {
          // 校正待ちのテキストがある場合は、それを校正してキューから削除する。
          const text = this._waitingText;
          this._waitingText = null;
          this.run(text);
        }
      } else {
        console.warn(`unknown command: ${event.data.command}`);
      }
    });
    worker.addEventListener("error", (event) => {
      console.error(event);
      this._runningStart = null;
      this._waitingText = null;
    });
    this._worker = worker;
  }

  /**
   * テキストの校正を行います。
   * @param text
   */
  public run(text: string): void {
    if (this.running) {
      // 前の処理が完了する前にさらに処理をすると処理が詰まってしまうため
      // 既に校正待ちのテキストがある場合は、それをキャンセルして、新しいテキストを校正待ちにする。
      this._waitingText = text;
      return;
    }
    this._runningStart = Date.now();
    this._worker.postMessage({
      command: "lint",
      text,
      ext: ".txt",
    });
  }

  /**
   * TextLintのルールを更新します。
   */
  public changeLintRule(rules: ProofreadingRules, suppressEvent: boolean = false) {
    // ルールが未指定の場合、デフォルトのルールを設定する。
    this._proofreadingRules = rules || DEFAULT_PROOFREADING_RULES;

    this._worker.postMessage({
      command: "merge-config",
      textlintrc: {
        rules: this._proofreadingRules,
      },
    });

    if (this._ruleChanged && !suppressEvent) {
      this._ruleChanged();
    }
  }

  /** 初期化時に呼び出すコールバックを登録する。 */
  public set init(init: () => void) {
    this._init = init;
  }

  /** 校正結果を受け取った時に呼び出すコールバックを登録する。 */
  public set result(result: (result: ProofreadingResult) => void) {
    this._result = result;
  }

  /** 校正ルール変更時に呼び出すコールバックを登録する。 */
  public set ruleChanged(ruleChanged: () => void) {
    this._ruleChanged = ruleChanged;
  }

  /** 校正が走っているかどうか */
  public get running() {
    return Boolean(this._runningStart);
  }

  /**
   * 進行中の校正およびキューに溜まっている校正処理を中断する。
   */
  public purge() {
    if (!this.running) {
      // 校正プロセスが走っていない場合は何もしない。
      return;
    }

    // ワーカーで走っている校正プロセスを止める場合ワーカー毎作り直す（それ以外の方法が見つかられないため）
    this._worker.terminate();
    this._runningStart = null;
    this._waitingText = null;
    this.initWorker();
    this.changeLintRule(this._proofreadingRules, true);
  }
}

export interface ProofreadingResult {
  messages: ProofreadingMessage[];
}

export interface ProofreadingMessage {
  index: number;
  ruleId: string;
  line: number;
  message: string;
  column: number;
  fix?: {
    range: [number, number];
    text: string;
  };
}

export interface ProofreadingRules {
  "general-novel-style-ja":
    | boolean
    | {
        // 連続した三点リーダー(…)の数は偶数にする
        /* eslint-disable camelcase */
        even_number_ellipsises: boolean;
        // 連続したダッシュ(―)の数は偶数にする
        even_number_dashes: boolean;
        // 連続した句読点(。、)を許可しない
        appropriate_use_of_punctuation: boolean;
        // 連続した中黒(・)を許可しない
        appropriate_use_of_interpunct: boolean;
        // 連続した長音符(ー)を許可しない
        appropriate_use_of_choonpu: boolean;
        // 閉じ括弧の手前に句読点(。、)を置かない
        no_punctuation_at_closing_quote: boolean;
        // 疑問符(？)と感嘆符(！)の直後にスペースを置く
        space_after_marks: boolean;
        // マイナス記号(−)は数字の前にしか許可しない
        appropriate_use_of_minus_sign: boolean;
        // アラビア数字は最大桁数までしか許可しない
        max_arabic_numeral_digits: number | false;

        // 各段落の先頭に許可する文字 (false: チェックしない)
        chars_leading_paragraph: string | false;
        /* eslint-enable camelcase */
      };
  "preset-ja-technical-writing": {
    // 漢数字と算用数字を使い分けます
    "arabic-kanji-numbers": boolean;
    // 「ですます調」、「である調」を統一します
    "no-mix-dearu-desumasu": boolean;
    // 文末の句点記号として「。」を使います
    "ja-no-mixed-period": boolean;
    // 二重否定は使用しない
    "no-double-negative-ja": boolean;
    // ら抜き言葉を使用しない
    "no-dropping-the-ra": boolean;
    // 逆接の接続助詞「が」を連続して使用しない
    "no-doubled-conjunctive-particle-ga": boolean;
    // 同じ接続詞を連続して使用しない
    "no-doubled-conjunction": boolean;
    // 同じ助詞を連続して使用しない
    "no-doubled-joshi": boolean;
    // 同一の単語を間違えて連続しているのをチェックする
    "ja-no-successive-word":
      | boolean
      | {
          allowOnomatopee?: boolean;
          allow?: string[];
        };
    // よくある日本語の誤用をチェックする
    "ja-no-abusage": boolean;
    // 入力ミスで発生する不自然なアルファベットをチェックする
    "ja-unnatural-alphabet": boolean;
    // 対になっていない括弧をチェックする
    "no-unmatched-pair": boolean;

    /** デフォルトOFF */
    // 1文の長さは100文字以下とする
    "sentence-length":
      | false
      | {
          max: number;
        };
    // カンマは1文中に3つまで
    "max-comma":
      | false
      | {
          max: number;
        };
    // 読点は1文中に3つまで
    "max-ten":
      | false
      | {
          max: number;
        };
    // 連続できる最大の漢字長は6文字まで
    "max-kanji-continuous-len":
      | false
      | {
          max: number;
        };
    // 不必要な制御文字を使用しない
    "no-invalid-control-character": boolean;
    // 不必要なゼロ幅スペースを使用しない
    "no-zero-width-spaces": boolean;
    // 感嘆符!！、感嘆符?？を使用しない
    "no-exclamation-question-mark": boolean;
    // 半角カナを使用しない
    "no-hankaku-kana": boolean;
    // 弱い日本語表現の利用を使用しない
    "ja-no-weak-phrase": boolean;
    // 冗長な表現をチェックする
    "ja-no-redundant-expression": boolean;
    // UTF8-MAC 濁点を使用しない
    "no-nfd": boolean;
  };
}

/**
 * デフォルトの校正ルールの設定
 */
export const DEFAULT_PROOFREADING_RULES: ProofreadingRules = {
  "general-novel-style-ja": {
    // 連続した三点リーダー(…)の数は偶数にする
    even_number_ellipsises: true,
    // 連続したダッシュ(―)の数は偶数にする
    even_number_dashes: true,
    // 連続した句読点(。、)を許可しない
    appropriate_use_of_punctuation: true,
    // 連続した中黒(・)を許可しない
    appropriate_use_of_interpunct: true,
    // 連続した長音符(ー)を許可しない
    appropriate_use_of_choonpu: true,
    // 閉じ括弧の手前に句読点(。、)を置かない
    no_punctuation_at_closing_quote: false,
    // 疑問符(？)と感嘆符(！)の直後にスペースを置く
    space_after_marks: false,
    // マイナス記号(−)は数字の前にしか許可しない
    appropriate_use_of_minus_sign: false,
    // アラビア数字は最大桁数までしか許可しない
    max_arabic_numeral_digits: false,

    // 各段落の先頭に許可する文字 (false: チェックしない)
    chars_leading_paragraph: "　「『【〈《（(“",
  },
  "preset-ja-technical-writing": {
    // 対になっていない括弧をチェックする
    "no-unmatched-pair": true,
    // 文末の句点記号として「。」を使います
    "ja-no-mixed-period": true,
    // 漢数字と算用数字を使い分けます
    "arabic-kanji-numbers": true,
    // 「ですます調」、「である調」を統一します
    "no-mix-dearu-desumasu": false,
    // 二重否定は使用しない
    "no-double-negative-ja": true,
    // ら抜き言葉を使用しない
    "no-dropping-the-ra": true,
    // 逆接の接続助詞「が」を連続して使用しない
    "no-doubled-conjunctive-particle-ga": true,
    // 同じ接続詞を連続して使用しない
    "no-doubled-conjunction": true,
    // 同じ助詞を連続して使用しない
    "no-doubled-joshi": true,
    // 同一の単語を間違えて連続しているのをチェックする
    "ja-no-successive-word": {
      allowOnomatopee: true,
      // これらの記号が連続使用と判断されてしまうため許可リストに追加
      allow: ["…", "―", "・"],
    },
    // よくある日本語の誤用をチェックする
    "ja-no-abusage": true,
    // 入力ミスで発生する不自然なアルファベットをチェックする
    "ja-unnatural-alphabet": true,
    // 1文の長さは100文字以下とする
    "sentence-length": false,
    // "sentence-length": {
    //     max: 100
    // },
    // カンマは1文中に3つまで
    "max-comma": {
      max: 3,
    },
    // 読点は1文中に3つまで
    "max-ten": {
      max: 3,
    },
    // 連続できる最大の漢字長は6文字まで
    "max-kanji-continuous-len": false,
    // "max-kanji-continuous-len": {
    //     max: 6
    // },
    // 不必要な制御文字を使用しない
    "no-invalid-control-character": false,
    // 不必要なゼロ幅スペースを使用しない
    "no-zero-width-spaces": false,
    // 感嘆符!！、感嘆符?？を使用しない
    "no-exclamation-question-mark": false,
    // 半角カナを使用しない
    "no-hankaku-kana": false,
    // 弱い日本語表現の利用を使用しない
    "ja-no-weak-phrase": false,
    // 冗長な表現をチェックする
    "ja-no-redundant-expression": false,
    // UTF8-MAC 濁点を使用しない
    "no-nfd": false,
  },
};

export function createUrlWithUtmParams(url: string, utmCampaign: string, utmContent?: string) {
  // URLを解析してベースURLと既存のクエリパラメータを取得
  const urlObject = new URL(url);

  // デフォルトのutmパラメータをクエリパラメータに設定
  urlObject.searchParams.append("utm_source", "nola-web");
  urlObject.searchParams.append("utm_medium", "direct");

  // クエリパラメータにutm_campaignを追加し、画面名を設定
  urlObject.searchParams.append("utm_campaign", utmCampaign);

  // オプショナルなutm_contentが指定されている場合に追加
  if (utmContent) {
    // ボタンやリンクのクリックイベントをトラッキングするためのIDを追加する
    urlObject.searchParams.append("utm_content", utmContent);
  }

  return urlObject.toString();
}

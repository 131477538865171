
import Vue from "vue";
import gql from "graphql-tag";
import { callApi } from "@/lib/api";
import crypto from "crypto";
import TextField from "@/components/molecules/TextField.vue";
import PremiumInformation from "@/components/atoms/PremiumInformation.vue";
import { Plan } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { TextField, PremiumInformation },
  props: {
    novelId: String,
    value: Object,
  },
  created() {
    if (!this.value) {
      return;
    }
    this.name = this.value.name;
    const { detail } = this.value as { detail: any[] };
    if (!detail) {
      return;
    }
    this.detail = this.detail.map((x) => {
      let targetValue = x.value;
      const target = detail.find((y) => y.name === x.name);
      if (target) targetValue = target.value;
      return { ...x, value: targetValue };
    });
    const { additionalColumn } = this.value as { additionalColumn: any[] };
    if (!additionalColumn || additionalColumn.length === 0) {
      return;
    }
    this.additionalColumn = additionalColumn;
  },
  async mounted() {
    const { billingInfo } = await callApi(gql`
      query BillingInfo {
        billingInfo {
          plan
        }
      }
    `);
    this.plan = billingInfo.plan;
  },
  methods: {
    change() {
      const { name, detail, additionalColumn } = this;
      this.$emit("input", {
        name,
        detail: detail.filter(({ value }) => value).map(({ name, value }) => ({ name, value })),
        additionalColumn,
      });
      this.setModified(true);
    },
    add() {
      const { additionalColumn } = this;
      additionalColumn.push({
        name: this.generateUniqueValue(),
        label: "",
        content: "",
      });
      this.change();
    },
    remove(index) {
      const { additionalColumn } = this;
      additionalColumn.splice(index, 1);
      this.change();
    },
    generateUniqueValue() {
      const N = 16;
      return crypto.randomBytes(N).toString("base64").substring(0, N);
    },
  },
  data() {
    return {
      plan: 0,
      name: "",
      detail: [
        {
          name: "period",
          value: "",
          _label: "時代",
          _placeholder: "時代を入力してください。",
        },
        {
          name: "law",
          value: "",
          _label: "法律",
          _placeholder: "法律を入力してください。",
          _rows: 4,
        },
        {
          name: "politics",
          value: "",
          _label: "政治",
          _placeholder: "政治を入力してください。",
          _rows: 4,
        },
        {
          name: "military",
          value: "",
          _label: "軍事",
          _placeholder: "軍事を入力してください。",
          _rows: 4,
        },
        {
          name: "religion",
          value: "",
          _label: "宗教",
          _placeholder: "宗教を入力してください。",
          _rows: 4,
        },
        {
          name: "language",
          value: "",
          _label: "言語",
          _placeholder: "言語設定を入力してください。",
          _rows: 4,
        },
        {
          name: "other",
          value: "",
          _label: "その他",
          _placeholder: "その他のメモを入力してください。",
          _rows: 4,
        },
      ],
      additionalColumn: [
        {
          name: (this as any).generateUniqueValue(),
          label: "",
          content: "",
        },
      ],
    };
  },
});

interface Props {
  novelId: string;
  value: {
    name: string;
    detail: any[];
    additionalColumn: any[];
  };
}

interface Data {
  plan: Plan;
  name: string;
  detail: any[];
  additionalColumn: any[];
}

interface Computed {}

interface Methods {
  change(): void;
  add(): void;
  remove(index: number): void;
  generateUniqueValue(): string;
}


import Vue, { PropType } from "vue";
import { WorldView, WorldViewFolder } from "@/lib/models";
import SortableList from "@/components/molecules/SortableList.vue";
import ListFolderItem from "@/components/molecules/ListFolderItem.vue";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
import FolderRenameDialog, { FolderRenameDialogProps } from "@/components/ui/novel/FolderRenameDialog.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SortableList, ListFolderItem },
  props: {
    novelId: String, // 小説ID
    items: Array as PropType<WorldViewFolder[]>, // 世界観フォルダ
    worldViewsCount: Number, // フォルダに含まれる世界観の数
    isInsert: Boolean, // 状態管理用。世界観にフォルダを設定する状態かどうかを管理
    selectedWorldView: Object as PropType<WorldView>, // フォルダを設定しようとしている世界観
  },
  data() {
    return {
      folders: this.items || [],
      isEditMode: false,
      selectedInsertFolderIds: [],
    };
  },
  watch: {
    items() {
      this.folders = this.items;
    },
    isInsert: {
      handler(isInsert: boolean) {
        if (!isInsert) return;

        const folders = this.foldersBelongWorldView(this.selectedWorldView.worldViewId) as WorldViewFolder[];
        this.selectedInsertFolderIds = folders.map((folder) => folder.worldViewFolderId);
      },
      immediate: true,
    },
  },
  computed: {
    foldersBelongWorldView() {
      return (worldViewId) => this.$store.getters["worldViewModule/foldersBelongWorldView"](worldViewId);
    },
    isSelectedFolder() {
      return (worldViewFolderId) => this.selectedInsertFolderIds.includes(worldViewFolderId);
    },
  },
  methods: {
    selectFolder(worldViewFolderId) {
      this.$emit("selectedFolder", worldViewFolderId);
    },
    async completeSetFolder() {
      const { folders, selectedInsertFolderIds, selectedWorldView } = this;
      const updatedFolders = (folders as WorldViewFolder[]).map((folder) => {
        const target = folder;
        if (selectedInsertFolderIds.includes(target.worldViewFolderId)) {
          target.worldViewKeys = [...new Set([...target.worldViewKeys, selectedWorldView.worldViewId])];
        } else {
          target.worldViewKeys = target.worldViewKeys.filter((key) => key !== selectedWorldView.worldViewId);
        }
        return target;
      });

      await this.updateWorldViewFolder(updatedFolders);
      this.$emit("clickCompleteSetFolder");
    },
    async createWorldViewFolder() {
      const { novelId } = this;
      const name = "新しいフォルダ";

      await this.$store.dispatch("worldViewModule/createFolder", { novelId, name });
    },
    async renameWorldViewFolder(key) {
      const renameCallback = async (name: string) => {
        const { novelId } = this;
        const worldViewFolders = (this.folders as WorldViewFolder[]).map((folder) => {
          const item = folder;
          if (item.worldViewFolderId === key) item.name = name;
          return item;
        });
        await this.$store.dispatch("worldViewModule/updateFolder", { novelId, worldViewFolders });
      };
      const { name } = (this.folders as WorldViewFolder[]).find((folder) => folder.worldViewFolderId === key)!;

      const confirmDialog = new Dialog(FolderRenameDialog);
      const data: FolderRenameDialogProps = {
        name,
        renameCallback,
      };
      await confirmDialog.show(data);
    },
    async deleteWorldViewFolder(key) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "削除の最終確認",
        content: "本当にフォルダを削除しますか？<br />※ 一度削除したフォルダは元に戻せません。",
        change: "削除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }

      const { novelId } = this;
      const worldViewFolders = (this.folders as WorldViewFolder[]).filter((folder) => folder.worldViewFolderId !== key);
      await this.$store.dispatch("worldViewModule/updateFolder", { novelId, worldViewFolders });

      this.$notify({
        title: "削除しました",
        type: "error",
      });
    },
    async switchEditMode() {
      // 編集終了を押した場合、かつ変更していた場合
      if (this.isEditMode) {
        await this.updateWorldViewFolder(this.folders);
      }

      this.isEditMode = !this.isEditMode;
    },
    async updateWorldViewFolder(folders) {
      const { novelId } = this;

      const worldViewFolders = folders.map((folder) => {
        const worldViewKeys = folder.worldViewKeys
          .filter((key) => key != null)
          .filter((el, index, selfArray) => selfArray.indexOf(el) === index);
        return { ...folder, worldViewKeys };
      });

      await this.$store.dispatch("worldViewModule/updateFolder", { novelId, worldViewFolders });
    },
    onToggleInsertFolder(worldViewFolderId) {
      if ((this.selectedInsertFolderIds as string[]).includes(worldViewFolderId)) {
        this.selectedInsertFolderIds = this.selectedInsertFolderIds.filter(
          (folderId) => folderId !== worldViewFolderId
        );
      } else {
        this.selectedInsertFolderIds.push(worldViewFolderId);
      }
    },
  },
});

interface Props {
  novelId: string;
  items: WorldViewFolder[];
  worldViewsCount: number;
  isInsert: boolean;
  selectedWorldView: WorldView | null;
}

interface Data {
  folders: WorldViewFolder[];
  isEditMode: boolean;
  selectedInsertFolderIds: string[];
}

interface Computed {
  foldersBelongWorldView(worldViewId: string): WorldViewFolder[];
  isSelectedFolder(worldViewFolderId: string): boolean;
}

interface Methods {
  selectFolder(worldViewFolderId: string): void;
  completeSetFolder(): Promise<void>;
  createWorldViewFolder(): Promise<void>;
  renameWorldViewFolder(key: string): Promise<void>;
  deleteWorldViewFolder(key: string): void;
  switchEditMode(): Promise<void>;
  updateWorldViewFolder(worldViewFolders: WorldViewFolder[]): Promise<void>;
  onToggleInsertFolder(worldViewFolderId: string): void;
}

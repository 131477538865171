
import Vue, { PropType } from "vue";
import VueClipboard from "vue-clipboard2";
import clone from "lodash/cloneDeep";
import InputText from "@/components/atoms/InputText.vue";
import DeleteIcon from "@/components/atoms/DeleteIcon.vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";
import WarningIcon from "@/components/atoms/WarningIcon.vue";
import { Manuscript, SharedLink, Plan, SharedLinkKind } from "@/lib/models";
import { SharedManuscriptStorage } from "@/lib/storages";
import { compareAsc } from "date-fns";
// NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
// import { Dialog } from "@/lib/utils";
// import PremiumFeatureDialog, { PremiumFeatureDialogProps } from "@/components/ui/PremiumFeatureDialog.vue";
// import { downloadPublicImage } from "@/lib/s3";
import { Plot, SubPlot, OverallPlot, PlotList } from "@/lib/models/plot";

Vue.use(VueClipboard);

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    InputText,
    DeleteIcon,
    CheckboxIcon,
    WarningIcon,
  },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      name: "",
      copied: false,
      saved: false,
      deleted: false,
      isDeleting: false,
      isStopping: false,
      SharedLinkKind,
      isOpenManuscriptList: true,
      isOpenPlotList: true,
    };
  },
  destroyed() {
    this.closePublishSharedLink();
  },
  methods: {
    select(item) {
      this.selected = clone(item);
    },
    onClickItem(item, kind) {
      switch (kind) {
        case SharedLinkKind.MANUSCRIPT: {
          const manuscriptKey = (item as Manuscript).key;
          if (this.selected.manuscriptKeys.includes(manuscriptKey)) {
            this.selected.manuscriptKeys = this.selected.manuscriptKeys.filter(
              (x: string | undefined) => x !== manuscriptKey
            );
          } else {
            this.selected.manuscriptKeys.push(manuscriptKey);
          }
          break;
        }
        case SharedLinkKind.PLOT: {
          const { plotId } = item as OverallPlot | SubPlot;

          // プロット共有機能追加前のデータにはplotIds属性が存在しない為、
          // this.selected.plotIdがFalsyの値の場合空の配列を代入する。
          if (!this.selected.plotIds) {
            this.selected.plotIds = [];
          }

          if (this.selected.plotIds.includes(plotId)) {
            this.selected.plotIds = this.selected.plotIds.filter((x: string | undefined) => x !== plotId);
          } else {
            this.selected.plotIds.push(plotId);
          }
          break;
        }
        default:
      }
    },
    toggleMenu(kind) {
      switch (kind) {
        case SharedLinkKind.MANUSCRIPT:
          this.isOpenManuscriptList = !this.isOpenManuscriptList;
          break;
        case SharedLinkKind.PLOT:
          this.isOpenPlotList = !this.isOpenPlotList;
          break;
        default:
          break;
      }
    },
    closePublishSharedLink() {
      this.$store.dispatch("sharedLinkModule/closePublishSharedLink");
    },
    async createSharedLink() {
      if (this.isFree && this.sharedLinkListBelongOwner.length >= 3) {
        // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
        // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
        // const data: PremiumFeatureDialogProps = {
        //   title: "共有リンクの無制限作成はプレミアム機能です",
        //   text: "原稿の共有リンクは、アカウントごとに3つまで無料で作成が可能となっております。プレミアム会員になると、共有リンクが無制限で作成することができますので、多くの方に作品を共有する場合はぜひご利用いただけますと幸いです。",
        //   image: (await downloadPublicImage("shared/images/gif/manuscript_share.gif")) as string,
        // };
        // premiumFeatureDialog.show(data);

        this.$router.push({ name: "subscriptionAnnounce", query: { from: "createSharedLink" } });

        return;
      }

      const { novelId } = this;
      this.$store.dispatch("sharedLinkModule/createSharedLink", {
        novelId,
        name: "",
        manuscriptKeys: [],
        plotIds: [],
        callback: () => {
          this.selected = clone(this.createdSharedLink);
          this.$store.dispatch("sharedLinkModule/initializeCreatedSharedLink");
          this.$store.dispatch("sharedLinkModule/initializeSharedLinkListBelongOwner");
        },
      });
    },
    isChecked(item, kind) {
      switch (kind) {
        case SharedLinkKind.MANUSCRIPT:
          return this.selected.manuscriptKeys.includes((item as Manuscript).key);
        case SharedLinkKind.PLOT:
          // プロット共有機能追加前のデータにはplotIds属性が存在しない為、falseを返す
          if (!this.selected.plotIds) {
            return false;
          }
          return this.selected.plotIds.includes((item as OverallPlot | SubPlot).plotId);
        default:
          return false;
      }
    },
    onCopy() {
      this.copied = true;
      setTimeout(() => (this.copied = false), 2000);
    },
    save() {
      const { sharedLinkId, novelId, name, manuscriptKeys, plotIds, enabled } = this.selected as SharedLink;
      this.$store.dispatch("sharedLinkModule/updateSharedLink", {
        sharedLinkId,
        novelId,
        name,
        manuscriptKeys,
        plotIds: plotIds || [],
        enabled,
        callback: async () => {
          /** 共有対象の原稿を他人が閲覧可能なディレクトリに複製する */
          if (manuscriptKeys) {
            const sharedManuscriptStorage = new SharedManuscriptStorage();
            const promiseArray = manuscriptKeys.map(
              async (key) => await sharedManuscriptStorage.duplicateFile(novelId, key)
            );
            await Promise.all(promiseArray);
          }

          this.saved = true;
          setTimeout(() => (this.saved = false), 2000);
        },
      });
    },
    onClickDelete() {
      this.isDeleting = true;
    },
    cancelDelete() {
      this.isDeleting = false;
    },
    onClickStop() {
      this.isStopping = true;
    },
    cancelStop() {
      this.isStopping = false;
    },
    deleteSharedLink() {
      const { sharedLinkId, novelId } = this.selected as SharedLink;
      this.$store.dispatch("sharedLinkModule/deleteSharedLink", {
        sharedLinkId,
        novelId,
        callback: () => {
          this.$store.dispatch("sharedLinkModule/initializeSharedLinkListBelongOwner");
          this.selected = null;
          this.isDeleting = false;
          this.deleted = true;
          setTimeout(() => (this.deleted = false), 2000);
        },
      });
    },
    stopSharedLink() {
      const { sharedLinkId, novelId, name, manuscriptKeys, plotIds } = this.selected as SharedLink;
      this.$store.dispatch("sharedLinkModule/updateSharedLink", {
        sharedLinkId,
        novelId,
        name,
        manuscriptKeys,
        plotIds: plotIds || [],
        enabled: false,
        callback: () => {
          this.selected.enabled = false;
          this.isStopping = false;
        },
      });
    },
    resumeSharedLink() {
      const { sharedLinkId, novelId, name, manuscriptKeys, plotIds } = this.selected as SharedLink;
      this.$store.dispatch("sharedLinkModule/updateSharedLink", {
        sharedLinkId,
        novelId,
        name,
        manuscriptKeys,
        plotIds: plotIds || [],
        enabled: true,
        callback: () => {
          this.selected.enabled = true;
        },
      });
    },
  },
  computed: {
    sharedLinkList() {
      const sharedLinkList = this.$store.getters["sharedLinkModule/sharedLinkList"] as SharedLink[];
      const enabledList = sharedLinkList
        .filter((sharedLink) => sharedLink.enabled)
        .sort((x, y) => compareAsc(x.createdAt!, y.createdAt!));
      const disabledList = sharedLinkList
        .filter((sharedLink) => !sharedLink.enabled)
        .sort((x, y) => compareAsc(x.createdAt!, y.createdAt!));
      return enabledList.concat(disabledList);
    },
    sharedLinkListBelongOwner() {
      return this.$store.getters["sharedLinkModule/sharedLinkListBelongOwner"] as SharedLink[];
    },
    createdSharedLink() {
      return this.$store.getters["sharedLinkModule/createdSharedLink"];
    },
    manuscriptList() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    sharedLinkPath() {
      return (item) => {
        if (!item) return undefined;

        // eslint-disable-next-line no-restricted-globals
        const { protocol, host } = location;
        const { sharedLinkId, novelId } = item;

        return `${protocol}//${host}/share/${sharedLinkId}/${novelId}`;
      };
    },
    resolveSharedLinkPath() {
      return (item) => {
        const { sharedLinkId, novelId } = item;

        return `/share/${sharedLinkId}/${novelId}`;
      };
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
    plotList() {
      const plots: Plot = this.$store.getters["plotModule/plots"];
      const { novelId } = this.selected as SharedLink;
      const overallPlot = {
        name: "全体プロット",
        plotId: `overall-plot-${novelId}`,
        ...plots,
      };
      const subPlots = this.$store.getters["plotModule/subPlots"];

      return [overallPlot, ...subPlots];
    },
  },
});

interface Data {
  selected: SharedLink | null;
  name: string;
  copied: boolean;
  saved: boolean;
  deleted: boolean;
  isDeleting: boolean;
  isStopping: boolean;
  SharedLinkKind: typeof SharedLinkKind;
  isOpenManuscriptList: boolean;
  isOpenPlotList: boolean;
}

interface Props {
  novelId: string;
  kind: string;
}

interface Computed {
  sharedLinkList: SharedLink[];
  sharedLinkListBelongOwner: SharedLink[];
  createdSharedLink: SharedLink;
  manuscriptList: Manuscript[];
  sharedLinkPath: (item: SharedLink | null) => string | undefined;
  resolveSharedLinkPath: (item: SharedLink) => string;
  plan: Plan;
  isFree: boolean;
  plotList: PlotList;
}

interface Methods {
  select: (item: SharedLink) => void;
  onClickItem: (item: Manuscript | OverallPlot | SubPlot, kind: string) => void;
  closePublishSharedLink: () => void;
  createSharedLink: () => void;
  isChecked: (item: Manuscript | OverallPlot | SubPlot, kind: string) => boolean;
  onCopy: () => void;
  save: () => void;
  onClickDelete: () => void;
  cancelDelete: () => void;
  onClickStop: () => void;
  cancelStop: () => void;
  deleteSharedLink: () => void;
  stopSharedLink: () => void;
  resumeSharedLink: () => void;
  toggleMenu: (kind: string) => void;
}


import Vue from "vue";
import Plots from "@/components/organisms/Plots.vue";
import PlotList from "@/components/organisms/PlotList.vue";
import PublishSharedLink from "@/components/organisms/PublishSharedLink.vue";
import { Plot, SubPlot } from "@/lib/models";
import isMobile from "ismobilejs";
import SlideSharedLinkMenu from "@/components/organisms/SlideSharedLinkMenu.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { Plots, PlotList, PublishSharedLink, SlideSharedLinkMenu },
  props: {
    novelId: String,
    plotId: String,
  },
  async created() {
    const { $store, novelId } = this;
    const { dispatch } = $store;

    dispatch("plotModule/initialize", novelId);

    this.$store.dispatch("sharedLinkModule/initializeSharedLinkList", novelId);
    this.$store.dispatch("sharedLinkModule/initializeSharedLinkListBelongOwner");
    this.$store.dispatch("sharedLinkModule/initializeCommentListBelongOwner", novelId);
  },
  computed: {
    plot() {
      const { $store, plotId } = this;

      if (plotId) {
        return $store.getters["plotModule/subPlot"](plotId);
      }
      return $store.getters["plotModule/plots"];
    },
    subPlots() {
      const { $store } = this;
      return $store.getters["plotModule/subPlots"];
    },
    isShowPublishSharedLink() {
      return this.$store.getters["sharedLinkModule/isShowPublishSharedLink"];
    },
    isEnableMobile() {
      return !isMobile().phone && !(isMobile().tablet && window.innerWidth < 2048);
    },
    isHamburgerMenuOpen() {
      return this.$store.getters["plotModule/isHamburgerMenuOpen"];
    },
  },
});

interface Props {
  novelId: string;
  plotId?: string;
}

interface Data {}

interface Computed {
  plot: Plot | SubPlot;
  subPlots: SubPlot[];
  isShowPublishSharedLink: boolean;
  isEnableMobile: boolean;
  isHamburgerMenuOpen: boolean;
}

interface Methods {}

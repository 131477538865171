
import Vue from "vue";

export default Vue.extend({
  props: {
    prefix: String,
  },
  data() {
    return {
      id: null,
      imageData: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileName = `${this.prefix}/${file.name}`;

        // await image.upload(fileName, file, file.type);
        const tgt = event.target as FileReader;
        const image = tgt.result as string;

        // const data = resizeImage.resize(image, 200, 100, resizeImage.PNG);

        this.$emit("update:file", { fileName, data: image });
      };
      reader.readAsDataURL(file);
    },
  },
});

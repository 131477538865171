import { render, staticRenderFns } from "./ListFolderItem.vue?vue&type=template&id=938e8908&scoped=true"
import script from "./ListFolderItem.vue?vue&type=script&lang=ts"
export * from "./ListFolderItem.vue?vue&type=script&lang=ts"
import style0 from "./ListFolderItem.vue?vue&type=style&index=0&id=938e8908&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "938e8908",
  null
  
)

export default component.exports

import Vue from "vue";

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    isUnread: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: "#000000",
    },
  },
});

interface Props {
  isUnread: boolean;
  fillColor: string;
}


import Vue from "vue";
import ListTemplate from "@/components/templates/ListTemplate.vue";
import CorrelationGroupList from "@/components/organisms/CorrelationGroupList.vue";
import CorrelationList from "@/components/organisms/CorrelationList.vue";
import CorrelationContent from "@/components/organisms/CorrelationContent.vue";
import { callApi } from "@/lib/api";
import { compareAsc } from "date-fns";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ListTemplate,
    CorrelationGroupList,
    CorrelationList,
    CorrelationContent,
  },
  props: {
    novelId: String,
    correlationGroupId: String,
    correlationId: String,
  },
  data() {
    return {
      correlationGroupList: [],
      correlationList: [],
      characterList: [],
    };
  },
  async created() {
    this.load();
  },
  methods: {
    async load() {
      await Promise.all([this.fetchCorrelationGroupList(), this.fetchCorrelationList(), this.fetchCharacterList()]);

      if (!this.correlationGroupId && this.correlationGroupList.length) {
        this.$router.push({
          name: "correlations",
          params: { novelId: this.novelId, correlationGroupId: this.correlationGroupList[0].correlationGroupId },
        });
      }
    },
    async fetchCorrelationGroupList() {
      const correlationGroupList = await this.getCorrelationGroupList();
      this.correlationGroupList = correlationGroupList.items;
    },
    async fetchCorrelationList() {
      const correlationList = await this.getCorrelationList();
      this.correlationList = correlationList.items.sort((x, y) => compareAsc(x.createdAt, y.createdAt));
    },
    async fetchCharacterList() {
      const characterList = await this.getCharacterList();
      this.characterList = characterList.items;
    },
    async getCorrelationGroupList() {
      const { novelId } = this;
      const query = `
        query CorrelationGroupList ($novelId: ID!){
          correlationGroupList (novelId: $novelId){
            items {
              novelId
              correlationGroupId
              name
              diagram {
                correlationKeys
                coordinate {
                  characterId
                  x
                  y
                }
              }
            }
          }
        }`;
      const { correlationGroupList } = await callApi(query, {
        novelId,
      });
      return correlationGroupList;
    },
    async getCorrelationList() {
      const { novelId, correlationGroupId } = this;
      if (!correlationGroupId) {
        return {
          items: [],
        };
      }
      const query = `
        query CorrelationList ($input: CorrelationListGetInput!) {
          correlationList (input: $input) {
            items {
              novelId
              correlationId
              correlationGroupId
              characterA
              characterB
              relationshipAtoB {
                relationship
                color
              }
              relationshipBtoA {
                relationship
                color
              }
              memo
              createdAt
            }
          }
        }`;
      const { correlationList } = await callApi(query, {
        input: {
          novelId,
          correlationGroupId,
        },
      });
      return correlationList;
    },
    getCorrelationGroupName() {
      let correlationGroupName;
      this.correlationGroupList.forEach((item) => {
        if (item.correlationGroupId === this.correlationGroupId) {
          correlationGroupName = item.name;
        }
      });
      return correlationGroupName;
    },
    getCorrelationDiagram() {
      let correlationDiagram;
      this.correlationGroupList.forEach((item) => {
        if (item.correlationGroupId === this.correlationGroupId) {
          correlationDiagram = item.diagram;
        }
      });
      return correlationDiagram;
    },
    async getCharacterList() {
      const { novelId } = this;
      const GetQuery = `
        query ListCharacters($input: ListCharacterInput!){
          characters(input: $input) {
            nextToken
            items {
              novelId
              characterId
              name
              image
              createdAt
              updatedAt
              detail {
                name
                value
              }
              additionalColumn {
                name
                label
                content
              }
            }
          }
        }`;
      const { characters } = await callApi(GetQuery, {
        input: {
          novelId,
        },
      });
      return characters;
    },
  },
  watch: {
    $route: "load",
  },
});


import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    selectedText: {
      type: String,
      default: "",
    },
    negative: {
      type: String,
      default: "閉じる",
    },
    positive: {
      type: String,
      default: "はい",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    return {
      entryText: this.selectedText,
      rubyText: "",
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(false);
    },
    onPositiveClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      const { entryText, rubyText } = this;
      this.$close(`｜${entryText}《${rubyText}》`);
    },
    onClickOutSide() {
      this.$close(false);
    },
  },
});

interface Props {
  selectedText: string;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  entryText: string;
  rubyText: string;
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
}

interface Computed {}

export type RubyDialogProps = Props;


import Vue from "vue";
import { ProofreadingResult, Proofreading, loadProofreading, ProofreadingRules } from "@/lib/proofreadingWorker";
import ProofreadingPopup from "@/components/ui/ProofreadingPopup.vue";
import isMobile from "ismobilejs";
import { SearchTextIndex } from "@/lib/models/manuscript";

/**
 * ダークモードのテーマの一覧
 * マーカーの色を切り替えるために使用する。
 */
const DARK_MODE_THEMES = ["dark", "blackboard"];
const HIGHLIGHT_LIGHT_COLOR = "#fefe00";
const HIGHLIGHT_LIGHT_BOLD_COLOR = "#feba00";
const HIGHLIGHT_DARK_COLOR = "#004bbb";
const HIGHLIGHT_DARK_BOLD_COLOR = "#00a2bb";

/**
 * 1つの要素(span)内の最大文字数
 * これ以上の長さはChromeの場合 Range#setStart() を呼ぶとエラーになるため。
 */
const MAX_TEXT_CHUNK_SIZE = 65536;

function applyPhantomText(elem: HTMLDivElement, text: string) {
  if (text.length >= MAX_TEXT_CHUNK_SIZE) {
    const s1 = text.substr(0, MAX_TEXT_CHUNK_SIZE);
    const s2 = text.substr(MAX_TEXT_CHUNK_SIZE);
    /* eslint-disable no-param-reassign */
    elem.firstChild!.textContent = s1;
    elem.lastChild!.textContent = s2;
  } else {
    elem.firstChild!.textContent = text;
    /* eslint-enable no-param-reassign */
  }
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    content: String,
    scrollTop: Number,
  },
  components: {
    ProofreadingPopup,
  },
  mounted() {
    const proofreading = loadProofreading();
    this.proofreading = proofreading;
    proofreading.purge();

    proofreading.result = (result) => {
      this.proofreadingResult = result;
      this.render();
    };
    proofreading.ruleChanged = () => {
      proofreading.run(this.content);
    };

    const resizeObserver = new ResizeObserver(() => {
      if (!this.enableProofreading) {
        return;
      }

      const canvas = this.$refs.canvas as HTMLCanvasElement;
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
      this.render();
    });
    resizeObserver.observe(this.$refs.editorArea as HTMLCanvasElement);
    this.resizeObserver = resizeObserver;
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.editorArea as HTMLCanvasElement);
    }
  },
  data() {
    return {
      proofreading: null,
      proofreadingResult: null,
      proofreadingAreas: null,
      showPopup: false,
      popup: null,
      resizeObserver: null,
    };
  },
  computed: {
    theme() {
      const theme = this.$store.getters["manuscriptModule/theme"];

      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => {
        this.render();
      }, 0);

      return theme;
    },
    rules() {
      return this.$store.getters["proofreadingModule/rules"];
    },
    enableProofreading() {
      if (isMobile().any) {
        return false;
      }
      return this.$store.getters["proofreadingModule/enable"];
    },
    enabledSearchReplace() {
      return this.$store.getters["manuscriptModule/enabledSearchReplace"];
    },
    searchTextIndexList() {
      return this.$store.getters["manuscriptModule/searchTextIndexList"];
    },
    searchTargetIndex() {
      return this.$store.getters["manuscriptModule/searchTargetIndex"];
    },
    searchTotalCount() {
      return this.$store.getters["manuscriptModule/searchTotalCount"];
    },
  },
  watch: {
    searchTextIndexList: {
      handler() {
        setTimeout(() => {
          const phantom = this.$refs.phantom as HTMLDivElement;
          applyPhantomText(phantom, this.content);
          phantom.scrollTop = this.scrollTop;
          const canvas = this.$refs.canvas as HTMLCanvasElement;
          if (!canvas) return;
          canvas.width = canvas.clientWidth;
          canvas.height = canvas.clientHeight;
          setTimeout(() => {
            this.render();
          }, 50);
        }, 0);
      },
      immediate: true,
    },
    content(newContent /* , oldContent */) {
      if (this.enableProofreading || this.enabledSearchReplace) {
        setTimeout(() => {
          applyPhantomText(this.$refs.phantom as HTMLDivElement, newContent);
          this.proofreading!.run(newContent);
          setTimeout(() => {
            this.render();
          }, 50);
        }, 0);
      }
    },
    scrollTop() {
      if (this.enableProofreading || this.enabledSearchReplace) {
        setTimeout(() => {
          const phantom = this.$refs.phantom as HTMLTextAreaElement;
          phantom.scrollTop = this.scrollTop;
          this.render();
        }, 0);
      }
    },
    enableProofreading: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.proofreading!.changeLintRule(this.rules as ProofreadingRules);

            const phantom = this.$refs.phantom as HTMLDivElement;
            applyPhantomText(phantom, this.content);
            phantom.scrollTop = this.scrollTop;
            const canvas = this.$refs.canvas as HTMLCanvasElement;
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;

            if (this.content.length > 10000) {
              // 10000字を超える長文の場合先頭10000字だけ先に解析する(ファーストビューを早く見せるため)
              this.proofreading!.run(this.content.slice(0, 10000));
            }
            this.proofreading!.run(this.content);
          });
        }
      },
      immediate: true,
    },
    enabledSearchReplace: {
      handler() {
        setTimeout(() => {
          this.render();
        }, 0);
      },
      immediate: true,
    },
    searchTargetIndex: {
      handler() {
        setTimeout(() => {
          this.render();
        }, 0);
      },
      immediate: true,
    },
    searchTotalCount: {
      handler() {
        setTimeout(() => {
          this.render();
        }, 0);
      },
      immediate: true,
    },
  },

  methods: {
    onFix(fix: any) {
      const currentText = this.content as string;
      const [start, end] = fix.range;
      const newText = currentText.slice(0, start).concat(fix.text, currentText.slice(end));

      this.$emit("fixed", newText);
      this.showPopup = false;
    },
    onMouseMove(event: MouseEvent) {
      if (!this.enableProofreading || !this.proofreadingAreas) return;

      const { offsetX: x, offsetY: y } = event;
      const target = event.target as HTMLElement;

      if (target && target.tagName.toLowerCase() !== "textarea") {
        return;
      }

      const proofreadingArea = this.proofreadingAreas.find((area) => {
        const { l, t, r, b } = area;
        return l <= x && x <= r && t <= y && y <= b;
      });

      if (!proofreadingArea) {
        this.showPopup = false;
        return;
      }

      const prev = this.popup;
      if (this.showPopup && prev && prev.l === proofreadingArea.l && prev.t === proofreadingArea.t) {
        return;
      }

      this.showPopup = true;
      this.popup = proofreadingArea;
    },

    render() {
      const phantom = this.$refs.phantom as HTMLDivElement;
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      if (!canvas || !phantom) {
        return;
      }
      const context = canvas.getContext("2d")!;
      if (!context) {
        return;
      }

      const offset = canvas.getBoundingClientRect();
      context.clearRect(0, 0, canvas.width, canvas.height);

      function pickPosition(position: number) {
        if (position >= MAX_TEXT_CHUNK_SIZE) {
          return { elem: phantom.lastChild!, pos: position - MAX_TEXT_CHUNK_SIZE };
        }
        return { elem: phantom.firstChild!, pos: position };
      }

      function createRange(from: number, to: number) {
        const range = document.createRange();
        const start = pickPosition(from);
        range.setStart(start.elem.firstChild!, start.pos);
        const end = pickPosition(to);

        range.setEnd(end.elem.firstChild!, end.pos);
        return range;
      }

      if (this.enableProofreading) {
        const result = this.proofreadingResult as ProofreadingResult;
        if (!result) {
          return;
        }

        /** 校正結果の下線を描画する */
        context.strokeStyle = "rgb(255, 0, 0)";
        context.lineWidth = 1.5;

        const proofreadingAreas: ProofreadingArea[] = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const msg of result.messages) {
          const length = msg.fix ? Math.max(msg.fix.range[1] - msg.fix.range[0], 1) : 1;

          try {
            const range = createRange(msg.index, msg.index + length);

            const rects = range.getClientRects();
            if (rects.length === 0 || rects[0].top - offset.y + rects[0].height < 0) {
              // eslint-disable-next-line no-continue
              continue;
            }
            if (rects[0].top - offset.y > canvas.clientHeight) {
              break;
            }

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < rects.length; i++) {
              const r = rects[i];
              // eslint-disable-next-line prefer-const
              let { left, top, width, height } = r;
              const y = top - offset.y + height;
              if (width === 0) {
                left -= 4;
                width = 8;
              }

              drawHorizontalWave(context, left - offset.x, y, width);

              proofreadingAreas.push({
                l: left - offset.x,
                t: top - offset.y,
                r: left - offset.x + width,
                b: top - offset.y + height,
                content: msg,
              });
            }
          } catch (e) {
            // setStart, setEnd のoffsetが要素内の文字列の長さより長い場合エラーになる。
            break;
          }
        }

        this.proofreadingAreas = proofreadingAreas;
      }

      if (this.enabledSearchReplace && this.searchTextIndexList) {
        /** 検索結果のハイライトを描画する */
        const currentTheme = this.theme;
        const isDarkMode = currentTheme && DARK_MODE_THEMES.includes(currentTheme);
        const markerColor = isDarkMode ? HIGHLIGHT_DARK_COLOR : HIGHLIGHT_LIGHT_COLOR;
        const markerBoldColor = isDarkMode ? HIGHLIGHT_DARK_BOLD_COLOR : HIGHLIGHT_LIGHT_BOLD_COLOR;
        const targetIndex = this.searchTotalCount && this.searchTargetIndex;

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, { from, to }] of this.searchTextIndexList.entries()) {
          const isTargetIndex = index === targetIndex;
          context.fillStyle = isTargetIndex ? markerBoldColor : markerColor;
          const ran = createRange(from, to);
          const rects = ran.getClientRects();
          if (rects.length === 0 || rects[0].top - offset.y + rects[0].height < 0) {
            // eslint-disable-next-line no-continue
            continue;
          }
          if (rects[0].top - offset.y > canvas.clientHeight) {
            break;
          }

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < rects.length; i++) {
            const r = rects[i];
            const { left, top, width, height } = r;
            context.fillRect(left - offset.x, top - offset.y, width, height);
          }
        }
      }
    },
  },
});

/** 水平方向の波線を引く */
function drawHorizontalWave(ctx: CanvasRenderingContext2D, x: number, y: number, width: number) {
  const path = new Path2D();
  path.moveTo(x, y);
  const SPAN = 8.0;
  const HEIGHT = 4.0;
  const S1 = SPAN / 3;
  const S2 = (SPAN * 2) / 3;
  const region = new Path2D();
  region.rect(x, y - HEIGHT, width, HEIGHT * 2);
  ctx.save();
  ctx.clip(region);
  for (let i = 0; i < width; i += SPAN) {
    path.bezierCurveTo(x + i + S1, y + HEIGHT, x + i + S2, y - HEIGHT, x + i + SPAN, y);
  }

  ctx.stroke(path);
  ctx.restore();
}

interface Props {
  content: string;
  scrollTop: number;
}

interface Data {
  proofreading: Proofreading | null;
  proofreadingAreas: ProofreadingArea[] | null;
  proofreadingResult: ProofreadingResult | null;
  showPopup: boolean;
  popup: ProofreadingArea | null;
  resizeObserver: ResizeObserver | null;
}

interface Computed {
  theme: string;
  rules: object;
  enableProofreading: boolean;
  enabledSearchReplace: boolean;
  searchTextIndexList: SearchTextIndex[];
  searchTargetIndex: number;
  searchTotalCount: number;
}

interface Methods {
  onMouseMove(event: MouseEvent): void;
  render(): void;
  onFix: (e: any) => void;
}

interface ProofreadingArea {
  l: number;
  t: number;
  r: number;
  b: number;
  content: any;
}


import Vue from "vue";
import PlotChange from "@/components/organisms/PlotChange.vue";
import { Plot, SubPlot } from "@/lib/models/plot";
import clone from "lodash/cloneDeep";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { PlotChange },
  props: {
    novelId: String,
    plotId: String,
  },
  computed: {
    plot() {
      const { $store, plotId } = this;

      if (plotId) {
        return clone($store.getters["plotModule/subPlot"](plotId));
      }
      return clone($store.getters["plotModule/plots"]);
    },
  },
});

interface Props {
  novelId: string;
  plotId?: string;
}

interface Data {}

interface Computed {
  plot: Plot | SubPlot;
}

interface Methods {}

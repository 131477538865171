import { render, staticRenderFns } from "./DropupMenu.vue?vue&type=template&id=b56b4860&scoped=true"
import script from "./DropupMenu.vue?vue&type=script&lang=ts"
export * from "./DropupMenu.vue?vue&type=script&lang=ts"
import style0 from "./DropupMenu.vue?vue&type=style&index=0&id=b56b4860&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b56b4860",
  null
  
)

export default component.exports
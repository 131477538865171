import { defineStore } from "pinia";
import { ref } from "vue";
import { ThemeClient } from "@/lib/clients";
import type { Theme, Detail } from "@/lib/models/theme";

export const useThemeStore = defineStore("theme", () => {
  const theme = ref<Theme>({
    novelId: "",
    detail: [] as Detail[],
  });

  const themeClient = new ThemeClient();

  async function fetchTheme(novelId: string) {
    theme.value = await themeClient.fetchTheme(novelId);
  }

  async function updateTheme(payload: { novelId: string; detail: Detail[] }) {
    const { novelId, detail } = payload;
    theme.value = await themeClient.updateNovelTheme(novelId, detail);
  }

  return { fetchTheme, theme, updateTheme };
});


import Vue, { PropType } from "vue";
import CommentListOnSlideMenu from "@/components/organisms/CommentListOnSlideMenu.vue";
import SharedLinkContentListOnSlideMenu from "@/components/organisms/SharedLinkContentListOnSlideMenu.vue";
import SharedLinkListOnSlideMenu from "@/components/organisms/SharedLinkListOnSlideMenu.vue";
import CommentIcon from "@/components/atoms/CommentIcon.vue";
import CloseIcon from "@/components/atoms/CloseIcon.vue";
import { Comment as CommentDexie } from "@/lib/indexeddb";
import { User, SharedLinkKind } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CommentListOnSlideMenu,
    SharedLinkContentListOnSlideMenu,
    SharedLinkListOnSlideMenu,
    CommentIcon,
    CloseIcon,
  },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onOpenTagClick() {
      this.isOpen = !this.isOpen;
    },
    closeMenu() {
      this.isOpen = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters["userModule/user"] as User;
    },
    selectedSharedLinkId() {
      return this.$store.getters["sharedLinkModule/selectedSharedLinkId"];
    },
    selectedContentKey() {
      return this.$store.getters["sharedLinkModule/selectedContentKey"];
    },
    hasUnreadComment() {
      const { novelId, kind } = this;
      const { userId } = this.user as User;
      const unreadCommentList = this.$store.getters["sharedLinkModule/unreadCommentList"](
        userId,
        novelId,
        kind
      ) as CommentDexie[];
      return !!unreadCommentList.length;
    },
    isPlot() {
      return this.kind === SharedLinkKind.PLOT;
    },
  },
  destroyed() {
    this.$store.dispatch("sharedLinkModule/initializeSelectedSharedLink");
    this.$store.dispatch("sharedLinkModule/initializeSelectedContent");
  },
  watch: {
    $route: "closeMenu",
  },
});

interface Props {
  novelId: string;
  kind: string;
}

interface Data {
  isOpen: Boolean;
}

interface Methods {
  onOpenTagClick: () => void;
  closeMenu: () => void;
}

interface Computed {
  user: User;
  selectedSharedLinkId: string;
  selectedContentKey: string;
  hasUnreadComment: boolean;
  isPlot: boolean;
}


import Vue from "vue";

const jpRules = {
  "ja-technical-writing/no-exclamation-question-mark": {
    replacer: (rule: any) => {
      // eslint-disable-next-line no-useless-escape
      const m = rule.message.match(/Disallow to use \"(.+)\"/);
      return m ? `'${m[1]}'記号は使用できません。` : `記号は使用できません。`;
    },
  },
  "ja-technical-writing/sentence-length": {
    replacer: (rule: any) => {
      const m = rule.message.match(
        /Line .+ sentence length\((.+)\) exceeds the maximum sentence length of (.+)\.\nOver (.+) characters\./
      );
      return m
        ? `1文の長さが${m[2]}文字を超えています\n（現在1文の長さが${m[1]}文字で、${m[3]}文字超えています）。`
        : "1文の長さが100文字を超えています。";
    },
  },
  "ja-technical-writing/no-unmatched-pair": {
    replacer: (rule: any) => {
      const m = rule.message.match(
        /Not found pair character for (.+)\.\n\s+\nYou should close this sentence with (.+)\.\nThis pair mark is called/
      );
      return m ? `（${m[1]}）に対応する括弧（${m[2]}）が見つかりません。` : `対応する閉じ括弧が見つかりません。`;
    },
  },
  "ja-technical-writing/no-hankaku-kana": {
    replacer: (rule: any) => {
      const m = rule.message.match(/Disallow to use 半角カタカナ: "(.+)"/);
      return m ? `半角カタカナ（${m[1]}）は使用できません` : `半角カタカナは使用できません。`;
    },
  },
  "ja-technical-writing/no-zero-width-spaces": {
    replacer: () => "不必要なゼロ幅スペースがあります。",
  },
  "ja-technical-writing/no-nfd": {
    replacer: (rule: any) => {
      const m = rule.message.match(/Disallow to use NFD\(well-known as UTF8-MAC 濁点\): "(.+)" => "(.+)"/);
      return m
        ? `UTF8-MAC 濁点の（${m[1]}）が使用されています。代わりに（${m[2]}）を使用してください。`
        : `UTF8-MAC 濁点が使用されています。`;
    },
  },
  "ja-technical-writing/no-doubled-joshi": {
    replacer: (rule: any) => {
      // ライブラリ側の不具合（？）によって "てももももも・・・" の様に表示されてしまうため暫定的に「ても」に強制変換する。
      const m = rule.message.match(/一文に二回以上利用されている助詞 "([てで])も(も*)" がみつかりました。/);
      return m ? `一文に二回以上利用されている助詞 "${m[1]}も" がみつかりました。` : rule.message;
    },
  },
};

function convertToJapanese(rule: any) {
  if (!jpRules[rule.ruleId as keyof typeof jpRules]) {
    return rule.message;
  }
  return jpRules[rule.ruleId as keyof typeof jpRules].replacer(rule);
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    show: Boolean,
    detail: Object,
  },
  data() {
    return {
      isOpen: false,
      location: {},
      onMouse: false,
      closeTimer: null,
    };
  },
  updated() {
    const popup = this.$refs.popup as HTMLElement;
    if (popup) {
      // ポップアップの右端が画面外に出た場合、左端に移動する
      const parent = (this.$refs.popup as HTMLDivElement).offsetParent as HTMLDivElement;
      const right = parent.clientWidth - (popup.offsetLeft + popup.offsetWidth);
      if (right < 0 && this.location) {
        this.location.left = undefined;
        this.location.right = "0px";
      }
    }
  },
  computed: {
    message() {
      return this.detail ? convertToJapanese(this.detail.content) : "";
    },
    fix() {
      return this.detail ? this.detail.content.fix : null;
    },
    fixText() {
      const text = this.fix ? this.fix.text : "";
      if (text === "") {
        return "（対象の文字を削除）";
      }
      if (text === "　") {
        return "（空白文字を追加）";
      }

      return text;
    },
  },
  watch: {
    show() {
      if (this.show) {
        setTimeout(() => {
          if (this.show) {
            this.isOpen = true;
          }
        }, 150);
      } else {
        this.startClosePopupTimer();
      }
    },
    detail(newContent) {
      if (newContent) {
        this.stopClosePopupTimer();
        const { l, t, b } = newContent;

        if (t < 60) {
          // ポップアップ表示が画面上部の場合上にはみ出て見えなくなるため、対象テキストの下に表示する
          this.location = {
            left: `${l}px`,
            top: `${b + 5}px`,
            transform: "translate(0%, 0%)",
          };
        } else {
          this.location = {
            left: `${l}px`,
            top: `${t - 5}px`,
            transform: "translate(0%, -100%)",
          };
        }
      }
    },
  },
  methods: {
    handleFix() {
      this.$emit("fix", this.fix);
      this.isOpen = false;
    },
    mouseEnter() {
      this.onMouse = true;
      this.stopClosePopupTimer();
    },
    mouseLeave() {
      this.onMouse = false;
      if (!this.show) {
        this.startClosePopupTimer();
      }
    },
    startClosePopupTimer() {
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
      }
      this.closeTimer = setTimeout(() => {
        this.isOpen = false;
      }, 500);
    },
    stopClosePopupTimer() {
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
        this.closeTimer = null;
      }
    },
  },
});

interface Props {
  show: boolean;
  detail: any;
}

interface Data {
  isOpen: boolean;
  location?: {
    left?: string;
    top?: string;
    right?: string;
    transform?: string;
  };
  onMouse: boolean;
  // eslint-disable-next-line no-undef
  closeTimer: NodeJS.Timeout | null;
}

interface Computed {
  fix: any | null;
  fixText: string | null;
  message: string | null;
}

interface Methods {
  handleFix(): void;
  mouseEnter(): void;
  mouseLeave(): void;
  startClosePopupTimer(): void;
  stopClosePopupTimer(): void;
}

import { Mode, Layout } from "./models/plot";

export interface PlotModeSelectBoxItem {
  id: Mode;
  name: string;
  image: string;
}
/** プロットモード */
export const plotModes: PlotModeSelectBoxItem[] = [
  {
    id: Mode.kishoutenketsu,
    name: "起承転結",
    image: "none",
  },
  {
    id: Mode.johakyu,
    name: "序破急",
    image: "none",
  },
  {
    id: Mode.custom,
    name: "カスタム",
    image: "none",
  },
];

export interface PlotLayoutSelectBoxItem {
  id: Layout;
  name: string;
}
/** プロットレイアウト */
export const plotLayouts: PlotLayoutSelectBoxItem[] = [
  {
    id: Layout.simple,
    name: "シンプル",
  },
  {
    id: Layout.detail,
    name: "詳細設計",
  },
];

export interface ChildPlotItem {
  plotId: String;
  name: string;
  image: "none";
}

export interface NewChildPlotItem extends ChildPlotItem {
  mode: PlotModeSelectBoxItem;
}

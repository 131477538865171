
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import { VueLoading } from "vue-loading-template";
import { EpisodeFromNolaNovel } from "@/lib/models";

interface Data {
  inProcess: boolean;
}

interface Methods {
  onClickOutSide: () => void;
  onCancelClick: () => void;
  onDelinkClick: () => void;
}

interface Computed {
  episode: EpisodeFromNolaNovel;
}

interface Props {
  novelId: string;
  manuscriptId: string;
  episodeId: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog, VueLoading },

  data() {
    return {
      inProcess: false,
    };
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onCancelClick() {
      this.$close(false);
    },
    async onDelinkClick() {
      const { dispatch } = this.$store;
      const { novelId, manuscriptId, episodeId, episode } = this;
      this.inProcess = true;
      try {
        await dispatch("nolaNovelModule/deleteEpisodeLink", {
          novelId,
          manuscriptIds: [manuscriptId],
          episodes: [episode ?? episodeId],
        });
      } catch (e) {
        this.inProcess = false;
        this.$close(false);
        return;
      }

      this.inProcess = false;
      this.$close(true);
    },
  },

  computed: {
    episode() {
      const { episodeId } = this;
      const { getters } = this.$store;

      return getters["nolaNovelModule/episode"](episodeId);
    },
  },

  props: {
    novelId: String,
    manuscriptId: String,
    episodeId: String,
  },
});

export type EpisodeDelinkConfirmProps = Props;

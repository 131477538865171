
import Vue from "vue";
import CorrelationListItem from "@/components/molecules/CorrelationListItem.vue";
import SelectBox from "@/components/molecules/SelectBox.vue";
import CorrelationForm from "@/components/organisms/CorrelationForm.vue";
import { getCharacterKey } from "@/lib/s3";

const filterItem = {
  characterId: "filter-item",
  name: "選択して絞り込む",
  image: "none",
};

export default Vue.extend({
  components: {
    CorrelationListItem,
    SelectBox,
    CorrelationForm,
  },
  props: {
    novelId: String,
    items: Array as any,
    selectedCorrelationGroupId: String,
    selectedCorrelationId: String,
    characterItems: Array as any,
    correlationDiagram: Object,
  },
  data() {
    return {
      correlationList: this.items,
      isCreating: false,
      filterCharacter: filterItem,
    };
  },
  methods: {
    openForm() {
      if (!this.isCreating && !this.selectedCorrelationId) {
        this.isCreating = true;
        this.setModified(true);
      }
    },
    closeForm() {
      this.setModified(false);
      this.isCreating = false;
      this.$router.push(
        {
          name: "correlations",
          params: {
            novelId: this.novelId,
            correlationGroupId: this.selectedCorrelationGroupId,
          },
        },
        () => {}
      );
    },
    fetchCorrelationList() {
      this.$emit("fetch");
    },
    getCharacterData(characterId) {
      const character = this.characterItems.find((character) => character.characterId === characterId);
      if (!character) {
        return null;
      }
      character.image = getCharacterKey(this.novelId, character);
      return character;
    },
    getCorrelationData() {
      return this.items.find((correlation) => correlation.correlationId === this.selectedCorrelationId);
    },
    filterCharacterItems() {
      const availableImageItems = this.characterItems.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.image = getCharacterKey(this.novelId, item);
        return item;
      });
      return [filterItem, ...availableImageItems];
    },
    selectFilterCharacter(item) {
      this.filterCharacter = item;
    },
    filterCorrelation() {
      if (this.filterCharacter.characterId === "filter-item") {
        this.correlationList = this.items;
      } else {
        this.correlationList = this.items.filter(
          (correlation) =>
            correlation.characterA === this.filterCharacter.characterId ||
            correlation.characterB === this.filterCharacter.characterId
        );
      }
    },
  },
  watch: {
    selectedCorrelationGroupId() {
      this.filterCharacter = filterItem;
    },
    filterCharacter() {
      this.filterCorrelation();
    },
    items() {
      this.correlationList = this.items;
      this.filterCorrelation();
    },
    $route(to, from) {
      const isMoveToOtherCorrelationGroup = from.params.correlationGroupId !== to.params.correlationGroupId;
      if (isMoveToOtherCorrelationGroup) {
        this.isCreating = false;
      }
    },
  },
});

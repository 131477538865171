
import Vue from "vue";
import ChevronRightIcon from "icons/ChevronRight.vue";
import ArrowUpIcon from "icons/ArrowUp.vue";
import ArrowDownIcon from "icons/ArrowDown.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ChevronRightIcon,
    ArrowUpIcon,
    ArrowDownIcon,
  },
  mounted() {
    (this.$refs.inputSearch as HTMLElement).focus();
  },
  computed: {
    searchText: {
      get() {
        return this.$store.getters["manuscriptModule/searchText"];
      },
      set(value) {
        this.$store.dispatch("manuscriptModule/updateSearchText", value);
      },
    },
    replaceText: {
      get() {
        return this.$store.getters["manuscriptModule/replaceText"];
      },
      set(value) {
        this.$store.dispatch("manuscriptModule/updateReplaceText", value);
      },
    },
    targetIndex() {
      return this.$store.getters["manuscriptModule/searchTargetIndex"];
    },
    totalCount() {
      return this.$store.getters["manuscriptModule/searchTotalCount"];
    },
    indexCount() {
      const { targetIndex, totalCount } = this;
      let index = 0;
        let count = 0;

      if (totalCount) {
        index = targetIndex + 1;
        count = totalCount;
      }

      return `${index}/${count}件`;
    },
    enabledSearch() {
      return this.$store.getters["manuscriptModule/enabledSearch"];
    },
    enabledReplace() {
      return this.$store.getters["manuscriptModule/enabledReplace"];
    },
    enabledReplaceSingle() {
      return this.$store.getters["manuscriptModule/enabledReplaceSingle"];
    },
    enabledReplaceAll() {
      return this.$store.getters["manuscriptModule/enabledReplaceAll"];
    },
  },
  methods: {
    toggleEnabledReplace() {
      this.$store.dispatch("manuscriptModule/toggleEnabledReplace");
    },
    onClickPrev() {
      const { targetIndex, totalCount, enabledSearch } = this;
      if (totalCount === 0) return;
      if (totalCount === 1 && !enabledSearch) {
        this.$store.dispatch("manuscriptModule/toggleEnabledSearch");
        return;
      }

      const prevTargetIndex = targetIndex === 0 ? totalCount - 1 : targetIndex - 1;
      this.$store.dispatch("manuscriptModule/updateSearchTargetIndex", prevTargetIndex);
      if (!enabledSearch) this.$store.dispatch("manuscriptModule/toggleEnabledSearch");
    },
    onClickNext() {
      const { targetIndex, totalCount, enabledSearch } = this;
      if (totalCount === 0) return;
      if (totalCount === 1 && !enabledSearch) {
        this.$store.dispatch("manuscriptModule/toggleEnabledSearch");
        return;
      }

      const nextTargetIndex = targetIndex === totalCount - 1 ? 0 : targetIndex + 1;
      this.$store.dispatch("manuscriptModule/updateSearchTargetIndex", nextTargetIndex);
      if (!enabledSearch) this.$store.dispatch("manuscriptModule/toggleEnabledSearch");
    },
    onClickReplaceSingle() {
      const { totalCount, enabledReplaceSingle } = this;
      if (!enabledReplaceSingle && totalCount) this.$store.dispatch("manuscriptModule/toggleEnabledReplaceSingle");
    },
    onClickReplaceAll() {
      const { totalCount, enabledReplaceAll } = this;
      if (!enabledReplaceAll && totalCount) this.$store.dispatch("manuscriptModule/toggleEnabledReplaceAll");
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  searchText: string;
  replaceText: string;
  targetIndex: number;
  totalCount: number;
  indexCount: string;
  enabledSearch: boolean;
  enabledReplace: boolean;
  enabledReplaceSingle: boolean;
  enabledReplaceAll: boolean;
}

interface Methods {
  toggleEnabledReplace(): void;
  onClickPrev(): void;
  onClickNext(): void;
  onClickReplaceSingle(): void;
  onClickReplaceAll(): void;
}

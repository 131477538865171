
import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    value: {
      type: String,
    },
    maxlength: {
      type: Number,
      default: 1000,
    },
    label: String,
    subLabel: String,
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
});

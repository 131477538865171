
import Vue from "vue";
import ManuscriptPreview from "@/components/organisms/ManuscriptPreview.vue";
import DropupMenu, { Item } from "@/components/molecules/DropupMenu.vue";
import HamburgerMenu from "@/components/molecules/HamburgerMenu.vue";
import { Manuscript, ManuscriptSetting, Plan } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import ManuscriptSettingsDialog, { ManuscriptSettingsDialogProps } from "@/components/ui/ManuscriptSettingsDialog.vue";
import ColorThemeDialog from "@/components/ui/ColorThemeDialog.vue";
import clone from "lodash/cloneDeep";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ManuscriptPreview,
    DropupMenu,
    HamburgerMenu,
  },
  props: {
    novelId: String,
  },
  data() {
    const { onSelectCountType, showManuscriptSettings, showColorTheme } = this as any;

    const countTypeList: Item[] = [
      {
        name: "全文字数",
        value: "onlyCharacter",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "空白を含む全文字数",
        value: "addBlank",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "改行を含む全文字数",
        value: "addNewLine",
        callback() {
          onSelectCountType(this.value);
        },
      },
      {
        name: "空白/改行を含む全文字数",
        value: "all",
        callback() {
          onSelectCountType(this.value);
        },
      },
    ];

    const hamburgerMenuList: Item[] = [
      {
        name: "原稿の設定",
        callback() {
          showManuscriptSettings();
        },
      },
      {
        name: "カラーテーマ選択",
        callback() {
          showColorTheme();
        },
      },
    ];

    return {
      countTypeList,
      hamburgerMenuList,
    };
  },
  computed: {
    manuscriptList() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    allContentLength() {
      const manuscriptList = this.manuscriptList as Manuscript[];
      if (manuscriptList.length === 1) {
        return this.contentLength(manuscriptList[0].content);
      }
      return manuscriptList.reduce((acc, cur) => acc + this.contentLength(cur.content), 0);
    },
    contentLength() {
      return (content) => {
        if (!content) return 0;

        let rubyLength = 0;

        // eslint-disable-next-line no-control-regex
        const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
        const textWithRuby = content.match(rubyRegExp);
        if (textWithRuby) {
          textWithRuby.forEach((text) => {
            const onlyRubyRegExp = new RegExp("《.*?》$");
            const textOnlyRuby = text.match(onlyRubyRegExp);
            rubyLength += textOnlyRuby![0].length + 1; // 親文字の縦棒をカウントから除くための+1
          });
        }

        let contentLength = 0;
        switch (this.countType) {
          case "addBlank":
            contentLength = content.replace(/\n/g, "").length;
            break;
          case "addNewLine":
            contentLength = content.replace(/[^\S\n]/g, "").length;
            break;
          case "all":
            contentLength = content.length;
            break;
          case "onlyCharacter":
          default:
            contentLength = content.replace(/\s+|\n/g, "").length;
            break;
        }

        return contentLength - rubyLength;
      };
    },
    allPageCount() {
      const manuscriptList = this.manuscriptList as Manuscript[];
      if (manuscriptList.length === 1) {
        return this.pageCount(manuscriptList[0].content);
      }
      return manuscriptList.reduce((acc, cur) => acc + this.pageCount(cur.content), 0);
    },
    pageCount() {
      return (content) => {
        const clonedContent = clone(content) as string;

        /** ルビを除外する */
        const splittedContent = clonedContent.split("\n");
        const removedRubyContent = splittedContent.map((content) => {
          // eslint-disable-next-line no-control-regex
          const rubyRegExp = new RegExp("[|｜][^|｜\r\n]*?《.*?》", "gu");
          const textWithRuby = content.match(rubyRegExp);
          if (textWithRuby) {
            textWithRuby.forEach((text: string) => {
              let replaceText = text;
              const parentRegExp = new RegExp("[|｜]");
              replaceText = replaceText.replace(parentRegExp, "");
              replaceText = replaceText.replace("《.*?》", "");
              // eslint-disable-next-line no-param-reassign
              content = content.replace(text, replaceText);
            });
          }
          return content;
        });

        /** 原稿の設定情報を取得 */
        const { wordCountOnVertical, lineCountPerPage } = this.manuscriptSetting as ManuscriptSetting;

        /** 行数を計算する */
        let lineCount = 0;
        removedRubyContent.forEach(
          (content) => (lineCount += content ? Math.ceil(content.length / wordCountOnVertical) : 1)
        );
        return Math.ceil(lineCount / lineCountPerPage);
      };
    },
    countType() {
      return this.$store.getters["manuscriptModule/countType"];
    },
    countTypeName() {
      const countTypeValue = this.countType;
      const countType = (this.countTypeList as Item[]).find((countType) => countType.value === countTypeValue);
      const countTypeName = countType ? countType.name : "";

      /** 原稿の設定情報を取得 */
      const { wordCountOnVertical, lineCountPerPage } = this.manuscriptSetting as ManuscriptSetting;

      return `${countTypeName}（縦${wordCountOnVertical}×横${lineCountPerPage}）`;
    },
    wordCountInfo() {
      const contentLength = this.allContentLength;
      const pageCount = this.allPageCount;

      return `${contentLength}字（${pageCount}P換算）`;
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    manuscriptSetting() {
      return this.$store.getters["novelModule/manuscriptSetting"](this.novelId) as ManuscriptSetting;
    },
  },
  methods: {
    onClickClose() {
      this.$router.push({ name: "editor" });
    },
    onSelectCountType(countTypeValue) {
      this.$store.dispatch("manuscriptModule/selectCountType", countTypeValue);
    },
    showManuscriptSettings() {
      const { novelId, plan } = this;
      const manuscriptSettingsDialog = new Dialog(ManuscriptSettingsDialog);
      const data: ManuscriptSettingsDialogProps = {
        novelId,
        isChangeable: Boolean(plan),
        positive: "保存する",
        positiveCallback: async () => {},
      };
      manuscriptSettingsDialog.show(data);
    },
    showColorTheme() {
      const colorThemeDialog = new Dialog(ColorThemeDialog);
      colorThemeDialog.show();
    },
  },
});

interface Props {
  novelId: string;
}

interface Data {
  countTypeList: Item[];
  hamburgerMenuList: Item[];
}

interface Methods {
  onClickClose: () => void;
  onSelectCountType(countTypeValue: any): void;
  showManuscriptSettings: () => void;
  showColorTheme(): void;
}

interface Computed {
  manuscriptList: Manuscript[];
  allContentLength: number;
  contentLength: (content: string) => number;
  allPageCount: number;
  pageCount: (content: string) => number;
  countType: string;
  countTypeName: string;
  wordCountInfo: string;
  plan: Plan;
  manuscriptSetting: ManuscriptSetting;
}

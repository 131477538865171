
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";

interface Props {
  title: string;
  content: string;
  change?: string;
  cancel?: string;
}

interface Data {}

interface Computed {}

interface Methods {
  onClickOutSide: () => void;
  onCancelClick: () => void;
  onChangeClick: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog },

  props: {
    title: String,
    content: String,
    change: {
      type: String,
      default: "変更する",
      required: false,
    },
    cancel: {
      type: String,
      default: "キャンセル",
      required: false,
    },
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onCancelClick() {
      this.$close(false);
    },
    onChangeClick() {
      this.$close(true);
    },
  },
});

export type BreakingChangeConfirmProps = Props;


import Vue from "vue";
import ListItem from "@/components/molecules/ListItem.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import { callApi } from "@/lib/api";
import { Plan } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
// NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
// import { Dialog } from "@/lib/utils";
// import PremiumFeatureDialog, { PremiumFeatureDialogProps } from "@/components/ui/PremiumFeatureDialog.vue";
// import { downloadPublicImage } from "@/lib/s3";

interface Data {
  isEditing: boolean;
  correlationGroupList: unknown[];
}

interface Methods {
  createCorrelationGroup: () => Promise<void>;
  deleteCorrelationGroup: (id: string) => Promise<void>;
  saveOrder: () => Promise<void>;
}

interface Props {
  novelId: string;
  items: unknown[];
  selectedCorrelationGroupId: string;
}

type CorrelationGroup = {
  correlationGroupId: string;
};

export default Vue.extend<Data, Methods, unknown, Props>({
  components: { ListItem, SortableList },
  props: {
    novelId: String,
    items: Array as any,
    selectedCorrelationGroupId: String,
  },
  data() {
    return {
      isEditing: false,
      correlationGroupList: this.items,
    };
  },
  watch: {
    items() {
      this.correlationGroupList = this.items;
    },
  },
  computed: {
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
  },
  methods: {
    async createCorrelationGroup() {
      if (this.isFree && this.correlationGroupList.length) {
        // NOTE: 機能紹介GIFを修正するため一時的にコメントアウト
        // const premiumFeatureDialog = new Dialog(PremiumFeatureDialog);
        // const data: PremiumFeatureDialogProps = {
        //   title: "相関図の複数作成はプレミアム機能です",
        //   text: "Nolaプレミアムにご登録いただくと、１つの作品内に２つ以上の『相関図の作成』を行うことができます。”各場面ごと”の相関図の作成や”〇〇高校”などの組織ごとに相関図を作成し資料管理することができますので、お手すきの際にぜひご活用いただだけたら幸いです。",
        //   image: (await downloadPublicImage("shared/images/gif/correlation_group.gif")) as string,
        // };
        // premiumFeatureDialog.show(data);

        this.$router.push({ name: "subscriptionAnnounce", query: { from: "createCorrelationGroup" } });

        return;
      }

      const { novelId } = this;
      const query = `
      mutation CreateCorrelationGroup ($input: CorrelationGroupCreateInput!) {
        createCorrelationGroup (input: $input) {
          novelId
          correlationGroupId
          name
          diagram {
            correlationKeys
            coordinate {
              characterId
              x
              y
            }
          }
        }
      }
      `;
      await callApi(query, {
        input: {
          novelId,
          name: "新しい相関関係グループ",
        },
      });

      this.$emit("fetch");
    },
    async deleteCorrelationGroup(correlationGroupId) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "削除の最終確認",
        content: "本当に相関関係グループを削除しますか？<br />※ 一度削除した相関関係は元に戻せません。",
        change: "削除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }

      const { novelId } = this;
      const query = `
      mutation DeleteCorrelationGroup ($input: CorrelationGroupDeleteInput!) {
        deleteCorrelationGroup (input: $input) {
          novelId
          correlationGroupId
          name
          diagram {
            correlationKeys
            coordinate {
              characterId
              x
              y
            }
          }
        }
      }
      `;
      await callApi(query, {
        input: {
          novelId,
          correlationGroupId,
        },
      });

      this.$notify({
        title: "削除しました",
        type: "error",
      });

      this.$router.push({
        name: "correlations",
        params: { novelId },
      });
    },
    async saveOrder() {
      const { novelId, correlationGroupList } = this;
      const order = correlationGroupList.map((item) => (item as CorrelationGroup).correlationGroupId);

      const query = `
        mutation UpdateDisplayOrder($input: UpdateDisplayOrderInput!) {
          updateDisplayOrder(input: $input) {
            order
          }
        }
      `;

      await callApi(query, {
        input: {
          novelId,
          kind: "correlationGroup",
          order,
        },
      });

      this.isEditing = false;
      this.$emit("fetch");
    },
  },
});


import Vue from "vue";
import { ManuscriptSetting } from "@/lib/models";
import PremiumInformation from "@/components/atoms/PremiumInformation.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    PremiumInformation,
  },
  props: {
    novelId: {
      type: String,
      required: true,
    },
    isChangeable: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "はい",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    const manuscriptSetting: ManuscriptSetting = this.$store.getters["novelModule/manuscriptSetting"](this.novelId);
    const { wordCountOnVertical, lineCountPerPage, fontSizeRate } = manuscriptSetting;

    return {
      wordCountOnVertical,
      lineCountPerPage,
      fontSizeRate,
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      const { novelId, validatedWordCountOnVertical, validatedLineCountPerPage, validatedFontSizeRate } = this;
      const manuscriptSetting: ManuscriptSetting = {
        novelId,
        wordCountOnVertical: validatedWordCountOnVertical,
        lineCountPerPage: validatedLineCountPerPage,
        fontSizeRate: validatedFontSizeRate,
      };
      this.$store.dispatch("novelModule/updateManuscriptSetting", manuscriptSetting);

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
  },
  computed: {
    validatedWordCountOnVertical: {
      get() {
        return this.wordCountOnVertical;
      },
      set(value) {
        if (value < 16) {
          // eslint-disable-next-line no-param-reassign
          value = 16;
        } else if (value > 50) {
          // eslint-disable-next-line no-param-reassign
          value = 50;
        }
        this.wordCountOnVertical = value;
      },
    },
    validatedLineCountPerPage: {
      get() {
        return this.lineCountPerPage;
      },
      set(value) {
        if (value < 16) {
          // eslint-disable-next-line no-param-reassign
          value = 16;
        } else if (value > 40) {
          // eslint-disable-next-line no-param-reassign
          value = 40;
        }
        this.lineCountPerPage = value;
      },
    },
    validatedFontSizeRate: {
      get() {
        return this.fontSizeRate;
      },
      set(value) {
        if (value < 0.8) {
          // eslint-disable-next-line no-param-reassign
          value = 0.8;
        } else if (value > 1.5) {
          // eslint-disable-next-line no-param-reassign
          value = 1.5;
        }
        this.fontSizeRate = value;
      },
    },
  },
});

interface Props {
  novelId: string;
  isChangeable?: boolean;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  wordCountOnVertical: number;
  lineCountPerPage: number;
  fontSizeRate: number;
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
}

interface Computed {
  validatedWordCountOnVertical: number;
  validatedLineCountPerPage: number;
  validatedFontSizeRate: number;
}

export type ManuscriptSettingsDialogProps = Props;

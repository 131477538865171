
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import { VueLoading } from "vue-loading-template";

type InputError = {
  hasError: boolean;
  message: string;
};

interface Data {
  inProcess: boolean;
  input: string;
  error: InputError;
}

interface Methods {
  onClickOutSide: () => void;
  onCancelClick: () => void;
  onDeleteClick: () => void;

  deleteNovel: () => void;
}

interface Computed {
  placeholder: string;
}

interface Props {
  novelId: string;
  nolaNovelId?: string;
  title: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog, VueLoading },

  data() {
    return {
      inProcess: false,
      input: "",
      error: {
        hasError: false,
        message: "",
      },
    };
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onCancelClick() {
      this.$close(false);
    },
    onDeleteClick() {
      const { input, title, deleteNovel } = this;
      if (input === title) {
        deleteNovel();
        return;
      }

      const error: InputError = {
        hasError: true,
        message: "入力された文字がタイトルと異なります。",
      };

      if (input.length < 1) {
        error.message = "タイトルが未入力です。";
      }

      this.error = error;
    },

    async deleteNovel() {
      try {
        this.inProcess = true;
        const { title, novelId, nolaNovelId } = this;
        const { dispatch } = this.$store;
        if (nolaNovelId) {
          await dispatch("nolaNovelModule/deleteNovelLink", {
            novelId,
            nolaNovelId,
          });
        }

        await dispatch("novelModule/deleteNovel", {
          novelId,
          title,
        });
        this.$close(true);
      } catch (e) {
        this.$close(false);
      }
    },
  },

  computed: {
    placeholder() {
      const { title } = this;
      return `ここに「${title}」と入力してください`;
    },
  },

  props: {
    novelId: String,
    nolaNovelId: String,
    title: String,
  },
});

export type NovelDeleteConfirmProps = Props;


import Vue from "vue";

export default Vue.extend({
  props: {
    to: Object,
  },
  model: {
    event: "input",
  },
  methods: {
    handleInput(e: any) {
      this.$emit("input", e.target.value);
    },
  },
});

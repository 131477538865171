
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    negative: {
      type: String,
      default: "閉じる",
    },
    negativeCallback: Function,
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close();
    },
    onClickOutSide() {
      this.$close(true);
    },
    onSelectColorTheme(event) {
      if (event.target instanceof HTMLSelectElement) {
        this.$store.dispatch("manuscriptModule/selectTheme", event.target.value);
      }
    },
  },
  computed: {
    theme() {
      return this.$store.getters["manuscriptModule/theme"];
    },
  },
});

interface Props {
  negative?: string;
  negativeCallback?: () => void;
}

interface Data {}

interface Methods {
  onNegativeClick: () => void;
  onClickOutSide: () => void;
  onSelectColorTheme(event: Event): void;
}

interface Computed {
  theme: string;
}

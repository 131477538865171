
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";

interface Props {
  name: string;
  renameCallback: (name: string) => Promise<void>;
}

interface Data {
  input: string;
}

interface Computed {
  placeholder: string;
}

interface Methods {
  onClickOutSide: () => void;
  onClickCancel: () => void;
  onClickRename: () => Promise<void>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog },

  props: {
    name: String,
    renameCallback: Function,
  },

  data() {
    return {
      input: this.name,
    };
  },

  computed: {
    placeholder() {
      return "フォルダ名を入力してください。";
    },
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onClickCancel() {
      this.$close(false);
    },
    async onClickRename() {
      if (!this.input) return;

      await this.renameCallback(this.input);
      this.$close(true);
    },
  },
});

export type FolderRenameDialogProps = Props;

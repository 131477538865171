
import Vue, { PropType } from "vue";
import Attribute from "@/components/molecules/Attribute.vue";
import ImageView from "@/components/atoms/ImageView.vue";
import ButtonRound from "@/components/atoms/ButtonRound.vue";
import { Character } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ButtonRound,
    Attribute,
    ImageView,
  },
  props: {
    novelId: String,
    character: Object as PropType<Character>,
  },
  computed: {
    detail() {
      const { detail } = this.character as Character;
      return Object.assign(
        {},
        ...detail.map((d) => ({
          [d.name]: d.value,
        }))
      );
    },
    additionalColumn() {
      return this.character.additionalColumn;
    },
    getCharacterKey() {
      const {
        novelId,
        character: { characterId, image },
      } = this;
      if (image && image.startsWith("file:")) {
        return `file:novels/${novelId}/characters/${characterId}.jpg`;
      }
      return image;
    },
  },
});

interface Props {
  novelId: string;
  character: Character;
}

interface Data {}

interface Computed {
  detail: { [key: string]: string };
  additionalColumn: { name: string; label: string; content: string };
  getCharacterKey: string;
}

interface Methods {}


import Vue from "vue";
import InputText from "@/components/atoms/InputText.vue";
import InputTextarea from "@/components/atoms/InputTextarea.vue";

export default Vue.extend({
  components: { InputText, InputTextarea },
  props: {
    value: {
      type: String,
    },
    /** text type */
    type: {
      type: String,
      default: "text",
    },
    id: String,
    name: String,
    rows: Number,
    maxlength: Number,
    label: String,
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      inputType: this.type,
      passwordVisible: false,
    };
  },
  methods: {
    toggleVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
});


import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  props: {
    animationData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$refs.anim.play();
  },
};
